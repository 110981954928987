import { Field, FlowableValueSetRadioField } from '../../../translation/types';
import ValuesetOneCheckbox, { ConnectedComponentProps } from './';
import {
    ensureEndswithId,
    getLabel,
    getHtmlId,
    getLabelledBy,
    isFieldFromFlowable,
    isFieldFromEntity,
} from '../../fieldUtils';
import { getDefaultCode, getValuesetGroup } from '../../fieldUtils/valueset';
import { getDeselectable, getDirection } from '../../fieldUtils/checkboxAndRadio';
import { GenericProps, FormFieldItem } from '../../props';

const getValuesetCheckbox = (
    fieldDefinition: Field,
    propConfiguration: GenericProps,
): FormFieldItem<ConnectedComponentProps> => {
    return {
        Component: ValuesetOneCheckbox,
        props: {
            fetchOwnData: false,
            ...propConfiguration,
            valueSet: isFieldFromFlowable(fieldDefinition)
                ? (fieldDefinition as FlowableValueSetRadioField).params.valueSet
                : isFieldFromEntity(fieldDefinition) && fieldDefinition.unpersistedValueset
                ? fieldDefinition.unpersistedValueset
                : undefined,
            labelledBy: getLabelledBy(fieldDefinition),
            id: getHtmlId(fieldDefinition),
            defaultCode: getDefaultCode(fieldDefinition),
            source: ensureEndswithId(fieldDefinition.name),
            direction: getDirection(fieldDefinition),
            label: getLabel(fieldDefinition),
            deselectable: getDeselectable(fieldDefinition),
            group: getValuesetGroup(fieldDefinition),
        },
    };
};
export default getValuesetCheckbox;
