import ViewConfig from 'reducers/ViewConfigType';
import { fromEither, Option } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';
import { getViewConfiguration } from 'components/generics/utils/viewConfigUtils';
import { fromNullable, some } from 'fp-ts/lib/Option';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, tap } from 'rxjs/operators';
import { loadSuccess } from 'viewConfig/actions';
import { getStorage } from 'storage/storage';
import { set } from './conditionalDefaultValueExpressionsSlice';
import { expressionTesterProvidedViewConfigurationContext } from 'expression-tester/entity-form';
import { useContext, useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { ConditionalDefaultValueExpressionMetaData } from './ConditionalDefaultValueExpressions';
import { getMetadataFromCalcExpressions } from '../CalcValueConfiguration/getCalcValueConfigMetaData';

export const getConditionalDefaultValueConfigMetaData = (
    resource: string,
    viewConfiguration: Option<string>,
    viewConfig: ViewConfig,
): Option<ConditionalDefaultValueExpressionMetaData[string]> => {
    const currentViewExpressions = viewConfiguration
        .map(parseConfig)
        .map((e) => e.map((c) => c.conditionalDefaultValueExpressions))
        // prints errors for the failed configs
        .map((e) =>
            e.mapLeft((error) => {
                console.error(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)

        .map((v) => {
            return getMetadataFromCalcExpressions(v, resource, viewConfig);
        });
    return currentViewExpressions.map((viewExpressions) =>
        Object.fromEntries(
            viewExpressions.map((e) => {
                return [e.fieldName, e];
            }),
        ),
    );
};

export const getAllConditionalDefaultValueConfigMetaData = (viewConfig: ViewConfig) => {
    const validConfigs = Object.entries(viewConfig.views).reduce((prev, [viewName, view]) => {
        const viewConfiguration = fromEither(
            // this should take viewName
            tryCatch(() => getViewConfiguration(viewConfig, viewName)).mapLeft((e) => console.log(e)),
        ).chain(fromNullable);
        return getConditionalDefaultValueConfigMetaData(view.entity, viewConfiguration, viewConfig).fold(
            prev,
            (res) => {
                prev[viewName] = res;
                return prev;
            },
        );
    }, {} as ConditionalDefaultValueExpressionMetaData);
    return validConfigs;
};
export const VIEW_CONDITIONAL_DEFAULT_VALUE_EXPS_KEY = 'viewConditionalDefaultValues';

export const viewConditionalDefaultValueMetaDataEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) =>
    action$.pipe(
        filter(isActionOf(loadSuccess)),
        map(({ payload: { viewConfig } }) => {
            return getAllConditionalDefaultValueConfigMetaData(viewConfig);
        }),
        tap((exps) => {
            getStorage().setItem(VIEW_CONDITIONAL_DEFAULT_VALUE_EXPS_KEY, JSON.stringify(exps));
        }),
        map((e) => {
            return set(e);
        }),
    );

export const useOverriddenViewConditionalDefaultValues = (viewName: string) => {
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName].entity;

    const overrideConfiguredContext = useContext(expressionTesterProvidedViewConfigurationContext);
    const overriddenConditionalDefaultValueExpressions =
        overrideConfiguredContext?.config?.[viewName]?.conditionalDefaultValueExpressions;

    return useMemo(() => {
        if (!overriddenConditionalDefaultValueExpressions) {
            return undefined;
        }
        return getConditionalDefaultValueConfigMetaData(
            resource,
            some(
                JSON.stringify({
                    conditionalDefaultValueExpressions: overriddenConditionalDefaultValueExpressions,
                }),
            ),
            viewConfig,
        ).toUndefined(); // not really handling the error here.
    }, [overriddenConditionalDefaultValueExpressions, viewConfig, resource]);
};
