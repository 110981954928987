import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { DesktopDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { IconButton } from '@material-ui/core';
import { TextFieldUtils } from 'fieldFactory/input/hooks/useTextFieldUtils';
import Clear from '@material-ui/icons/Clear';
import { Moment } from 'moment';
import moment from 'moment';
import uniqueId from 'lodash/uniqueId';
import { Theme, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import memoizeOne from 'memoize-one';
import { getUseFullWidthDateFieldsSelector } from 'util/applicationConfig';
import { DateFormatContext } from 'fieldFactory/dateFormat/Broadcasts';
import dateToDateTimeString from './dateToDateTimeString';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import { useAppSelector } from 'reducers/rootReducer';

export type DateTimeProps = ({ mode: 'YYYYMMDD' } | { mode?: 'ISO'; setUstTime?: string }) & {
    isRequired?: boolean;
    addField?: boolean;

    input: any;
    meta: any;
    label: string | null;
    options?: {};
    disabled?: boolean;
    fullWidth?: boolean;
    ariaInputProps?: {};
    renderLabel?: boolean;
    /**
     * TODO add override for timezone noon
     */
    mode?: 'YYYYMMDD' | 'ISO';
    hideCalendarButton?: boolean;
    overrideAriaLabel?: string;
    dateFormat?: string;
};
const V5DateTimePicker: React.FC<DateTimeProps> = (props) => {
    const {
        input,
        label,
        meta,
        options,
        ariaInputProps,
        disabled = false,
        renderLabel = true,
        hideCalendarButton = false,
        overrideAriaLabel,
    } = props;
    const templatedLabel = useEvaluateTemplateInFormContext(label);
    const { touched, error } = meta;
    const lastValueWasEmptyRef = useRef(!props.input.value);
    useEffect(() => {
        // update after component updates
        lastValueWasEmptyRef.current = !props.input.value;
    });

    const [selectedDate, setSelectedDate] = useState<string>('');
    const [invalidDateFormat, setInvalidDateFormat] = useState(false);
    const pickerInputRef = useRef<HTMLInputElement>();
    const [key, refresh] = useReducer((state) => state + 1, 1);
    const _dateFormat = useContext(DateFormatContext);
    const dateFormat = props.dateFormat ?? _dateFormat;
    const handleDateChange = (e: Moment) => {
        setSelectedDate(String(e));

        if (!e) {
            setSelectedDate('');
            onChange(null);
            onBlur(null);
            setInvalidDateFormat(false);
            return;
        }
        if (e.isValid && !e.isValid()) {
            setInvalidDateFormat(true);
            return;
        }

        if (e.isValid()) {
            let d = dateToDateTimeString(e);
            onChange(d);
            onBlur(d);
        } else {
            onChange(null);
            onBlur(null);
        }
        setInvalidDateFormat(false);
    };
    useEffect(() => {
        // when the input is remounted, lets fix the invalidDateFormat according to whether the displayed value is valid
        // fixes IADSS-198
        const valueAfterRefresh = pickerInputRef.current?.value;
        if (
            valueAfterRefresh &&
            invalidDateFormat &&
            moment(valueAfterRefresh, `${dateFormat}  hh:mm A`, true).isValid()
        ) {
            setInvalidDateFormat(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [key]);

    const onChange = props.input.onChange;
    const onBlur = props.input.onBlur;
    const clearInput = useCallback(() => {
        onChange({ target: { value: null } });
        onBlur(null);
        pickerInputRef.current?.focus();
    }, [onBlur, onChange]);

    const inputProps = {};
    const errorMessageId = useMemo(() => uniqueId('date-errormsg'), []);

    const htmlErrorMessageId = (touched && error && errorMessageId) || undefined;
    if (htmlErrorMessageId) {
        inputProps['aria-describedby'] = htmlErrorMessageId;
        inputProps['aria-errormessage'] = htmlErrorMessageId;
    }
    const _getInputValue = useMemo(
        () =>
            memoizeOne((value: string, mode: 'YYYYMMDD' | 'ISO') => {
                return moment(value) || null;
            }),
        [],
    );
    const getInputValue = useCallback(() => {
        return _getInputValue(props.input.value, props.mode);
    }, [props.mode, props.input.value, _getInputValue]);

    const themeV5: Theme = useTheme();
    const fullWidth = useAppSelector(getUseFullWidthDateFieldsSelector);

    return (
        <div style={fullWidth ? { width: '100%' } : undefined}>
            <div style={{ position: 'relative', display: 'inline-block', width: fullWidth ? '100%' : undefined }}>
                <TextFieldUtils meta={meta}>
                    {({
                        createInputLabelProps,
                        fieldVariant,
                        muiErrorProp,
                        InputPropsClasses,
                        createFormHelperTextProps,
                        helperText,
                    }) => (
                        <>
                            <EvaluateFormattedMessage>
                                {({ translate }) => {
                                    const invalidDateMsg =
                                        invalidDateFormat &&
                                        (!lastValueWasEmptyRef.current ||
                                            // if all characters filled, display the error if it's invalid, even if the date is not accepted.
                                            /\d{2}\/\d{2}\/\d{4}/.test(selectedDate))
                                            ? translate({ id: 'date.invalidFormat' })
                                            : '';

                                    return (
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DesktopDateTimePicker
                                                label={renderLabel && templatedLabel}
                                                format={dateFormat + ' hh:mm A'}
                                                value={input.value !== '' ? getInputValue() : null}
                                                onChange={handleDateChange}
                                                disabled={disabled}
                                                {...options}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: fullWidth,
                                                        error: Boolean(muiErrorProp || invalidDateMsg),
                                                        variant: fieldVariant,
                                                        inputRef: (ref) => {
                                                            pickerInputRef.current = ref;
                                                        },
                                                        helperText: Boolean(muiErrorProp || invalidDateMsg)
                                                            ? `${invalidDateMsg ? invalidDateMsg + '\n' : ''}${
                                                                  helperText ?? ''
                                                              }`
                                                            : invalidDateMsg || undefined,
                                                        FormHelperTextProps: createFormHelperTextProps(inputProps),
                                                        InputLabelProps: {
                                                            style:
                                                                themeV5.palette.mode !== 'dark'
                                                                    ? undefined
                                                                    : {
                                                                          color: !error
                                                                              ? grey[300]
                                                                              : themeV5.palette.error.main,
                                                                      },
                                                            ...createInputLabelProps({ disabled: false, shrink: true }),
                                                            htmlFor: templatedLabel,
                                                        },
                                                        InputProps: {
                                                            ...ariaInputProps,
                                                            ...inputProps,
                                                            'aria-label': overrideAriaLabel ?? templatedLabel,

                                                            classes: invalidDateMsg ? undefined : InputPropsClasses,
                                                        },
                                                    },
                                                }}
                                            />
                                        </LocalizationProvider>
                                    );
                                }}
                            </EvaluateFormattedMessage>
                            {!disabled && input.value && input.value.length > 0 && (
                                <IconButton
                                    aria-label={`Clear date for ${overrideAriaLabel ?? templatedLabel}`}
                                    style={
                                        !fieldVariant || fieldVariant === 'standard'
                                            ? {
                                                  position: 'absolute',
                                                  right: 30 - (hideCalendarButton ? 25 : 0),
                                                  top: renderLabel ? 8 : -8,
                                              }
                                            : {
                                                  position: 'absolute',
                                                  right: 35 - (hideCalendarButton ? 45 : 0),
                                                  top: 4,
                                              }
                                    }
                                    onClick={() => {
                                        clearInput();
                                    }}
                                >
                                    <Clear />
                                </IconButton>
                            )}
                        </>
                    )}
                </TextFieldUtils>
            </div>
        </div>
    );
};

export default V5DateTimePicker;
