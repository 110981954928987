import React from 'react';
import compose from 'recompose/compose';
import { createSelector } from 'reselect';
import { change, formValueSelector } from 'redux-form';
import invert from 'lodash/invert';
import { connect } from 'react-redux';
import memoizeOne from 'memoize-one';
import { ThrowReporter } from 'io-ts/lib/ThrowReporter';
import withPropsOnChange from 'recompose/withPropsOnChange';
import { withStyles } from '@material-ui/core/styles';
import { RootState } from '../../../../reducers/rootReducer';
import withFormContext, { InjectedProps as ReduxFormContextProps } from '../hoc/withFormContext';
import ValueSetSelect from '../ValueSelectDownshift';
import TextInput from '../TextInput';
import getConfiguredValidation from '../../../translation/fromEntity/getConfiguredValidation';
import {
    Button,
    List,
    ListItem,
    Collapse,
    CircularProgress,
    Divider,
    Typography,
    ListItemText,
} from '@material-ui/core';
import { Concept } from '../Concept';
import { getConceptsByValueSet } from '../../../../components/generics/utils/valueSetsUtil';
import {
    prependZerosTo3And4DigitZipCodes,
    mapValuesToOriginalKeys,
    valuesIsLine1Line2,
    changeActionsAreLine1Line2,
    configIsLine1Line2,
    addressFieldLabels,
    renderVerificationMessage,
} from './util';
import './registerEVIConfigValidation';
import {
    IFieldMapping1,
    IFieldMapping2,
    FieldMapping,
    IFieldMapping,
    IAddressWidgetConfig,
    Suggestion,
    mappedActions,
    Values,
    VerifyResponse,
    InputSuggestion,
    AddressPopoverValue,
} from './types';
import { loadValueSet as loadValueSetAction } from 'valueSets/actions';
import { fromNullable, fromPredicate } from 'fp-ts/lib/Option';
import getStateFromZip from './getStateFromZip';
import { getAddressValidation } from './getAddressValidation';
import formTypeContext, { FormType } from 'components/generics/form/formTypeContext';
import fromEntries from 'util/fromentries';
import { initialize as initializeAction } from 'redux-form';
import buildHeaders from 'sideEffect/buildHeaders';
const config = require('../../../../config.js');

type Input = any;
type Meta = any;

const practicallyEqual = (v1, v2) => {
    if (typeof v1 !== 'number' && typeof v2 !== 'number' && !v1 && !v2) {
        // if they are both falsy non-numbers, they should be considered equal
        // (based on the kinds of fields in the address widget)
        return true;
    }
    return v1 === v2;
};

const mapValuesToSuggestion = (
    formValues: {},
    concepts: { [id: number]: Concept },
    invertedAddrFieldConfig: {},
): InputSuggestion => {
    const values: Values = mapValuesToOriginalKeys(formValues, invertedAddrFieldConfig);
    const line2 = () => {
        if (valuesIsLine1Line2(values)) {
            return values.casetivityLine2;
        }
        const { casetivityHouse, casetivityStreet } = values;
        return [casetivityHouse, casetivityStreet]
            .filter((f) => f)
            .join(' ')
            .trim();
    };
    const line1 = () => {
        if (valuesIsLine1Line2(values)) {
            return values.casetivityLine1;
        }
        const { casetivityUnit: _unit } = values;
        const unit = _unit ? `Unit ${_unit}` : '';
        // return [building, unit].join(' ').trim();
        return unit;
    };
    const getStrNum = () => {
        if (!valuesIsLine1Line2(values)) {
            const { casetivityHouse } = values;
            return casetivityHouse;
        }
        return values.casetivityLine2.split(' ').find((token) => /\d/.test(token)); // return the first token containing a number
    };
    const getStrName = () => {
        if (!valuesIsLine1Line2(values)) {
            const { casetivityStreet } = values;
            // const strType = strTypeCode || (strTypeId && (concepts[strTypeId] || {}).display);
            // return [strName, strType, unit].filter(f => f).join(' ').trim();
            return casetivityStreet;
        }
        return values.casetivityLine2
            .split(' ')
            .filter((token) => !/\d/.test(token))
            .join(' '); // strip out any tokens containing a number
    };
    const getUnit = () => {
        if (!valuesIsLine1Line2(values)) {
            const { casetivityUnit } = values;
            return casetivityUnit;
        }
        return values.casetivityLine1.split(' ').find((token) => /\d/.test(token)); // TOFIX: don't know what the line1/line2 format is yet.
    };
    const getNeighborhoodCode = () => {
        const { casetivityNeighborhoodCode, casetivityNeighborhoodId } = values;
        return (
            casetivityNeighborhoodCode || (casetivityNeighborhoodId && (concepts[casetivityNeighborhoodId] || {}).code)
        );
    };
    const {
        casetivityStateId,
        // casetivityCityId,
        casetivityZip,
        casetivityStateCode,
        // casetivityCityCode
    } = values;
    const getStateCode = () =>
        casetivityStateCode || (casetivityStateId && (concepts[casetivityStateId] || {}).code) || '';
    /*
    const getTownCodeCode = () => casetivityCityCode ||
        (casetivityCityId && (concepts[casetivityCityId] || {}).display) || '';
    */
    return {
        casetivityHouse: getStrNum(),
        casetivityStreet: getStrName(),
        casetivityUnit: getUnit(),
        casetivityLine1: line1(),
        casetivityLine2: line2(),
        casetivityStateCode: getStateCode(),
        // casetivityCityCode: getTownCodeCode(),
        casetivityNeighborhoodCode: getNeighborhoodCode(),
        casetivityZip,
    };
};

type FixedValues = {
    [field in keyof IFieldMapping]?: any;
};
export interface AddressValidationInputProps extends ReduxFormContextProps {
    validate?: (value: string | false, allValues: {}, props: AddressValidationInputProps) => string | false;
    config: IAddressWidgetConfig['fieldMapping'];
    source: string;
    meta: Meta;
    input: { value: AddressPopoverValue; onBlur: (newValue: AddressPopoverValue) => void };
    label: string;
    changeActions: mappedActions<IFieldMapping>;
    values: {};
    formValues: Values;
    fixedValues?: FixedValues;
    syncErrors?: {
        [fname: string]: string;
    };
    formFields?: {
        [fname: string]: {
            visited: boolean;
            touched: boolean;
        };
    };
    viewConfig: RootState['viewConfig'];
    concepts: {
        [id: number]: Concept;
    };
    stateConceptsByCode: {
        [code: string]: Concept;
    };
    townCodeConceptsByCode: {
        [code: string]: Concept;
    };
    communityConceptsByCode: {
        [code: string]: Concept;
    };
    classes: {
        actions: string;
        error: string;
        expand: string;
        expandOpen: string;
        primary: string;
        rightIcon: string;
    };
    addrFieldConfig: IFieldMapping;
    invertedAddrFieldConfig: { [key: string]: string };
    disabled?: boolean;
    useAddressEntityValidations: boolean;
    manualVerification: IAddressWidgetConfig['manualVerificationAllowed'];
    verificationRequired: IAddressWidgetConfig['verificationRequired'];
    verifStatusConceptsByCode: {
        [code: string]: Concept;
    };
    loadVerifStatusValueSet: () => void;
    allValuesetsLoaded: boolean;
    showVerificationStatus: boolean;
    setGisIdentifier: (gisIdentifier: string) => void;
    dispatchInitialize: (values: {}) => void;
}

interface AddressValidationInputState {
    showSuggestions: boolean;
    loading: boolean;
    requestError: string | null;
    suggestions: Suggestion[];
    validatedFieldValues: {} | null;
    popoverOpen: boolean;
    requestFailed: boolean;
    exactMatchNotifShow: boolean;
    cleanValues: {} | null;
    skipDirtyCheckOnUpdate: boolean;
}

type InternalValidation = (value: string, fixedValues: FixedValues) => string | undefined;

const styles = (theme) => ({
    actions: {
        cursor: 'pointer',
        display: 'flex',
        // paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
        overflow: 'show',
    },
    error: {
        color: theme.palette.error.main,
    },
    primary: {
        color: theme.palette.primary.main,
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

class AddressValidationInput extends React.Component<AddressValidationInputProps, AddressValidationInputState> {
    _getFixedValues = memoizeOne((fixedValues, allValuesetsLoaded) => this.setupCodesAndIds(fixedValues));
    constructor(props: AddressValidationInputProps) {
        super(props);
        this.state = {
            showSuggestions: false,
            loading: false,
            requestError: null,
            suggestions: [],
            requestFailed: false,
            validatedFieldValues: null,
            popoverOpen: false,
            exactMatchNotifShow: false,
            cleanValues: null,
            skipDirtyCheckOnUpdate: false,
        };
    }
    componentDidMount() {
        if (this.props._reduxForm) {
            this.setup(this.props);
        }
    }
    shouldPreventGisLookup = () => {
        return !!Object.entries(this.internalValidations).find(
            ([k, validation]: [keyof IFieldMapping, InternalValidation]) => {
                return !!validation(this.getFieldValue(k), this.getFixedValues());
            },
        );
    };
    internalValidations: {
        [key in keyof typeof addressFieldLabels]?: InternalValidation;
    } = {
        casetivityZip: (zipCode: string, fixedValues) => {
            if (!zipCode) {
                // let the regular validation take precedence and handle this case
            } else if (zipCode.length !== 0 && zipCode.length !== 5) {
                return 'Zip must be a 5 digit zip code';
            } else {
                const fixedStateCode = fixedValues.casetivityStateCode;
                const possibleStates = getStateFromZip(zipCode);
                if (fixedStateCode && possibleStates.indexOf(fixedStateCode) === -1) {
                    return `"${zipCode}" is not a valid ${fixedStateCode} zipcode`;
                }
            }
            return undefined;
        },
        casetivityNeighborhoodId: (commId: string, fixedValues) => {
            if (!commId && !fixedValues.casetivityNeighborhoodCode) {
                return 'Please provide a Neighborhood/City';
            }
            return undefined;
        },
        casetivityStreet: (casetivityStreet, fixedValues) => {
            if (!casetivityStreet) {
                return 'Please provide a Street Name';
            }
            return undefined;
        },
        casetivityHouse: (casetivityHouse, fixedValues) => {
            if (!casetivityHouse) {
                return 'Please provide a Street Number';
            }
            return undefined;
        },
    };
    setup(props: AddressValidationInputProps) {
        const { changeActions, _reduxForm, viewConfig, useAddressEntityValidations } = props;
        /*
        We shouldn't need to handle this ourselves, each view should fetch the valueset knowing it is going to need it for the AddressWidget.
        loadVerifStatusValueSet();
        */
        Object.keys(changeActions).forEach((fname) => {
            _reduxForm.registerField(fname, 'Field');
        });
        if (useAddressEntityValidations) {
            Object.keys(changeActions).forEach((fname) => {
                const validations = getConfiguredValidation(
                    viewConfig,
                    'Address',
                    fname.endsWith('Id') ? fname.slice(0, -2) : fname,
                    fname.endsWith('Id') ? 'SELECT' : 'TEXTBOX',
                );
                _reduxForm.register(fname, 'Field', () => validations);
            });
        }
        this.setFixedValuesInForm();
    }
    componentWillUpdate(nextProps: AddressValidationInputProps) {
        if (!this.props._reduxForm && nextProps._reduxForm) {
            this.setup(nextProps);
        }
    }
    setFixedValuesInForm() {
        const { fixedValues, _reduxForm, addrFieldConfig } = this.props;
        if (fixedValues) {
            // _reduxForm.initialize for some reason overwrote the dirty values, even with keepDirtyOnReinitialize
            // (that's why using a custom action dispatch with the 'keepDirty' flag explicitly set)
            // _reduxForm.initialize({
            this.props.dispatchInitialize({
                ..._reduxForm.initialValues,
                ...fromEntries(
                    Object.entries(this.getFixedValues()).map(([casetivityField, fixedValue]) => {
                        const formKey = addrFieldConfig[casetivityField];
                        return [formKey, fixedValue] as [string, string];
                    }),
                ),
            });
        }
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        const { values, input, concepts, invertedAddrFieldConfig, allValuesetsLoaded } = this.props;
        const { validatedFieldValues } = this.state;

        if (
            // the values are changed to dirty before all valuesets are loaded
            !allValuesetsLoaded &&
            !this.valuesAreDirty(prevProps) &&
            this.valuesAreDirty() &&
            !validatedFieldValues
        ) {
            this.setState({ cleanValues: values });
            return;
        }
        if (
            // the valuesets are now loaded, and there's a previous 'cleanValues' saved
            allValuesetsLoaded &&
            this.state.cleanValues &&
            !validatedFieldValues
        ) {
            this.setState(
                {
                    validatedFieldValues: mapValuesToSuggestion(
                        this.state.cleanValues,
                        concepts,
                        invertedAddrFieldConfig,
                    ),
                },
                this.setFixedValuesInForm,
            );
            return;
        }
        if (
            // allvaluesets are loaded and the values are still clean, and validatedFieldValues aren't saved
            allValuesetsLoaded &&
            !this.valuesAreDirty(this.props) &&
            !validatedFieldValues
        ) {
            this.setState(
                {
                    validatedFieldValues: mapValuesToSuggestion(values, concepts, invertedAddrFieldConfig),
                },
                this.setFixedValuesInForm,
            );
            return;
        }
        // if currently validated (input.value === true),
        // if the set marked as validated doesn't match all the actual form values, we are no longer validated,
        // so set our value to false (input.value === false)
        if (
            !this.state.skipDirtyCheckOnUpdate &&
            validatedFieldValues &&
            input.value !== 'NO_MATCH' &&
            input.value !== 'ERROR'
        ) {
            const formEntries = Object.entries(values);
            // don't check for length - just see if the values are different.
            // this is because we don't update all the fields atomically
            const someFieldChanged = formEntries.find(([formId, value]) => {
                const correspondingField = this.props.invertedAddrFieldConfig[formId];
                if (this.getFixedValues()[correspondingField]) {
                    /*
                    TODO: Log an error here if this value has changed.
                    */
                    return false;
                }
                const propertyInValidation = Object.prototype.hasOwnProperty.call(
                    validatedFieldValues,
                    correspondingField,
                );
                const validatedValue = validatedFieldValues[correspondingField];
                // unit is always displayed right now, so
                // lets check against it even if it's not in validatedFieldValues.
                const res =
                    (propertyInValidation || correspondingField === 'casetivityUnit') &&
                    !practicallyEqual(validatedValue, value);

                if (res && (window as any).CASETIVITY_ADDRESS_DEBUG) {
                    console.log(
                        `validatedFieldValues[${correspondingField}] (${validatedValue}) !== this.props.values[${formId}] (${value})`,
                    );
                }
                return res;
            });
            if (someFieldChanged) {
                console.log('someFieldChanged in componentWillReceiveProps');
                this.updateFieldsFromSuggestions({
                    casetivityCityCode: '',
                });
                input.onBlur('NO_MATCH');
                this.props.setGisIdentifier(null);
            }
        }
    }
    valuesAreDirty = (props: AddressValidationInputProps = this.props) => {
        if (!props._reduxForm) {
            return false;
        }
        const initialValues = props._reduxForm.initialValues;
        return !!Object.entries(props.values).find(([formId, value]) => {
            const fixedValues = this._getFixedValues(props.fixedValues, props.allValuesetsLoaded);
            const res =
                !!props.invertedAddrFieldConfig[formId] &&
                value !== initialValues[formId] &&
                typeof fixedValues[props.invertedAddrFieldConfig[formId]] === 'undefined';
            return res;
        });
    };
    setError = (errorStr: string) => {
        this.setState({
            requestError: errorStr,
            showSuggestions: true,
            loading: false,
            suggestions: [],
            requestFailed: false,
        });
    };
    getSuggestions = () => {
        const { values, concepts, invertedAddrFieldConfig, addrFieldConfig } = this.props;
        const reqBodyWithAllFields = {
            ...mapValuesToSuggestion(values, concepts, invertedAddrFieldConfig),
            ...this.getFixedValues(),
        };

        /*
            we could configure which fields to send in config. Right now just switch on the presence of line1 */
        const { casetivityLine1, casetivityLine2, casetivityHouse, casetivityStreet, casetivityUnit, ...rest } =
            reqBodyWithAllFields;
        const reqBody = configIsLine1Line2(addrFieldConfig)
            ? {
                  casetivityLine1,
                  casetivityLine2,
                  ...rest,
              }
            : { casetivityHouse, casetivityStreet, casetivityUnit, ...rest };

        this.setState({ loading: true }, async () => {
            const rawResponse = await fetch(`${config.API_URL}verify`, {
                method: 'POST',
                credentials: 'same-origin',
                headers: buildHeaders({
                    includeCredentials: true,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                }),
                body: JSON.stringify(reqBody),
            }).catch(() => {
                this.setState({
                    requestError: 'Failed to reach server',
                    loading: false,
                    suggestions: [],
                    requestFailed: true,
                    showSuggestions: true, // error is shown in the suggestions area
                });
                return null;
            });
            // address error
            if (!rawResponse) {
                return;
            }
            if (rawResponse.status !== 200) {
                this.setError('Request Error');
                return;
            }
            const content: VerifyResponse = await rawResponse.json();

            if (content.current) {
                const current = content.exact ? content.recommendations[0] || content.current : content.current;
                // don't update the neighborhood code if present already.
                let valuesToUpdateInForm;
                if (reqBody.casetivityNeighborhoodCode) {
                    const { casetivityNeighborhoodCode, ...restOfCurrent } = current;
                    valuesToUpdateInForm = restOfCurrent;
                } else {
                    valuesToUpdateInForm = current;
                }
                this.updateFieldsFromSuggestions(valuesToUpdateInForm);
            }
            if (content.errMessage) {
                // 'no suggestions found' comes back in the error message.
                this.setError(content.errMessage);
                return;
            }
            if (content.exact) {
                const setup = this.setupCodesAndIds(
                    content.recommendations[0]
                        ? { ...content.current, ...content.recommendations[0] }
                        : content.current,
                );
                this.currentValuesVerified('FOUND_ADDRESS')(setup)();
                this.setState(
                    {
                        exactMatchNotifShow: true,
                    },
                    () =>
                        setTimeout(
                            () =>
                                this.setState({
                                    exactMatchNotifShow: false,
                                }),
                            1000,
                        ),
                );
            } else {
                this.setState({
                    requestError: null,
                    showSuggestions: true,
                    loading: false,
                    suggestions:
                        content.recommendations &&
                        // filter out suggestions that don't correspond to our fixed values
                        content.recommendations.filter(
                            (r) => !Object.entries(this.getFixedValues()).find(([ff, fv]) => r[ff] && fv !== r[ff]),
                        ),
                    requestFailed: false,
                });
            }
        });
    };
    getFixedValues = (fixedValues = this.props.fixedValues) =>
        this._getFixedValues(fixedValues, this.props.allValuesetsLoaded);

    getFieldValue = (hfn: keyof IFieldMapping) => {
        const { formValues, values, addrFieldConfig } = this.props;
        const fname = addrFieldConfig[hfn];
        return fname?.endsWith('Id') ? { ...formValues, ...this.getFixedValues() }[hfn] : values[fname] || '';
    };
    renderFieldGroup = (fields: (keyof typeof addressFieldLabels)[]) => {
        const { syncErrors, formFields, _reduxForm, addrFieldConfig, disabled, fixedValues } = this.props;
        if (!_reduxForm) {
            return null;
        }
        const addressFieldsAreDirty = this.valuesAreDirty();
        return (
            <div style={{ marginTop: '16px', marginBottom: '8px' }}>
                {fields
                    .map((hfn) => {
                        return [
                            addrFieldConfig[hfn],
                            hfn,
                            disabled ||
                                hfn.startsWith('casetivityCity') ||
                                !!(fixedValues && this.getFixedValues()[hfn]),
                        ] as const;
                    })
                    .filter((f) => f[0])
                    .map(([fname, basename, isDisabled]) => {
                        const value = this.getFieldValue(basename as keyof IFieldMapping);

                        const internalValidationMessage = fromNullable(this.internalValidations[basename])
                            .mapNullable((internalValidation) => internalValidation(value, this.getFixedValues()))
                            .getOrElse(undefined);
                        const error = internalValidationMessage || (syncErrors && syncErrors[fname]);
                        const touched =
                            /*
                                (Address requires verification OR there's an address-level validation error)
                                &&
                                (
                                    some address fields were changed and there is an internal validation
                                    OR
                                    the field was touched at the form-level
                                )
                            */
                            (getAddressValidation(
                                {
                                    fixedValues: fixedValues as any,
                                    verificationRequired: this.props.verificationRequired,
                                    fieldMapping: this.props.config,
                                    validationMessage: 'FAILED',
                                },
                                this.props.source,
                            )(this.props.input.value, this.props.values, {}) ||
                                (syncErrors && syncErrors[fname])) &&
                            !(fixedValues && this.getFixedValues()[basename]) &&
                            (!!(addressFieldsAreDirty && internalValidationMessage) ||
                                (formFields && formFields[fname] && formFields[fname].touched));
                        return fname.endsWith('Id') ? (
                            <ValueSetSelect
                                key={fname}
                                label={addressFieldLabels[basename]}
                                source={fname}
                                resource="Address"
                                input={{
                                    value,
                                    onBlur: (value) => {
                                        if (typeof value !== 'undefined') {
                                            this.props.changeActions[fname](value);
                                        }
                                        _reduxForm.touch(fname);
                                    },
                                }}
                                emptyText={''}
                                meta={{ touched, error }}
                                disabled={isDisabled}
                                // valueset should be fetched by the root page (Edit/Show/TaskForm/Create)
                                shouldFetchValueset={false}
                            />
                        ) : (
                            <TextInput
                                label={addressFieldLabels[basename]}
                                source={fname}
                                key={fname}
                                input={{
                                    value,
                                    onBlur: (e) => {
                                        this.props.changeActions[fname](e.target.value);
                                        _reduxForm.touch(fname);
                                    },
                                    onChange: (e) => {
                                        this.props.changeActions[fname](e.target.value);
                                    },
                                }}
                                meta={{
                                    touched,
                                    error,
                                }}
                                resource="Address"
                                validate={() => null}
                                options={{
                                    fullWidth: true,
                                }}
                                disabled={isDisabled}
                            />
                        );
                    })}
            </div>
        );
    };
    callIfConfigured = (stdKey: keyof (IFieldMapping1 & IFieldMapping2), arg: any) => {
        const { changeActions, addrFieldConfig } = this.props;
        const formKey = addrFieldConfig[stdKey];
        if (formKey && changeActions[formKey]) {
            changeActions[formKey](arg);
        }
    };
    updateFieldsFromSuggestions = (s: Partial<Suggestion>) => {
        const { stateConceptsByCode, communityConceptsByCode, townCodeConceptsByCode, changeActions, fixedValues } =
            this.props;
        Object.keys(s).forEach((fname: keyof Suggestion) => {
            if (fixedValues && fixedValues[fname]) {
                return;
            }
            switch (fname) {
                case 'casetivityStateCode': {
                    const conc = stateConceptsByCode[(s.casetivityStateCode || '').toLowerCase()];
                    this.callIfConfigured('casetivityStateId', (conc && conc.id) || null);
                    this.callIfConfigured('casetivityStateCode', s.casetivityStateCode);
                    break;
                }
                case 'casetivityCityCode': {
                    const conc = townCodeConceptsByCode[(s.casetivityCityCode || '').toLowerCase()];
                    this.callIfConfigured('casetivityCityId', (conc && conc.id) || null);
                    this.callIfConfigured('casetivityCityCode', s.casetivityCityCode);
                    break;
                }
                case 'casetivityLine2':
                    if (!s.casetivityLine2) {
                        return;
                    }
                    if (changeActionsAreLine1Line2(changeActions)) {
                        changeActions.casetivityLine2(s.casetivityLine2);
                    } else {
                        const [casetivityHouse, restOfLine2] = s.casetivityLine2.split(/[ ,]+/, 2);
                        this.callIfConfigured('casetivityHouse', casetivityHouse);

                        const casetivityStreet = restOfLine2;
                        this.callIfConfigured('casetivityStreet', casetivityStreet);
                    }
                    break;
                case 'casetivityZip':
                    this.callIfConfigured('casetivityZip', prependZerosTo3And4DigitZipCodes(s.casetivityZip));
                    break;
                case 'casetivityLine1':
                    if (!s.casetivityLine1) {
                        return;
                    }
                    const line1 = s.casetivityLine1.split(/[ ,]+/);
                    let updatedUnit = false;
                    line1.forEach((v, i) => {
                        if (v.toLowerCase() === 'unit') {
                            this.callIfConfigured('casetivityUnit', line1[i + 1]);
                            updatedUnit = true;
                        }
                    });
                    if (!updatedUnit) {
                        this.callIfConfigured('casetivityUnit', null);
                    }
                    break;
                case 'casetivityHouse':
                    this.callIfConfigured('casetivityHouse', s.casetivityHouse);
                    break;
                case 'casetivityStreet':
                    this.callIfConfigured('casetivityStreet', s.casetivityStreet);
                    break;
                case 'casetivityNeighborhoodCode': {
                    const conc = communityConceptsByCode[(s.casetivityNeighborhoodCode || '').toLowerCase()];
                    this.callIfConfigured('casetivityNeighborhoodId', (conc && conc.id) || null);
                    this.callIfConfigured('casetivityNeighborhoodCode', s.casetivityNeighborhoodCode);
                    break;
                }
                case 'casetivityUnit':
                    this.callIfConfigured('casetivityUnit', s.casetivityUnit);
                    break;
                default:
            }
        });
    };
    onSuggestionClick = (s: Suggestion) => () => {
        const values: Suggestion = { casetivityUnit: '', ...this.setupCodesAndIds(s) };
        this.setState(
            {
                skipDirtyCheckOnUpdate: true,
            },
            () => {
                this.updateFieldsFromSuggestions(values);
                this.props.setGisIdentifier(s.externalGISId || null);
                this.setState({ showSuggestions: false, validatedFieldValues: values }, () => {
                    this.props.input.onBlur('FOUND_ADDRESS');
                    // Lets let React update everything before we allow check for dirtyness so props.values and state.validatedFieldValues are in-sync
                    setTimeout(() => {
                        this.setState({ skipDirtyCheckOnUpdate: false });
                    }, 0);
                });
            },
        );
    };
    setupCodesAndIds = (values: IAddressWidgetConfig['fieldMapping'] = {}) => {
        const { townCodeConceptsByCode, communityConceptsByCode, stateConceptsByCode } = this.props;
        // setup Codes and Ids if found
        return Object.assign(
            {},
            ...Object.entries(values).flatMap(
                ([key, _value]: [keyof IAddressWidgetConfig['fieldMapping'], any]): {} => {
                    const value = typeof _value === 'string' ? _value.toLowerCase() : _value;
                    if (key === 'casetivityCityCode') {
                        const conc = townCodeConceptsByCode[value];
                        const id = conc && conc.id;
                        return id ? [{ casetivityCityId: id }] : [];
                    }
                    if (key === 'casetivityNeighborhoodCode') {
                        const conc = communityConceptsByCode[value];
                        const id = conc && conc.id;
                        return id ? [{ casetivityNeighborhoodId: id }] : [];
                    }
                    if (key === 'casetivityStateCode') {
                        const conc = stateConceptsByCode[value];
                        const id = conc && conc.id;
                        return id ? [{ casetivityStateId: id }] : [];
                    }
                    return [];
                },
            ),
            values,
        );
    };
    currentValuesVerified = (verificationType: 'FOUND_ADDRESS' | 'MANUAL_OVERRIDE') => (values) => () => {
        const gisIdentifier = values.gisIdentifier || null;
        this.setState(
            {
                showSuggestions: false,
                // setup Id/Concept etc. so this works where Ids can be checked for valuesets
                validatedFieldValues: values,
                popoverOpen: false,
                loading: false,
                requestFailed: false,
            },
            () => {
                this.props.setGisIdentifier(gisIdentifier);
                this.props.input.onBlur(verificationType);
            },
        );
    };
    cellWidth = (multiples: number, columns: number) => `calc(${multiples * (100 / columns)}% - 6px)`;
    renderVerificationMessage = () => {
        const { showVerificationStatus, classes, input, verifStatusConceptsByCode } = this.props;
        if (!showVerificationStatus) {
            return null;
        }
        return renderVerificationMessage(classes, input.value, verifStatusConceptsByCode);
    };
    getConceptsByCode = (set: 'Community' | 'City') => {
        const { communityConceptsByCode, townCodeConceptsByCode } = this.props;
        return set === 'Community' ? communityConceptsByCode : townCodeConceptsByCode;
    };
    getDisplayFromCode = (valueSet: 'Community' | 'City') => (code: string) => {
        const concept = this.getConceptsByCode(valueSet)[code && code.toLowerCase()];
        if (concept) {
            return concept.display;
        }
        return null;
    };
    getListItemPrimaryText = (s: Suggestion) => {
        const neighborhoodDisplay = this.getDisplayFromCode('Community')(s.casetivityNeighborhoodCode);
        const cityDisplay = this.getDisplayFromCode('City')(s.casetivityCityCode);
        return `${s.casetivityHouse || ''} ${s.casetivityStreet || ''}${
            s.casetivityUnit ? ` UNIT ${s.casetivityUnit}` : ''
        }, ${neighborhoodDisplay || ''}${
            cityDisplay && cityDisplay !== neighborhoodDisplay ? ` ${cityDisplay}` : ''
        }, ${s.casetivityStateCode || ''} ${s.casetivityZip || ''}`;
    };
    getRequestError = () => {
        const { viewConfig, addrFieldConfig } = this.props;
        const rx = /Please add a (.*)/gm;
        const { requestError } = this.state;
        if (requestError) {
            const matches = rx.exec(requestError);
            if (matches && matches[0] && matches[1]) {
                const casetivityField = matches[1];
                const possibleIndexes = [casetivityField, `${casetivityField}Id`, `${casetivityField}Code`];
                return fromNullable(possibleIndexes.find((ix) => addrFieldConfig[ix]))
                    .map((ix) => addrFieldConfig[ix])
                    .map((realField) =>
                        realField.endsWith('Code')
                            ? realField.slice(0, -'Code'.length)
                            : realField.endsWith('Id')
                            ? realField.slice(0, -'Id'.length)
                            : realField,
                    )
                    .mapNullable((f) => viewConfig.entities.Address.fields[f])
                    .map((f) => f.label)
                    .chain(fromPredicate<string>(Boolean))
                    .map((l) => `Please add a ${l}`)
                    .getOrElse(requestError); // <- the original field
            }
        }
        return requestError;
    };
    render() {
        const { meta, formValues, classes, addrFieldConfig, manualVerification } = this.props;
        return (
            <div>
                {this.renderVerificationMessage()}
                <div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        {configIsLine1Line2(addrFieldConfig) ? (
                            <div style={{ width: this.cellWidth(5, 5) }}>
                                {this.renderFieldGroup(['casetivityLine2'])}
                            </div>
                        ) : (
                            [
                                <div key="strNum" style={{ width: this.cellWidth(1, 5) }}>
                                    {this.renderFieldGroup(['casetivityHouse'])}
                                </div>,
                                <div key="strName" style={{ width: this.cellWidth(3, 5) }}>
                                    {this.renderFieldGroup(['casetivityStreet'])}
                                </div>,
                                <div key="unit" style={{ width: this.cellWidth(1, 5) }}>
                                    {this.renderFieldGroup(['casetivityUnit'])}
                                </div>,
                            ]
                        )}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        {configIsLine1Line2(addrFieldConfig) ? (
                            <div style={{ width: this.cellWidth(2, 2) }}>
                                {this.renderFieldGroup(['casetivityLine1'])}
                            </div>
                        ) : null}
                    </div>
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={{ width: this.cellWidth(1, 4) }}>
                            {this.renderFieldGroup([
                                addrFieldConfig.casetivityNeighborhoodId
                                    ? 'casetivityNeighborhoodId'
                                    : 'casetivityNeighborhoodCode',
                            ])}
                        </div>
                        <div style={{ width: this.cellWidth(1, 4) }}>
                            {this.renderFieldGroup([
                                addrFieldConfig.casetivityCityId ? 'casetivityCityId' : 'casetivityCityCode',
                            ])}
                        </div>
                        <div style={{ width: this.cellWidth(1, 4) }}>
                            {this.renderFieldGroup([
                                addrFieldConfig.casetivityStateId ? 'casetivityStateId' : 'casetivityStateCode',
                            ])}
                        </div>
                        <div style={{ width: this.cellWidth(1, 4) }}>{this.renderFieldGroup(['casetivityZip'])}</div>
                    </div>
                </div>
                {!this.props.disabled && (
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.shouldPreventGisLookup() || this.state.loading || this.props.disabled}
                        onClick={this.getSuggestions}
                    >
                        {this.state.loading ? 'loading...  ' : 'GIS Lookup'}
                        {this.state.loading ? <CircularProgress color="inherit" size={12} thickness={4} /> : ''}
                    </Button>
                )}
                <Collapse mountOnEnter={true} appear={true} unmountOnExit={true} in={this.state.exactMatchNotifShow}>
                    <Typography variant="subtitle1">Exact match found</Typography>
                </Collapse>
                <Collapse mountOnEnter={true} appear={true} unmountOnExit={true} in={this.state.showSuggestions}>
                    {this.state.suggestions.length > 0 ? <Divider style={{ marginTop: '.5em' }} /> : null}
                    <List style={{ overflow: 'auto', maxHeight: 300 }}>
                        {this.state.suggestions.map((s, i) => {
                            return (
                                <ListItem
                                    autoFocus={Boolean(i === 0)}
                                    role="listitem"
                                    key={i}
                                    button={true}
                                    onClick={this.onSuggestionClick(s)}
                                >
                                    <ListItemText
                                        primary={this.getListItemPrimaryText(s)}
                                        secondary={s.confidence && `(${s.confidence}% match)`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                    {this.state.suggestions.length === 0 ? (
                        <div style={{ textAlign: 'center', marginBottom: '.5em' }}>No suggested addresses</div>
                    ) : null}
                    {manualVerification === 'ALWAYS' ||
                    (manualVerification === 'ENDPOINT_REACHED' && !this.state.requestFailed) ? (
                        <Button
                            style={{ width: '100%', textAlign: 'center' }}
                            variant="contained"
                            onClick={this.currentValuesVerified('MANUAL_OVERRIDE')(formValues)}
                        >
                            Continue
                            <br />
                            (no match)
                        </Button>
                    ) : null}
                    {this.state.requestError ? (
                        <span className={classes.error} style={{ marginTop: '1em', display: 'inline-block' }}>
                            {this.getRequestError()}
                        </span>
                    ) : null}
                </Collapse>
                {meta.error && (
                    <span className={classes.error} style={{ marginTop: '1em', display: 'inline-block' }}>
                        {meta.error}
                    </span>
                )}
                <span aria-live="assertive" aria-atomic="true" className="casetivity-off-screen">
                    {(() => {
                        if (this.state.loading) {
                            return 'loading';
                        }
                        if (this.state.showSuggestions) {
                            if (this.state.requestError) {
                                return 'Error: ' + this.getRequestError();
                            }
                            if (this.state.suggestions.length === 0) {
                                return 'No suggested Addresses';
                            }
                            return '' + this.state.suggestions.length + ' possible matches found';
                        }
                        return null;
                    })()}
                </span>
            </div>
        );
    }
}

interface PAWithConfigValProps {
    showDisplayOnlyVersion?: (formType: FormType) => boolean;
    disabled?: boolean;
    source: string;
    meta: Meta;
    input: Input;
    label: string;
    config: IAddressWidgetConfig['fieldMapping'];
}
type ConnectLevelProps = PAWithConfigValProps & {
    addrFieldConfig: IFieldMapping;
    invertedAddrFieldConfig: { [key: string]: string };
};

const valuesetSelector = (valueName) => (state: RootState) => {
    const valueSets = state.valueSets;
    return valueSets && valueSets[valueName];
};
const allValuesetsLoadedSelector = (state: RootState) =>
    ['State', 'Community', 'TownCode', 'VerificationStatus'].reduce((prev, vsCode) => {
        const vs = valuesetSelector(vsCode)(state);
        return !!vs && !!vs.allConceptsLoaded && prev;
    }, true);
export const createConceptInvertedIndexSelector = (valueName) => (property) =>
    createSelector(
        valuesetSelector(valueName),
        (state: RootState) => state.admin.entities.Concept as any as { [id: string]: Concept },
        (valueSet, concepts) => {
            const conceptsList: Concept[] = getConceptsByValueSet(valueSet, concepts);
            return Object.assign({}, ...conceptsList.map((o) => ({ [o[property].toLowerCase()]: o })));
        },
    );
const makeMapStateToProps = () => {
    const selectors: { [formId: string]: Function } = {};
    const stateConceptsByCodeSelector = createConceptInvertedIndexSelector('State')('code');
    const communityConceptsByCodeSelector = createConceptInvertedIndexSelector('Community')('code');
    const townCodeConceptsByCodeSelector = createConceptInvertedIndexSelector('TownCode')('code');
    const verifStatusConceptsByCodeSelector = createConceptInvertedIndexSelector('VerificationStatus')('code');
    return (state: RootState, props: ConnectLevelProps) => {
        if (!selectors[props.meta.form]) {
            selectors[props.meta.form] = formValueSelector(props.meta.form);
        }
        const values = Object.assign(
            {},
            ...Object.keys(props.addrFieldConfig).map((fname) => ({
                [props.addrFieldConfig[fname]]: selectors[props.meta.form](state, props.addrFieldConfig[fname]),
            })),
        );
        return {
            values,
            formValues: Object.assign(
                {},
                ...Object.keys(props.invertedAddrFieldConfig).map((fname) => ({
                    [props.invertedAddrFieldConfig[fname]]: selectors[props.meta.form](state, fname),
                })),
            ),
            syncErrors: (state.form![props.meta.form] || {}).syncErrors,
            formFields: (state.form![props.meta.form] || {}).fields,
            viewConfig: state.viewConfig,
            concepts: state.admin.entities.Concept,
            stateConceptsByCode: stateConceptsByCodeSelector(state),
            communityConceptsByCode: communityConceptsByCodeSelector(state),
            townCodeConceptsByCode: townCodeConceptsByCodeSelector(state),
            verifStatusConceptsByCode: verifStatusConceptsByCodeSelector(state),
            allValuesetsLoaded: allValuesetsLoadedSelector(state),
        };
    };
};

export const withFieldConfigs = withPropsOnChange(
    ['config'],
    ({ config: addrConfig /* config: addrConfigRaw */ }: PAWithConfigValProps) => {
        // const addrConfig = JSON.parse(addrConfigRaw);
        const decodeResult = FieldMapping.decode(addrConfig);
        ThrowReporter.report(decodeResult);
        const addrFieldConfig: IFieldMapping = decodeResult.value as IFieldMapping;
        return {
            addrFieldConfig,
            invertedAddrFieldConfig: invert(addrFieldConfig),
        };
    },
);
const PopoverAddress: React.SFC<PAWithConfigValProps> = compose(
    (BaseComponent: React.SFC<PAWithConfigValProps>) =>
        ({ showDisplayOnlyVersion, ...props }: PAWithConfigValProps) => {
            if (showDisplayOnlyVersion) {
                return (
                    <formTypeContext.Consumer>
                        {(formType) => {
                            if (showDisplayOnlyVersion(formType)) {
                                return <BaseComponent {...props} disabled={true} />;
                            }
                            return <BaseComponent {...props} />;
                        }}
                    </formTypeContext.Consumer>
                );
            }
            return <BaseComponent {...props} />;
        },
    withFieldConfigs,
    connect(makeMapStateToProps, (dispatch, props: ConnectLevelProps) => ({
        dispatchInitialize: (values) => dispatch(initializeAction(props.meta.form, values, true)),
        changeActions: Object.assign(
            {},
            ...Object.keys(props.addrFieldConfig)
                .filter((fname) => props.addrFieldConfig[fname])
                .map((fname) => ({
                    [props.addrFieldConfig[fname]]: (value) =>
                        dispatch(change(props.meta.form, props.addrFieldConfig[fname], value)),
                })),
        ),
        setGisIdentifier: (gisIdentifier: string) => {
            dispatch(change(props.meta.form, 'gisIdentifier', gisIdentifier));
        },
        loadVerifStatusValueSet: () => dispatch(loadValueSetAction('VerificationStatus')),
    })),
    withFormContext,
    withStyles(styles),
)(AddressValidationInput);

export default PopoverAddress;
