import { EntityField, ViewField, searchTypes } from 'reducers/ViewConfigType';
import getTypeFromEntityField from './getTypeFromEntityField';

export const getUnpersistedFieldType = (
    widgetType: ViewField['widgetType'],
    {
        isValueset,
        isEntity,
        isMany,
        turnTextBoxToFloatOrIntBasedOnDataType,
        searchType,
        nameForErrorMessaging,
    }: {
        isValueset?: boolean;
        isEntity?: boolean;
        isMany?: boolean;
        turnTextBoxToFloatOrIntBasedOnDataType?: boolean;
        searchType?: searchTypes;
        nameForErrorMessaging?: string;
    },
) => {
    const dataTypeHint: EntityField['dataType'] = (() => {
        if (isValueset) {
            switch (widgetType) {
                case 'CHECKBOX': {
                    if (isMany) {
                        return 'VALUESETMANY';
                    }
                    return 'VALUESET';
                }
                case 'SELECT':
                    if (isMany) {
                        return 'VALUESETMANY';
                    }
                    return 'VALUESET';
                case 'MULTISELECT':
                    return 'VALUESETMANY';
                default:
                    console.error(
                        `unexpected fallthrough of unpersisted valueset field with widget type: ${widgetType}.`,
                    );
            }
        }
        if (isEntity) {
            switch (widgetType) {
                case 'SELECT':
                case 'ENTITY_TYPEAHEAD':
                    return 'REFONE';
                default:
                    return 'REFMANYMANY';
            }
        }
        switch (widgetType) {
            case 'INLINE_MANY':
                return 'REFMANYMANY';
            default:
                return undefined;
        }
    })();

    return getTypeFromEntityField(
        widgetType,
        dataTypeHint,
        nameForErrorMessaging,
        searchType,
        turnTextBoxToFloatOrIntBasedOnDataType,
    );
};
