import React, { useMemo, useState } from 'react';
import { OutputOptionsArea, ReportFormComponentProps, getOutputOptions } from '../ReportForm';
import BpmFormField from 'fieldFactory/input/components/bpmForm/BpmFormField';
import { ControlledForm } from 'fieldFactory/input/components/InlineMany/ControlledValidatedRowForm';
import { Field } from 'react-final-form';
import { Card, Toolbar, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { getPrefixToReportTitlesSelector } from 'util/applicationConfig';
import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';

const DynamicFormReport: React.FC<{
    onSubmit: ReportFormComponentProps['onSubmit'];
    reportDefinition: ReportFormComponentProps['reportDefinition'];
}> = ({ reportDefinition, onSubmit }) => {
    if (!reportDefinition.inputFormDefinition) {
        throw new Error('DynamicFormReport needs an inputFormDefinition. This was probably programmer error.');
    }
    const formDefinition = useMemo(() => {
        const config = JSON.parse(reportDefinition.inputFormDefinition);
        if (!config.definition) {
            throw new Error("Expected 'definition' in ReportDefinition.inputFormDefinition JSON.");
        }
        return config.definition;
    }, [reportDefinition.inputFormDefinition]);

    const initialValues = useMemo(() => ({}), []);
    const [values, setValues] = useState<any>();
    const outputOptions = useMemo(() => getOutputOptions(reportDefinition.config), [reportDefinition.config]);
    const reportNamePrefix = useSelector(getPrefixToReportTitlesSelector);

    return (
        <div>
            <Helmet>
                <title>{reportDefinition.displayName}</title>
            </Helmet>
            <Card>
                <Toolbar>
                    <Typography component="h1" variant="h5">
                        {(reportNamePrefix === null ? 'Report ' : reportNamePrefix) + reportDefinition.displayName}
                    </Typography>
                </Toolbar>
                <div style={{ marginLeft: '1.5em', marginTop: '1em' }}>
                    <Typography variant="h6" component="h2">
                        Description
                    </Typography>
                    <span>{reportDefinition.description}</span>
                    <ControlledForm values={values} setValues={setValues} initialValues={initialValues}>
                        {() => (
                            <div>
                                <Field
                                    name="formData"
                                    render={(props) => {
                                        return (
                                            <BpmFormField
                                                input={props.input}
                                                meta={props.meta}
                                                bpmFormDefinition={formDefinition}
                                                from="Flowable"
                                                renderBelowFields={(props) => {
                                                    return (
                                                        <formContext.Consumer>
                                                            {(fc) => {
                                                                return (
                                                                    <OutputOptionsArea
                                                                        outputOptions={outputOptions}
                                                                        valid={props.valid}
                                                                        longRunning={reportDefinition.longRunning}
                                                                        validateAndSubmit={(fileType: string) => () => {
                                                                            // validate already happens on blur events (e.g. just before the onClick when we click 'submit'),
                                                                            // so we don't need to trigger it ourselves.
                                                                            // We _do_ need to touch all the fields though, so the validations show up.
                                                                            props.form
                                                                                .getRegisteredFields()
                                                                                .forEach((f) => {
                                                                                    props.form.mutators.setFieldTouched(
                                                                                        f,
                                                                                        true,
                                                                                    );
                                                                                });
                                                                            if (!props.valid) {
                                                                                console.warn(
                                                                                    'The report form contains errors or missing information. Please review fields and validations.',
                                                                                );
                                                                                return;
                                                                            }
                                                                            onSubmit(fileType, fc.registeredValues);
                                                                        }}
                                                                    />
                                                                );
                                                            }}
                                                        </formContext.Consumer>
                                                    );
                                                }}
                                            />
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </ControlledForm>
                </div>
            </Card>
        </div>
    );
};
export default DynamicFormReport;
