import React from 'react';
import { Card, Divider, Typography } from '@material-ui/core';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import prismTheme from 'react-syntax-highlighter/dist/cjs/styles/prism/darcula';
import FTreeView from './FTreeView';

const FDocumentationRoot = () => {
    return (
        <Card style={{ padding: '1rem' }}>
            <h3>f</h3>
            <Divider />
            <SyntaxHighlighter
                style={prismTheme}
                PreTag="div"
                language="typescript"
                children={`f(): {\n\tdate: PlainDateBuilder,\n\tinstant: InstantBuilder,\n\ttime: TimeBuilder,\n\tlist: ListUtils\n}`}
            />
            <code style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>{`f()`}</code>
            <Divider />
            <p>
                <code>#f()</code> is a function used to easily build expressions by leveraging autocompletion to find
                what is needed from a single root expression, particularly for dealing with date and time related data.
            </p>
            <p>
                While it is recommended to leverage autocompletion in the editor, you may also use the explorer below to
                see what is possible, including some examples.
            </p>
            <div
                style={{
                    backgroundColor: 'white',
                    border: '1px solid black',
                }}
            >
                <Typography variant="h5">Explore</Typography>
                <FTreeView />
            </div>
        </Card>
    );
};
export default FDocumentationRoot;
