import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import { flowablePreprocessValuesForEval } from 'expressions/formValidation';
import { useEvalTemplateInEntityForm } from 'fieldFactory/popovers/PopoverRefInput/EvaluateStringTemplateInFormContext';
import { useEvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import { useContext, useMemo } from 'react';
import { casetivityViewContext } from 'util/casetivityViewContext';
import { getTemplateDataPaths } from 'util/getTemplateDataPaths';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import useViewConfig from 'util/hooks/useViewConfig';

const eo = {};

export const useEvaluateTemplateInFormContext = (template: string = ''): string => {
    const { currentViewContext } = useContext(casetivityViewContext);
    const taskFormContext = useContext(formContext);
    const evalTemplate = useEvaluateTemplate(template);

    const { expansionsRequired, dataPaths } = useMemo(() => getTemplateDataPaths(template), [template]);
    const entityTemplated = useEvalTemplateInEntityForm({
        templateString: template,
        context: {
            type: 'adhoc',
            expansionsRequired,
            dataPaths,
            valuesetFieldsRequired: eo,
        },
    });

    const flowableFormDefinitionContext = useContext(stagedFormDefinitionContext);
    /**
     * TODO: not handled: report form context
     * The issue is null initializing requires knowing all the fields in our form, and we don't use stagedFormDefinitionContext in Report Forms (although we could - we just don't have an editor there.)
     *
     * When we use our dynamic forms for that, then we will be all set.
     */
    const entities = useEntities();
    const valueSets = useValueSets();
    const viewConfig = useViewConfig();
    const maybeBpmStyleFormDefinitionFields = flowableFormDefinitionContext?.formDefinition?.fields ?? null;
    const bpmTemplated = useMemo(() => {
        // currently, START_FORM is dynamic forms also
        if (currentViewContext !== 'bpm') {
            return null;
        }

        const processedValues = flowablePreprocessValuesForEval(
            taskFormContext.fieldValues,
            maybeBpmStyleFormDefinitionFields ?? [],
            entities,
            valueSets,
            viewConfig,
        );
        return evalTemplate(processedValues);
    }, [
        evalTemplate,
        taskFormContext,
        entities,
        valueSets,
        maybeBpmStyleFormDefinitionFields,
        currentViewContext,
        viewConfig,
    ]);

    return useEvaluatedFormattedMessage(bpmTemplated ?? entityTemplated);
};
