import { useOverriddenViewDefaultValues } from 'expression-tester/entity-form/DefaultValueConfiguration/getDefaultValueConfigMetaData';
import React, { useMemo } from 'react';
import { useAppSelector } from 'reducers/rootReducer';
import getAdhocVariablesContextSelector from '../form/EntityFormContext/util/getVariablesContextSelector';
import { useOverriddenViewCalctValues } from 'expression-tester/entity-form/CalcValueConfiguration/getCalcValueConfigMetaData';
import { FormContextEvaluator } from 'expressions/CachingEvaluator/FormContextEvaluator';
import useViewConfig from 'util/hooks/useViewConfig';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import { sortVariablesByDependencies } from '../form/EntityFormContext';
import { merge } from 'lodash';
import { denormalizeEntitiesByPaths } from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/denormalizing/buildEntityMappingsFromPaths';

export const useDefaultValuesMeta = (viewName: string) => {
    const overriddenViewDefaultValues = useOverriddenViewDefaultValues(viewName);
    const viewDefaultValues = useAppSelector((state) => state.viewDefaultValueExpressions[viewName]);

    return overriddenViewDefaultValues ?? viewDefaultValues;
};

const useCalcValuesMeta = (viewName: string) => {
    const overriddenViewCalcValues = useOverriddenViewCalctValues(viewName);
    const viewCalcValues = useAppSelector((state) => state.viewCalcValueExpressions[viewName]);
    return overriddenViewCalcValues ?? viewCalcValues;
};

const useDefaultValues = (
    viewName: string,
    extraValues?: {},
    evaluatedAdhocSPELVariables?: Record<string, unknown>,
    id?: string,
) => {
    const defaultValuesMeta = useDefaultValuesMeta(viewName);
    const _calcValuesMeta = useCalcValuesMeta(viewName);
    const calcValuesMeta = useMemo(() => {
        return Object.fromEntries(
            Object.entries(_calcValuesMeta ?? {}).filter(([k, v]) => {
                // Only allow calc expressions in context if they don't depend on any widgets set by our default value expressions.
                return !v.dataPaths.some((dataNeededForCalc) => defaultValuesMeta[dataNeededForCalc]);
            }),
        );
    }, [_calcValuesMeta, defaultValuesMeta]);

    const adhocVariablesContextSelector = useMemo(getAdhocVariablesContextSelector, []);

    const viewConfig = useViewConfig();
    const entities = useEntities();

    const expansionsRequired = Object.values({
        ...defaultValuesMeta,
        ...calcValuesMeta,
    })
        .flatMap((c) => c.expansionsRequired)
        .filter((f) => !f.startsWith('$') && !f.startsWith('_'));
    const denormalizedInitialValues = denormalizeEntitiesByPaths(
        entities,
        expansionsRequired,
        viewConfig,
        viewConfig.views[viewName].entity,
        id,
    );
    const fc = useMemo(() => {
        const fieldsUsedInExpressions = Object.values({
            ...defaultValuesMeta,
            ...calcValuesMeta,
        }).flatMap((c) => c.dataPaths);

        const { variablesInExecutionOrder, remainingVariables } = sortVariablesByDependencies({
            ...defaultValuesMeta,
            ...calcValuesMeta,
        });
        if (remainingVariables.length > 0) {
            console.log('Variables containting an execution cycle: ', remainingVariables);
            alert(
                "A cycle was detected when calculating default values. Variables which can't be sorted into a proper execution order have been logged to the console.",
            );
        }
        const variables = variablesInExecutionOrder.map((group) =>
            group.reduce((prev, curr) => {
                prev[curr.fieldName] = curr.expression;
                return prev;
            }, {} as { [varName: string]: string }),
        );
        const fc = new FormContextEvaluator({
            basedOnEntityOptions: {
                basedOnEntity: viewConfig.views[viewName].entity,
                fieldsUsedInExpressions,
            },
            evaluationFactors: {
                fieldWidgets: Object.keys({
                    ...defaultValuesMeta,
                    ...calcValuesMeta,
                }).reduce((prev, curr) => {
                    prev[curr] = [curr];
                    return prev;
                }, {}),
                valueset1Fields: Object.assign(
                    {},
                    ...Object.values({
                        ...defaultValuesMeta,
                        ...calcValuesMeta,
                    }).map((c) => c.valuesetFieldsRequired),
                ),
                variables,
            },
            options: {},
            viewConfig,
        });
        return fc;
    }, [viewConfig, defaultValuesMeta, calcValuesMeta, viewName]);

    const adhocSpelVariables = adhocVariablesContextSelector(evaluatedAdhocSPELVariables);
    const valueSets = useValueSets();
    const result = useMemo(
        () =>
            fc.evaluate(
                merge({}, extraValues ?? {}, denormalizedInitialValues),
                valueSets,
                {},
                entities,
                adhocSpelVariables,
            ).variables,
        [valueSets, fc, entities, adhocSpelVariables, extraValues, denormalizedInitialValues],
    );

    return result;
};

export const WithDefaultValues: React.FC<{
    viewName: string;
    children: (props: { defaultValues: {} }) => JSX.Element;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    extraValues?: {};
    id?: string;
}> = ({ viewName, children, extraValues, evaluatedAdhocSPELVariables, id }) => {
    const defaultValues = useDefaultValues(viewName, extraValues, evaluatedAdhocSPELVariables, id);
    return children({
        defaultValues,
    });
};

export default useDefaultValues;
