import isEqual from 'lodash/isEqual';
import { Ast } from 'ts-spel/lib-esm/lib/Ast';

interface Nullable {
    maybeNull: boolean;
}
interface StringType extends Nullable {
    _type: 'string';
    staticValue?: string;
}

interface ArrayType extends Nullable {
    _type: 'array';
    of: StaticType;
}

export interface MapType extends Nullable {
    __className?: string;
    _type: 'map';
    staticValue?: {
        [key: string]: StaticType;
    };
    parameters?: {
        [key: string]: StaticType;
    };
}

interface EntityType extends Nullable {
    // the problem with representing with maps is it forces us to eagerly stuff data in,
    // which is difficult with cycles + arbitrary depth (but not impossible - see 'StaticTypes')
    // when we want to do our lookups lazily
    _type: 'entity';
    entityType: string;
}

interface NumberType {
    _type: 'number';
}

interface BooleanType {
    _type: 'boolean';
}
interface NullType {
    _type: 'Null';
}

interface UnknownType {
    _type: 'unknown';
}

export type StaticType =
    | StringType
    | ArrayType
    | MapType
    | EntityType
    | NumberType
    | BooleanType
    | NullType
    | UnknownType;

export const Types = {
    string: (maybeNull: boolean, staticValue?: string): StringType => ({ _type: 'string', maybeNull, staticValue }),
    array: (maybeNull: boolean, of: StaticType = { _type: 'unknown' }): ArrayType => ({
        _type: 'array',
        maybeNull,
        of,
    }),
    map: (
        maybeNull: boolean,
        staticValue?: { [key: string]: StaticType },
        parameters?: { [key: string]: StaticType },
    ): MapType => ({
        _type: 'map',
        maybeNull,
        staticValue,
        parameters,
    }),
    number: { _type: 'number' } as NumberType,
    boolean: { _type: 'boolean' } as BooleanType,
    entity: (maybeNull: boolean, entityType: string): EntityType => ({ _type: 'entity', entityType, maybeNull }),
    unknown: { _type: 'unknown' } as UnknownType,
    Null: { _type: 'Null' } as NullType,
} as const;

const isNullable = <T extends StaticType>(type: T): type is T extends Nullable ? T : never =>
    type['maybeNull'] !== 'undefined';

const resolveTypes = (...types: StaticType[]): StaticType => {
    if (types.some((t) => t._type === 'unknown')) {
        return Types.unknown;
    }
    const nonNull = types.filter((t) => t._type !== 'Null');
    const resolvedType = nonNull.reduce((prev, curr, i) => {
        const isFirst = i === 0;
        if (isFirst) {
            return curr;
        }
        if (prev._type !== curr._type) {
            return Types.unknown;
        }
        let res = {
            _type: prev._type,
        };
        if (prev['staticValue'] && isEqual(prev['staticValue'], curr['staticValue'])) {
            res['staticValue'] = prev['staticValue'];
        }
        if (prev['maybeNull'] || curr['maybeNull']) {
            res['maybeNull'] = true;
        }
        if (prev['of'] && isEqual(prev['of'], curr['of'])) {
            res['of'] = prev['of'];
        }
        return res as StaticType;
    }, Types.unknown);

    if (isNullable(resolvedType)) {
        return {
            ...resolvedType,
            maybeNull: nonNull.some((t) => t['maybeNull']),
        };
    }
    return resolvedType;
};

export const getEvaluateStatically = (
    resolvePath: (path?: string, topContext?: StaticType) => StaticType,
    options: {
        disableBoolOpChecks?: boolean;
    } = {},
) => {
    const { disableBoolOpChecks } = options;
    const rootContext: StaticType = resolvePath();
    const stack: StaticType[] = [rootContext];
    const getHead = (): StaticType => {
        if (stack.length > 0) {
            return stack[stack.length - 1];
        }
        throw new Error('Stack is empty');
    };

    const staticEvaluate = (ast: Ast): StaticType => {
        if (!ast) {
            // we parsed partially - get the root
            // (it could also be a union of the root and current context.)

            // however, we would prefer that to be *explicit*
            // so we know unambiguously the paths to all our data.
            // so let's force users to configure paths explicitly
            // and *not* do the below:
            // return resolvePath(undefined, getHead());
            return resolvePath(undefined);
        }
        switch (ast.type) {
            case 'BooleanLiteral':
                return Types.boolean;
            case 'CompoundExpression': {
                const res = ast.expressionComponents.reduce((_, curr) => {
                    const res = staticEvaluate(curr);
                    stack.push(res);
                    return res;
                }, rootContext);
                ast.expressionComponents.forEach(() => {
                    stack.pop();
                });
                return res;
            }
            case 'Elvis': {
                const left = staticEvaluate(ast.expression);
                const right = staticEvaluate(ast.ifFalse);
                if (!ast._isClosed) {
                    return right;
                }
                return resolveTypes(left, right);
            }
            case 'FunctionReference': {
                if (ast.__unclosed) {
                    const lastNode = ast.args[ast.args.length - 1];
                    if (lastNode) {
                        return staticEvaluate(lastNode);
                    }
                }
                const res = resolvePath('#' + ast.functionName + '()', getHead());
                return res;
            }
            case 'Indexer': {
                if (ast.__unclosed) {
                    return staticEvaluate(ast.index);
                }
                const head = getHead();
                if (head === null) {
                    return Types.unknown;
                }
                const index = staticEvaluate(ast.index);
                if (index._type === 'number') {
                    if (head._type === 'string') {
                        return Types.string(true);
                    }
                    if (head._type === 'array') {
                        return head.of;
                    }
                    return Types.unknown;
                }
                if (index._type === 'string') {
                    return resolvePath(index.staticValue, head);
                }
                return Types.unknown;
            }
            case 'InlineList': {
                if (ast.__unclosed) {
                    const lastEl = ast.elements[ast.elements.length - 1];
                    return staticEvaluate(lastEl);
                }
                const items = ast.elements.map(staticEvaluate);
                const resolvedItemsType = resolveTypes(...items);

                return Types.array(false, resolvedItemsType);
            }
            case 'InlineMap': {
                if (ast.__unclosed) {
                    if (typeof ast.elements[''] !== 'undefined') {
                        // this signifies we ended on a comma, so expect a property name
                        return Types.unknown;
                    }
                    const values = Object.values(ast.elements);
                    const lastValue = values[values.length - 1];
                    if (!lastValue) {
                        return resolvePath(undefined);
                    }
                    return staticEvaluate(lastValue);
                }
                const staticValue = Object.fromEntries(
                    Object.entries(ast.elements).map(([k, ast]) => [k, staticEvaluate(ast)]),
                );
                return Types.map(false, staticValue);
            }
            case 'MethodReference': {
                if (ast.__unclosed) {
                    const lastNode = ast.args[ast.args.length - 1];
                    if (lastNode) {
                        return staticEvaluate(lastNode);
                    }
                }

                return resolvePath(ast.methodName + '()', getHead());
            }
            case 'Negative': {
                const operand = staticEvaluate(ast.value);
                if (operand._type === 'number') {
                    return operand;
                }
                // throw ?
                // throw new Error(
                //   "unary (-) operator applied to " + JSON.stringify(operand)
                // );
                return Types.unknown;
            }
            case 'NullLiteral': {
                return Types.Null;
            }
            case 'NumberLiteral': {
                return Types.number;
            }
            case 'OpAnd': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);

                if (!disableBoolOpChecks) {
                    if (left._type !== 'boolean') {
                        // throw new Error(JSON.stringify(left) + " is not a boolean");
                    }
                    if (typeof right !== 'boolean') {
                        // throw new Error(JSON.stringify(right) + " is not a boolean");
                    }
                }
                if (!ast._isClosed) {
                    return right;
                }

                return disableBoolOpChecks ? resolveTypes(left, right) : Types.boolean;
            }
            case 'OpDivide': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);
                if (left._type !== 'number' || right._type !== 'number') {
                    // throw?
                }
                if (!ast._isClosed) {
                    return right;
                }
                return Types.number;
            }
            case 'OpEQ':
            case 'OpGE':
            case 'OpGT':
            case 'OpLE':
            case 'OpLT': {
                if (!ast._isClosed) {
                    return staticEvaluate(ast.right);
                }
                return Types.boolean;
            }
            case 'OpMatches': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);
                if (!ast._isClosed) {
                    return right;
                }
                // check for string types
                return Types.boolean;
            }
            case 'OpBetween': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);
                if (!ast._isClosed) {
                    return right;
                }
                // if (!Array.isArray(right) || right.length !== 2) {
                //   throw new Error(
                //     "Right operand for the between operator has to be a two-element list"
                //   );
                // }
                // const [firstValue, secondValue] = right;

                return Types.boolean;
            }
            case 'OpMinus':
            case 'OpModulus':
            case 'OpMultiply': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);
                if (!ast._isClosed) {
                    return right;
                }
                // check for number types
                return Types.number;
            }
            case 'OpNE': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);
                if (!ast._isClosed) {
                    return right;
                }
                return Types.boolean;
            }
            case 'OpNot': {
                const exp = staticEvaluate(ast.expression);
                return Types.boolean;
            }
            case 'OpOr': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);

                if (!disableBoolOpChecks) {
                    if (left._type !== 'boolean') {
                        throw new Error(JSON.stringify(left) + ' is not a boolean');
                    }
                    if (right._type !== 'boolean') {
                        throw new Error(JSON.stringify(right) + ' is not a boolean');
                    }
                }
                if (!ast._isClosed) {
                    return right;
                }
                return Types.boolean;
            }
            case 'OpPlus': {
                const left = staticEvaluate(ast.left);
                const right = staticEvaluate(ast.right);
                if (!ast._isClosed) {
                    return right;
                }
                //  check for string or number
                if (left._type === 'string' || right._type === 'string') {
                    return Types.string(false);
                }

                return left;
            }
            case 'OpPower': {
                const left = staticEvaluate(ast.base);
                const right = staticEvaluate(ast.expression);
                if (!ast._isClosed) {
                    return right;
                }
                // check for number types
                return Types.number;
            }
            case 'Projection': {
                const head = getHead();
                const { nullSafeNavigation, expression } = ast;
                if (head._type === 'array') {
                    stack.push(head.of);
                    const result = staticEvaluate(expression);
                    if (ast.__unclosed) {
                        return result;
                    }
                    stack.pop();
                    return Types.array(head.maybeNull, result);
                }
                if (head._type === 'map') {
                    const mapType = resolveTypes(...Object.values(head.staticValue ?? {}));
                    stack.push(mapType);
                    const result = staticEvaluate(expression);
                    if (ast.__unclosed) {
                        return result;
                    }
                    stack.pop();
                    return Types.map(head.maybeNull, {
                        '*': result,
                    });
                }
                // Probably need to throw here
                return Types.unknown;
            }
            case 'PropertyReference': {
                /**
                 * TODO!!!
                 * set flags when doing a compound, so we know if this is a standalone property reference,
                 * or in a compound
                 *
                 * Then, we will pass resolvePath the current 'entity context' (just a StaticType), or not,
                 * depending on if it's the first/standalone (no context), or a later part of a compound, where we need to pass
                 * the StaticType we are currently on.
                 *
                 * then the callback will do the viewconfig lookup to see what the type resolves to.
                 */

                const { nullSafeNavigation, propertyName } = ast;
                const resolved = resolvePath(propertyName, getHead());
                return resolved;
            }
            case 'SelectionAll': {
                const head = getHead();
                if (ast.__unclosed) {
                    if (head._type === 'array') {
                        stack.push(head.of);
                    }
                    if (head._type === 'map') {
                        const mapType = resolveTypes(...Object.values(head.staticValue ?? {}));
                        stack.push(mapType);
                    }
                    return staticEvaluate(ast.expression);
                }
                const { nullSafeNavigation, expression } = ast;

                if (head._type === 'Null' && nullSafeNavigation) {
                    return head;
                }
                if (head._type === 'array') {
                    return head;
                }

                if (head._type === 'map') {
                    const resolvedType = resolveTypes(...Object.values(head.staticValue ?? {}));
                    return Types.map(head.maybeNull, {
                        '*': resolvedType,
                    });
                }
                throw new Error('Cannot run selection expression on non-collection ' + JSON.stringify(head));
            }
            case 'SelectionLast':
            case 'SelectionFirst': {
                const head = getHead();
                if (ast.__unclosed) {
                    if (head._type === 'array') {
                        stack.push(head.of);
                    }
                    if (head._type === 'map') {
                        const mapType = resolveTypes(...Object.values(head.staticValue ?? {}));
                        stack.push(mapType);
                    }
                    return staticEvaluate(ast.expression);
                }

                const { nullSafeNavigation, expression } = ast;

                if (head._type === 'Null' && nullSafeNavigation) {
                    return head;
                }

                if (head._type === 'array') {
                    return head.of;
                }
                if (head._type === 'map') {
                    return resolveTypes(...Object.values(head.staticValue ?? {}));
                }
                throw new Error('Cannot run selection expression on non-array ' + JSON.stringify(head));
            }

            case 'StringLiteral': {
                return Types.string(false, ast.value);
            }
            case 'Ternary': {
                const { expression, ifTrue, ifFalse } = ast;
                const conditionResult = staticEvaluate(expression);
                // bool check on conditionResult
                if (!ast._isClosed) {
                    if (ifTrue) {
                        if (ifFalse) {
                            return staticEvaluate(ifFalse);
                        }
                        return staticEvaluate(ifTrue);
                    }
                    // throw error - unexpected
                }

                // disableBoolOpChecks and throw if conditionResult is not boolean
                return resolveTypes(staticEvaluate(ifTrue), staticEvaluate(ifFalse));
            }
            case 'VariableReference': {
                if (ast.variableName === 'this') {
                    return resolvePath(undefined, getHead());
                }
                if (ast.variableName === 'root') {
                    return rootContext;
                }
                const res = resolvePath('#' + ast.variableName, getHead());
                return res;
            }
        }
    };
    return staticEvaluate;
};
