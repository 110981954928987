import * as t from 'io-ts';

const _entityViewConfig = t.exact(
    t.partial({
        entityActions: t.array(
            t.union([
                t.intersection([
                    t.partial({
                        displayRule: t.string,
                        redirectOnSuccess: t.string,
                    }),
                    t.type({
                        label: t.string,
                        key: t.string,
                        action: t.any,
                    }),
                ]),
                t.intersection([
                    t.partial({
                        displayRule: t.string,
                    }),
                    t.type({
                        label: t.string,
                        key: t.string,
                        url: t.string,
                    }),
                ]),
                t.intersection([
                    t.partial({
                        displayRule: t.string,
                    }),
                    t.type({
                        label: t.string,
                        key: t.string,
                        processDefinitionKey: t.string,
                    }),
                ]),
            ]),
        ),
        visibleField: t.array(t.object),
        editableField: t.array(t.object),
        hideDeleteButton: t.string,
        hideEditButton: t.string,
        conceptIdsForFields: t.object,
        hideCreateButton: t.union([t.string, t.boolean]),
        overrideTitle: t.string,
        showExportForNonSuper: t.union([t.string, t.boolean]),
        validations: t.array(
            t.type({
                message: t.string,
                expression: t.string,
            }),
        ),
        hidePopupNavigationArrow: t.union([t.string, t.boolean]),
        hideBackTo: t.union([t.string, t.boolean]),
        defaultValueExpressions: t.record(t.string, t.string),
        conditionalDefaultValueExpressions: t.record(t.string, t.string),
        calcValueExpressions: t.record(t.string, t.string),
        preventCreateUntilSearch: t.boolean,
    }),
);

export interface EntityViewConfig extends t.TypeOf<typeof _entityViewConfig> {}
export const entityViewConfig = t.clean<EntityViewConfig, EntityViewConfig>(_entityViewConfig);
