import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import getFields from 'components/generics/genericList/getFields';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../../../translation/types/DataSource';
import React, { useContext, useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { Table } from '@material-ui/core';
import { renderTableHead } from 'components/generics/genericList/renderList';
import getRenderInlineList from 'fieldFactory/input/components/InlineMany/renderInlineManyList';

const EmptyTable = (props: {
    viewName: string;
    parentFieldInChild: string;
    inlineMany?: {
        parentId: string;
        source: string;
        parentEntityType: string;
        useCreateView?: boolean;
        createViewName?: string;
        renderNoData?: () => JSX.Element;
        evaluatedAdhocSPELVariables?: Record<string, unknown>;
    };
    disabled?: boolean;
}) => {
    const viewConfig = useViewConfig();
    const entity = viewConfig.views[props.viewName].entity;
    const fieldFactory: any = useContext(FieldFactoryContext);
    const fields = useMemo(() => {
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
            },
        };
        return fieldFactory(config)({ record: {}, resource: entity, basePath: '/' + entity, isForSearch: true })(
            getFields(viewConfig, props.viewName, true, props.parentFieldInChild),
        );
    }, [fieldFactory, entity, props.parentFieldInChild, props.viewName, viewConfig]);

    if (props.inlineMany) {
        return getRenderInlineList({
            viewName: props.viewName,
            entityType: props.inlineMany.parentEntityType,
            id: props.inlineMany.parentId,
            source: props.inlineMany.source,
            useCreateView: props.inlineMany.useCreateView,
            createViewName: props.inlineMany.createViewName,
        })({
            resource: entity,
            referencedByField: props.parentFieldInChild,
            data: {},
            renderNoData: props.inlineMany.renderNoData,
            disabled: props.disabled,
            evaluatedAdhocSPELVariables: props.inlineMany.evaluatedAdhocSPELVariables,
        });
    }
    return (
        <Table aria-hidden={true}>
            {renderTableHead(
                {
                    fields,
                    ids: [],
                    data: {},
                    resource: entity,
                    isLoading: false,
                    setSort: null,
                    hasSomeVisibleSearchFields: false,
                },
                undefined,
                undefined,
                0, // extra columns to append.
                'DISABLED',
            )}
        </Table>
    );
};
export default EmptyTable;
