import ViewConfig from 'reducers/ViewConfigType';

// Ordering matters!
// Let the first item be the 'default' item.

// This 'first item' is used when generating default views.
export const validDataTypeWidgetCombinations: {
    [dt in ViewConfig['entities'][0]['fields'][0]['dataType']]: ViewConfig['views'][0]['fields'][0]['widgetType'][];
} = {
    STRING: [
        'TEXTBOX',
        'TEXTAREA',
        'DMSDOC',
        'HTML_WYSIWYG',
        'ENTITY_TYPEAHEAD',
        'VALUESET_SUGGEST',
        'VALIDATION_EXP_EDITOR',
        'JSONSCHEMA_FORM',
        'JSONSCHEMA_FORM_BUILDER',
        'BPM_FORM',
        'BPM_FORM_BUILDER',
    ],
    INTEGER: ['INTEGER', 'TEXTBOX'],
    FLOAT: ['FLOAT', 'TEXTBOX', 'CURRENCY', 'PERCENT'],
    DOUBLE: ['FLOAT', 'TEXTBOX', 'CURRENCY', 'PERCENT'],
    LONG: ['FLOAT', 'TEXTBOX'],
    BIGDECIMAL: ['FLOAT', 'TEXTBOX', 'CURRENCY'],
    BOOLEAN: ['CHECKBOX', 'TEXTBOX', 'TOGGLE', 'NULLABLE_BOOLEAN'],
    DATE: ['CALENDAR'],
    INSTANT: ['CALENDARTIME', 'CALENDAR'],
    BLOB: ['FILEUPLOAD', 'IMAGE'],
    ANYBLOB: ['FILEUPLOAD', 'IMAGE'],
    IMAGEBLOB: ['FILEUPLOAD', 'IMAGE'],
    TEXTBLOB: [
        'TEXTAREA',
        'TEXTBOX',
        'FILEUPLOAD',
        'HTML_WYSIWYG',
        'VALIDATION_EXP_EDITOR',
        'JSONSCHEMA_FORM',
        'JSONSCHEMA_FORM_BUILDER',
        'BPM_FORM',
        'BPM_FORM_BUILDER',
    ],
    REFONE: ['SELECT', 'FORCE_ONEOF', 'ENTITY_TYPEAHEAD', 'COMPONENT'],
    REFONEJOIN: ['SELECT', 'FORCE_ONEOF', 'ENTITY_TYPEAHEAD'],
    REFMANY: ['MULTISELECT', 'FORCE_IDS_LIST', 'MULTI_CARD', 'INLINE_MANY'],
    REFMANYJOIN: ['MULTISELECT', 'FORCE_IDS_LIST', 'MULTI_CARD'],
    VALUESET: ['SELECT', 'MULTISELECT', 'CHECKBOX', 'ADDRESS', 'ADDRESS_2'],
    VALUESETMANY: ['MULTISELECT', 'CHECKBOX'],
    REFMANYMANY: ['MULTISELECT', 'MULTIPLE_ENTITY_TYPEAHEAD', 'ENTITY_CHIP', 'MULTI_CARD', 'INLINE_MANY'],
};
