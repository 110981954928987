import { createSlice } from '@reduxjs/toolkit';
import { ViewCalcValueExpressionMetaData } from './ViewCalcValueExpressions';

export const viewCalcValueExpressions = createSlice({
    name: 'ViewCalcValueExpressions',
    initialState: {} as ViewCalcValueExpressionMetaData,
    reducers: {
        set: (
            state,
            action: {
                payload: ViewCalcValueExpressionMetaData;
            },
        ) => {
            return action.payload;
        },
    },
});

export const { set } = viewCalcValueExpressions.actions;

export default viewCalcValueExpressions.reducer;
