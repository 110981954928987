import { useMemo } from 'react';
import { RootState } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import getProvidedMessages from './getProvidedMessages';
import defaultMessages from './defaultMessages';
import { useSelector } from 'react-redux';
import { getLanguagesSelector } from 'util/applicationConfig';

export const messagesSelector = createSelector(
    (state: RootState) => state.basicInfo,
    (basicInfo) => getProvidedMessages(basicInfo.messages ?? ({} as any), defaultMessages),
);
const useMessages = () => {
    const messages = useSelector(messagesSelector);
    return messages;
};
export const useLocale = () => {
    return useSelector((state: RootState) => state.locale ?? ('en' as const));
};
export const useAvailableLocales = (): string[] => {
    const messages = useMessages();
    const locales = useMemo(() => Object.keys(messages), [messages]);
    const overriddenLocales = useSelector(getLanguagesSelector);
    return overriddenLocales ?? locales;
};

export const useCurrentMessages = () => {
    const messages = useMessages();
    const locale = useLocale();
    return messages[locale];
};

export default useMessages;
