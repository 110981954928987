import React, { useContext, useMemo, useCallback, useState, useReducer } from 'react';
import { RootState } from 'reducers/rootReducer';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import produce from 'immer';
import MovableGrid, { FieldElem } from './MovableGrid2';
import { View, FieldViewField, ViewField } from 'reducers/ViewConfigType';
import Clear from '@material-ui/icons/Clear';
import fromentries from 'fromentries';
import getFields, { getGetGenerateFields } from 'components/generics/genericEdit/getFields';
import { LayoutState, LayoutStateItem } from 'layout-editor/demo/layoutReducer';
import { isFieldViewField, isRefManyField } from 'components/generics/utils/viewConfigUtils';
import AddField from 'layout-editor/add-field/components/AddViewField';
import TabEditor from './tabs/TabEditor';
import { IconButton, Divider } from '@material-ui/core';
import Popup from 'components/Popup';
import deepEql from 'deep-eql';
import { useGetData } from 'components/generics/genericEdit/useGetData';
import Title from 'components/generics/title/Title';
import useViewConfig from 'util/hooks/useViewConfig';
import labelField from 'components/generics/utils/labelField';
import { Mode } from 'fieldFactory/Mode';
import fromEntries from 'util/fromentries';
import { orderFieldObjectRowCol } from 'layout-editor/UniversalViewWizard/cleanFieldCoordinates';
import { getFieldKeyGetter } from 'layout-editor/util/getFieldKeyGetter';
import { MoveFieldToOtherGridDialog } from 'dash-editor/MoveFieldToOtherGrid';
import { Reply } from '@material-ui/icons';

export const mergeLayoutIntoView = (view: View, layout: LayoutState, tab?: string) =>
    produce(view, (draftState) => {
        const getFieldKey = getFieldKeyGetter();
        const fields = orderFieldObjectRowCol(
            fromentries(
                layout.map(({ 'data-originaldefinition': originalDefinition, x, y, w, content }) => {
                    const viewField: ViewField = JSON.parse(originalDefinition);
                    const id = getFieldKey(viewField);
                    return [
                        id,
                        {
                            ...viewField,
                            row: y,
                            column: x,
                            span: w,
                            key: content?.['key'],
                        } as ViewField,
                    ];
                }),
            ),
        );
        if (tab) {
            draftState.tabs[tab].fields = fields;
        } else {
            draftState.fields = fields;
        }
        return draftState;
    });

interface EditableViewFormLayoutProps {
    mode: 'EDIT' | 'SHOW';
    id?: string;
    isNew?: boolean;
    entityType: string;
    view: View;
    hasTabs?: boolean;
    includeSearchType?: boolean;
    onViewChange: (args: { view: View }) => void;
    addFieldLabel?: React.ReactNode;
    isCreate?: boolean;
}

interface FieldGridProps extends Pick<EditableViewFormLayoutProps, 'view' | 'onViewChange' | 'isCreate'> {
    fields: React.ReactElement[];
    tab?: string;
    recalculateHeightKey?: string;
    includeSearchType?: boolean;
    createField: (fieldDefinition: ViewField) => FieldElem;
    getAddToBottomFieldWidth?: (element: FieldElem) => 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    addFieldLabel?: React.ReactNode;
    mode: 'EDIT' | 'SHOW';
    renderMoveButton?: (item: LayoutStateItem) => JSX.Element;
    openMoveFieldDialog?: boolean;
    gridChangeKey?: number;
}
const FieldGrid: React.FC<FieldGridProps> = React.memo(
    ({
        includeSearchType,
        view,
        onViewChange,
        fields,
        tab,
        createField,
        recalculateHeightKey,
        getAddToBottomFieldWidth,
        addFieldLabel = 'Add Field',
        mode,
        isCreate,
        renderMoveButton,
        openMoveFieldDialog,
        gridChangeKey,
    }) => {
        const handleLayoutChange = useCallback(
            (args: { layout: LayoutState }) => {
                const newView = mergeLayoutIntoView(view, args.layout, tab);
                if (tab) {
                    if (!deepEql(newView.tabs[tab].fields, view.tabs[tab].fields)) {
                        onViewChange({ view: newView });
                    }
                } else {
                    if (!deepEql(newView.fields, view.fields)) {
                        onViewChange({ view: newView });
                    }
                }
            },
            [view, onViewChange, tab],
        );
        const [refreshKey, setRefreshKey] = useState(1);
        const refresh = useCallback(() => {
            setRefreshKey(refreshKey + 1);
        }, [refreshKey, setRefreshKey]);

        return (
            <div style={{ paddingTop: 5 }}>
                {fields && (
                    <MovableGrid
                        gridChangeKey={gridChangeKey}
                        renderMoveButton={renderMoveButton}
                        openMoveFieldDialog={openMoveFieldDialog}
                        // No overflow area if search
                        overlapInsert
                        MAX_COLS={includeSearchType ? 12 : 24}
                        key={refreshKey}
                        refresh={refresh}
                        recalculateHeightKey={recalculateHeightKey}
                        createField={createField}
                        renderFieldAdder={({ addField, renderToggler, initialValues }) => (
                            <Popup
                                renderToggler={renderToggler}
                                renderDialogContent={({ closeDialog }) => {
                                    return (
                                        <div
                                            style={{
                                                // width: '512px',
                                                padding: '1em',
                                                border: '1px solid black',
                                            }}
                                        >
                                            <b>Add Field</b>
                                            <IconButton style={{ float: 'right' }} size="small" onClick={closeDialog}>
                                                <Clear />
                                            </IconButton>
                                            <AddField
                                                isCreate={isCreate}
                                                mode={mode}
                                                includeSearchType={includeSearchType}
                                                initialValues={
                                                    initialValues && {
                                                        ...initialValues,
                                                        // thing with label is that it defaults back to backing field label.
                                                        fieldPath: (initialValues as FieldViewField).field,
                                                    }
                                                }
                                                rootEntity={view.entity}
                                                onAdd={(fd) => {
                                                    addField(fd);
                                                    closeDialog();
                                                }}
                                            />
                                        </div>
                                    );
                                }}
                            />
                        )}
                        fields={fields}
                        onLayoutChange={handleLayoutChange}
                        columnStartsAt={1}
                        getAddToBottomFieldWidth={getAddToBottomFieldWidth}
                        addFieldLabel={addFieldLabel}
                    />
                )}
            </div>
        );
    },
);

const emptyArr = [];
const EditableViewFormLayout: React.SFC<EditableViewFormLayoutProps> = React.memo(
    ({
        hasTabs = true,
        view,
        onViewChange,
        id,
        entityType,
        mode = 'EDIT',
        includeSearchType,
        addFieldLabel,
        isCreate,
    }) => {
        const fieldFactory = useContext(FieldFactoryContext);
        const viewConfig = useViewConfig();
        const getData = useGetData();
        const { name: viewName } = view;
        const [fieldToMove, setFieldToMove] = useState<{ gridKey: string; field: LayoutStateItem } | undefined>(
            undefined,
        );
        const [openMoveFieldDialog, setOpenMoveFieldDialog] = useState(false);

        const handleFieldMove = useCallback((gridKey: string, field: LayoutStateItem) => {
            setFieldToMove({ field, gridKey });
            setOpenMoveFieldDialog(true);
        }, []);

        const createField = React.useCallback(
            (viewField: ViewField) => {
                const defaultRecord = {
                    id,
                    entityType,
                };
                const field = getGetGenerateFields(
                    fieldFactory,
                    {
                        isPopover: false,
                        referenceFieldsShouldFetchInitialData: true,
                        resource: entityType,
                        record: defaultRecord,
                        basePath: `/${entityType}`,
                        nonEditableAddressWidget: true,
                        match: {
                            params: {
                                id,
                                basePath: `/${entityType}`,
                            },
                        },
                        validate: false,
                    },
                    (state: RootState) => {
                        return {
                            record:
                                getData(state, {
                                    overrideViewConfig: viewConfig,
                                    viewName,
                                    id,
                                }) || defaultRecord,
                        };
                    },
                    mode === 'SHOW' ? Mode.DISPLAY : Mode.INPUT,
                )(false)([[(viewField as FieldViewField).field || Math.random().toString(), viewField]])[0];

                if (mode === 'SHOW') {
                    return labelField(
                        field,
                        defaultRecord,
                        entityType,
                        `/${entityType}`,
                        field.props.source || null,
                        field.props.fieldInstanceIdentifier,
                    ) as JSX.Element;
                }
                return field;
            },
            [id, entityType, fieldFactory, getData, viewName, viewConfig, mode],
        );
        const { baseFields, fieldsByTab } = useMemo(() => {
            const defaultRecord = {
                id,
                entityType,
            };
            const { baseFields, fieldsByTab } = getFields(
                fieldFactory,
                {
                    isForCreate: !id,
                    viewName,
                    viewConfig,
                    isPopover: false,
                    referenceFieldsShouldFetchInitialData: true, // false,
                    resource: entityType,
                    record: defaultRecord,
                    nonEditableAddressWidget: true,
                    basePath: `/${entityType}`,
                    match: {
                        params: {
                            id,
                            basePath: `/${entityType}`,
                        },
                    },
                    validate: false,
                },
                (state: RootState) => {
                    return {
                        record:
                            getData(state, {
                                overrideViewConfig: viewConfig,
                                viewName,
                                id,
                            }) || defaultRecord,
                    };
                },
                mode === 'SHOW' ? Mode.DISPLAY : Mode.INPUT,
            );
            if (mode !== 'SHOW') {
                return { baseFields, fieldsByTab };
            }
            return {
                baseFields: baseFields.map(
                    (f) =>
                        f &&
                        (labelField(
                            f,
                            defaultRecord,
                            entityType,
                            `/${entityType}`,
                            f.props.source || null,
                            f.props.fieldInstanceIdentifier,
                        ) as JSX.Element),
                ),
                fieldsByTab: fromEntries(
                    Object.entries(fieldsByTab).map(([k, a]) => [
                        k,
                        (a as any).map(
                            (f) =>
                                labelField(
                                    f,
                                    defaultRecord,
                                    entityType,
                                    `/${entityType}`,
                                    f.props.source || null,
                                    f.props.fieldInstanceIdentifier,
                                ) as JSX.Element,
                        ),
                    ]),
                ),
            };
        }, [fieldFactory, viewName, viewConfig, id, entityType, getData, mode]);
        const getAddToBottomFieldWidth = useCallback(
            (elem: FieldElem) => {
                const definition = elem.props['data-originaldefinition'];
                if (!definition) {
                    return undefined;
                }
                try {
                    const viewDefinition: ViewField = JSON.parse(definition);
                    if (!isFieldViewField(viewDefinition)) {
                        return undefined;
                    }
                    if (viewDefinition.widgetType === 'TEXTAREA') {
                        return 12 as const;
                    }
                    const isRefMany = isRefManyField(
                        viewConfig,
                        viewDefinition.entity,
                        viewDefinition.field,
                        'POP_LAST',
                    );
                    if (
                        (viewDefinition.widgetType === 'MULTISELECT' || viewDefinition.widgetType === 'MULTI_CARD') &&
                        isRefMany
                    ) {
                        return 12 as const;
                    }
                    if (viewDefinition.widgetType === 'BPM_FORM_BUILDER') {
                        return 12 as const;
                    }
                } catch {
                    return undefined;
                }
            },
            [viewConfig],
        );

        const initialTabRefreshCounters = useMemo(() => {
            return Object.keys(view.tabs || {}).reduce((tokens, tabKey) => {
                tokens[tabKey] = Math.random();
                return tokens;
            }, {});
        }, [view.tabs]);
        const [tabRefreshDict, setTabRefreshDict] = useState(initialTabRefreshCounters);
        const refreshTab = (tabKey) => {
            setTabRefreshDict((prev) => ({
                ...prev,
                [tabKey]: Math.random(), // Update just this tab's token to force a re-render
            }));
        };

        const [gridChangeKey, incrementGridChangeKey] = useReducer((state) => state + 1, 1);
        return (
            <div style={{ padding: 10, paddingLeft: 20 }}>
                <Title component="h1" resource={view.entity} id={id} />
                {baseFields && (
                    <FieldGrid
                        isCreate={isCreate}
                        addFieldLabel={addFieldLabel}
                        getAddToBottomFieldWidth={getAddToBottomFieldWidth}
                        includeSearchType={includeSearchType}
                        createField={createField}
                        fields={baseFields}
                        view={view}
                        onViewChange={onViewChange}
                        mode={mode}
                        openMoveFieldDialog={openMoveFieldDialog}
                        gridChangeKey={gridChangeKey}
                        renderMoveButton={(item) => {
                            return (
                                view?.tabs && (
                                    <IconButton
                                        style={{ zIndex: 1000, transform: 'scaleX(-1)' }}
                                        size="small"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleFieldMove(view.name, item);
                                        }}
                                    >
                                        <Reply />
                                    </IconButton>
                                )
                            );
                        }}
                    />
                )}
                {view?.tabs && (
                    <MoveFieldToOtherGridDialog
                        view={view}
                        fieldToMove={fieldToMove}
                        onViewChange={onViewChange}
                        open={openMoveFieldDialog}
                        setOpen={setOpenMoveFieldDialog}
                        refreshTab={refreshTab}
                        incrementGridChangeKey={incrementGridChangeKey}
                    />
                )}
                <Divider />
                {hasTabs ? (
                    <TabEditor
                        tabRefreshDict={tabRefreshDict}
                        onViewChange={onViewChange}
                        view={view}
                        renderTab={({ tabKey, isActiveTab }) => {
                            return (
                                <FieldGrid
                                    addFieldLabel={addFieldLabel}
                                    getAddToBottomFieldWidth={getAddToBottomFieldWidth}
                                    includeSearchType={includeSearchType}
                                    recalculateHeightKey={isActiveTab.toString()}
                                    createField={createField}
                                    tab={tabKey}
                                    fields={fieldsByTab[tabKey] || emptyArr}
                                    view={view}
                                    onViewChange={onViewChange}
                                    mode={mode}
                                    openMoveFieldDialog={openMoveFieldDialog}
                                    renderMoveButton={(item) => {
                                        return (
                                            view?.tabs && (
                                                <IconButton
                                                    style={{ zIndex: 1000, transform: 'scaleX(-1)' }}
                                                    size="small"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleFieldMove(tabKey, item);
                                                    }}
                                                >
                                                    <Reply />
                                                </IconButton>
                                            )
                                        );
                                    }}
                                />
                            );
                        }}
                    />
                ) : null}
            </div>
        );
    },
);
export default EditableViewFormLayout;
