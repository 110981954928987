import { Checkbox, FormControlLabel, FormHelperText, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useContext } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import useViewConfig from 'util/hooks/useViewConfig';
import { FormData } from '../FormData';
import { ErrorMessage } from '@hookform/error-message';

const RenderUnpersistedRequiredFields = ({ defaultValues }: { defaultValues: Partial<FormData> }) => {
    const methods = useFormContext<FormData>();
    const currentWidgetType = methods.watch('widgetType');
    const unpersistedValueset = methods.watch('unpersistedValueset');
    const viewConfig = useViewConfig();
    const { getInputLabelProps, fieldVariant } = useContext(themeOverrideContext);

    const renderEntityTypeField = (required: false | 'orValueset' | 'required') => {
        const entityOptions = Object.keys(viewConfig.entities);
        return (
            <>
                <Controller
                    render={(props) => (
                        <Autocomplete
                            options={entityOptions}
                            value={props.value ?? ''}
                            defaultValue={defaultValues?.unpersistedEntity ?? ''}
                            autoHighlight
                            onChange={(e, value) => props.onChange(value)}
                            getOptionLabel={(option) => option}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant={fieldVariant}
                                    InputLabelProps={getInputLabelProps({
                                        shrink: true,
                                    })}
                                    label="Entity"
                                    margin="normal"
                                    fullWidth
                                    inputProps={{
                                        ...params.inputProps,
                                        draggable: false,
                                        autoComplete: 'disabled',
                                    }}
                                />
                            )}
                        />
                    )}
                    defaultValue={defaultValues?.['unpersistedEntity'] ?? ''}
                    name="unpersistedEntity"
                    control={methods.control as any}
                    rules={
                        required === 'orValueset'
                            ? {
                                  validate: (value) => {
                                      if (value && methods.getValues('unpersistedValueset')) {
                                          return 'Cannot point to both an entity and valueset. Pick one.';
                                      }
                                      if (!value && !methods.getValues('unpersistedValueset')) {
                                          return 'An Entity type or Valueset is required';
                                      }
                                  },
                              }
                            : required === 'required'
                            ? {
                                  validate: (value) => {
                                      if (!value) {
                                          return 'An Entity Type is required';
                                      }
                                  },
                              }
                            : undefined
                    }
                />
                <ErrorMessage
                    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                    errors={methods.errors}
                    name="unpersistedEntity"
                />
            </>
        );
    };
    const renderValuesetField = (required: false | 'orEntity' | 'required') => {
        return (
            <>
                <Controller
                    render={(props) => (
                        <TextField
                            margin="normal"
                            InputLabelProps={getInputLabelProps({ shrink: true })}
                            label={!required ? 'Value set (optional)' : 'Value set'}
                            variant={fieldVariant}
                            fullWidth
                            {...props}
                            onChange={(e) => {
                                props.onChange(e);
                                methods.trigger();
                            }}
                        />
                    )}
                    defaultValue={defaultValues?.['unpersistedValueset'] ?? ''}
                    name="unpersistedValueset"
                    control={methods.control as any}
                    rules={
                        required === 'orEntity'
                            ? {
                                  validate: (value) => {
                                      if (value && methods.getValues('unpersistedEntity')) {
                                          return 'Cannot point to both an entity and valueset. Pick one.';
                                      }
                                      if (!value && !methods.getValues('unpersistedEntity')) {
                                          return 'An Entity type or Valueset is required';
                                      }
                                  },
                              }
                            : required === 'required'
                            ? {
                                  validate: (value) => {
                                      if (!value) {
                                          return 'A ValueSet is required';
                                      }
                                  },
                              }
                            : undefined
                    }
                />
                <ErrorMessage
                    render={({ message }) => <FormHelperText error>{message}</FormHelperText>}
                    errors={methods.errors}
                    name="unpersistedValueset"
                />
            </>
        );
    };
    const renderIsManyField = () => {
        return (
            <>
                <Controller
                    render={({ onChange, value }) => (
                        <FormControlLabel
                            label="Is Many"
                            control={<Checkbox checked={value} onChange={(e) => onChange(e.target.checked)} />}
                        />
                    )}
                    defaultValue={defaultValues?.['unpersistedIsMany'] ?? false}
                    name="unpersistedIsMany"
                    control={methods.control as any}
                />
            </>
        );
    };
    switch (currentWidgetType) {
        case 'VALUESET_SUGGEST':
            return renderValuesetField('required');
        case 'CHECKBOX':
            return (
                <>
                    {renderValuesetField(false)}
                    {unpersistedValueset && renderIsManyField()}
                </>
            );
        case 'SELECT':
        case 'MULTISELECT':
            return (
                <>
                    {renderValuesetField('orEntity')}
                    {renderEntityTypeField('orValueset')}
                </>
            );
        case 'MULTIPLE_ENTITY_TYPEAHEAD':
        case 'ENTITY_TYPEAHEAD':
        case 'MULTI_CARD':
        case 'ENTITY_CHIP':
        case 'INLINE_MANY':
            // pick entity;
            return renderEntityTypeField('required');
        default:
            return null;
    }
};

export default RenderUnpersistedRequiredFields;
