import { createSlice } from '@reduxjs/toolkit';
import { ConditionalDefaultValueExpressionMetaData } from './ConditionalDefaultValueExpressions';

export const conditionalDefaultValueExpressions = createSlice({
    name: 'ConditionalDefaultValueExpressions',
    initialState: {} as ConditionalDefaultValueExpressionMetaData,
    reducers: {
        set: (
            state,
            action: {
                payload: ConditionalDefaultValueExpressionMetaData;
            },
        ) => {
            return action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const { set } = conditionalDefaultValueExpressions.actions;

export default conditionalDefaultValueExpressions.reducer;
