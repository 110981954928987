import React from 'react';
import { connect } from 'react-redux';
import Refresh from '@material-ui/icons/Refresh';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import { load } from 'viewConfig/actions';
import { RootState } from 'reducers/rootReducer';
import { CircularProgress } from '@material-ui/core';
import { useUserCanEditViewDefs } from 'layout-editor/viewdef-editor/useUserCanEditViewDefs';

const RefreshViewConfigButton = ({ mobile, label, refresh, isLoading }) => {
    const hasPermission = useUserCanEditViewDefs();

    if (!hasPermission) {
        return null;
    }
    return mobile ? (
        <MenuItem disabled={isLoading} onClick={refresh}>
            {label}
            <Refresh />
        </MenuItem>
    ) : (
        <IconButton disabled={isLoading} aria-label="Refresh Viewconfig" color="inherit" onClick={refresh}>
            {isLoading ? <CircularProgress style={{ color: 'white' }} /> : <Refresh />}
        </IconButton>
    );
};
const makeMapStateToProps = () => {
    return (state: RootState) => {
        return {
            isLoading: state.viewConfigIsLoading,
        };
    };
};

const enhance = connect(makeMapStateToProps, {
    refresh: load,
});
RefreshViewConfigButton.defaultProps = {
    mobile: false,
    label: '',
};

export default enhance(RefreshViewConfigButton);
