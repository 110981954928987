import getFormInitial from './index';
import { createSelector } from 'reselect';
import { RootState } from 'reducers/rootReducer';
import { fromNullable } from 'fp-ts/lib/Option';
import { createGetEntities } from '../EntityFormContext/util/getEntities';
import ViewConfig from 'reducers/ViewConfigType';
import merge from 'lodash/merge';
import getEmptyInitializedViewData from 'components/generics/utils/getEmptyInitializedViewData';
import { EntityVisibilityExps } from 'reducers/entityVisibilityReducer';
import { ViewItemFilterExpressionsGeneratedType } from 'viewConfigCalculations/filterExpressions/ViewItemFilterExpressionsGeneratedType';
import { TemplateExpressionsGenerated } from 'viewConfigCalculations/expressionTemplates/TemplateExpressionsGenerated';

interface Props {
    overrideViewConfig?: ViewConfig;
    viewName: string;
    id: string;
}

const createGetEmptyInitializedDataSelector = <
    T extends {
        viewName: string;
        overrideViewConfig?: ViewConfig;
        id?: string;
    },
>() =>
    createSelector(
        (state: RootState, props: T) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: T) => props.viewName,
        (viewConfig, viewName) => {
            return getEmptyInitializedViewData(viewConfig, viewName);
        },
    );

const createGetConceptExpressionFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => state.entityConceptExps[props.viewName],
        (ec) =>
            fromNullable(ec)
                .map((e) => Object.values(e).flatMap((ex) => ex.expansionsRequired || (ex as any).fieldsRequired || []))
                .getOrElse([]),
    );
};

const accumulator = (
    ev: EntityVisibilityExps[0] & ViewItemFilterExpressionsGeneratedType[0] & TemplateExpressionsGenerated[0],
) =>
    fromNullable(ev)
        .map((e) =>
            Object.values(e)
                .flatMap((ex) => ex)
                .flatMap((ey) => ey.expansionsRequired || (ey as any).fieldsRequired),
        )
        .getOrElse([]);

const createGetVisibilityExpressionFieldsRequired = <P extends Props>() => {
    return createSelector((state: RootState, props: P) => state.entityVisibility[props.viewName], accumulator);
};

const createGetEditabilityExpressionFieldsRequired = <P extends Props>() => {
    return createSelector((state: RootState, props: P) => state.entityEditability[props.viewName], accumulator);
};
const createGetCalcValueFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => state.viewCalcValueExpressions[props.viewName],
        (ev) => {
            return Object.values(ev ?? {}).flatMap((entry) => entry.expansionsRequired);
        },
    );
};
const createGetDefaultValueFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => state.viewDefaultValueExpressions[props.viewName],
        (ev) => {
            return Object.values(ev ?? {}).flatMap((entry) => entry.expansionsRequired);
        },
    );
};
const createGetValidationExpressionFieldsRequired = <P extends Props>() => {
    return createSelector(
        (state: RootState, props: P) => {
            const resource = props.overrideViewConfig
                ? props.overrideViewConfig.views[props.viewName].entity
                : props.viewName && state.viewConfig.views[props.viewName].entity;
            return state.entityValidations[resource];
        },
        (ev) =>
            fromNullable(ev)
                .map((e) => e.flatMap((ey) => ey.expansionsRequired || (ey as any).fieldsRequired))
                .getOrElse([]),
    );
};

const createGetFilterExpressionFieldsRequired = <P extends Props>() => {
    return createSelector((state: RootState, props: P) => state.viewItemFilterExps[props.viewName], accumulator);
};

const createGetTemplateExpressionFieldsRequired = <P extends Props>() => {
    return createSelector((state: RootState, props: P) => state.templateExps[props.viewName], accumulator);
};

const createGetExpressionFields = () => {
    const getVisFields = createGetVisibilityExpressionFieldsRequired();
    const getEditabilityFields = createGetEditabilityExpressionFieldsRequired();
    const getFilterFields = createGetFilterExpressionFieldsRequired();
    const getTemplateFields = createGetTemplateExpressionFieldsRequired();
    const getConcFields = createGetConceptExpressionFieldsRequired();
    const getValidationFields = createGetValidationExpressionFieldsRequired();
    const getDefaultValueFields = createGetDefaultValueFieldsRequired();
    const getCalcValueFields = createGetCalcValueFieldsRequired();
    const getExpressionFields = createSelector(
        getVisFields,
        getEditabilityFields,
        getConcFields,
        getValidationFields,
        getFilterFields,
        getTemplateFields,
        getDefaultValueFields,
        getCalcValueFields,
        (
            visfields,
            editabilityFields,
            concFields,
            valFields,
            filterFields,
            templateFields,
            defaultValueFields,
            calcValueFields,
        ) => {
            return [
                ...visfields,
                ...editabilityFields,
                ...concFields,
                ...valFields,
                ...filterFields,
                ...templateFields,
                ...defaultValueFields,
                ...calcValueFields,
            ];
        },
    );
    return getExpressionFields;
};

const createGetInitialFormValues = <P extends Props>(conf: { getIdFromProps: (props: P) => string }) => {
    const getExpressionFields = createGetExpressionFields();
    const getEntities = createGetEntities();
    const getEmptyInitializedViewData = createGetEmptyInitializedDataSelector();
    const getInitialValues = createSelector(
        getEmptyInitializedViewData,
        getExpressionFields,
        getEntities,
        (state: RootState, props: P) => props.overrideViewConfig || state.viewConfig,
        (state: RootState, props: P) => props.viewName,
        (state: RootState, props: P) => conf.getIdFromProps(props),

        (emptyInitializedViewData, expressionFields, entities, viewConfig, viewName, entityId) => {
            const fi = getFormInitial(viewConfig, viewName, expressionFields, entities, entityId, 'EXPANDED');
            return merge({}, emptyInitializedViewData, fi);
        },
    );
    return getInitialValues;
};
export default createGetInitialFormValues;
