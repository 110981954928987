import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: any) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        width: '100%',
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
}));

const FullWidthTabs: React.FunctionComponent<{
    items: [string, JSX.Element][];
    vertical?: boolean;
}> = (props) => {
    const { items, vertical = false } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    return (
        <div className={classes.root} style={{ flexDirection: vertical ? 'row' : 'column' }}>
            <AppBar position="static" color="default" style={{ width: vertical ? 'unset' : '100%', zIndex: 100 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    orientation={vertical ? 'vertical' : 'horizontal'}
                    variant="scrollable"
                    aria-label="full width tabs example"
                    className={vertical ? classes.tabs : ''} // Apply border style for vertical tabs
                >
                    {items.map(([label], i) => (
                        <Tab label={label} key={label} {...a11yProps(i)} />
                    ))}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                style={{ flexGrow: 1 }} // Ensure content area fills remaining space
            >
                {items.map(([label, element], i) => (
                    <TabPanel key={i} value={value} index={i} dir={theme.direction}>
                        {element}
                    </TabPanel>
                ))}
            </SwipeableViews>
        </div>
    );
};

export default FullWidthTabs;
