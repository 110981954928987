import React, { useContext } from 'react';
import compose from 'recompose/compose';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import { WithStyles, createStyles, Theme, withStyles } from '@material-ui/core';
import moment from 'moment';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { withDateFormat } from '../../../dateFormat/Broadcasts';
import { IconButton } from '@material-ui/core';
import Clear from '@material-ui/icons/Clear';
import uniqueId from 'lodash/uniqueId';
import dateToDateTimeString from './dateToDateTimeString';
import { TextFieldUtils } from 'fieldFactory/input/hooks/useTextFieldUtils';
import { getUseFullWidthDateFieldsSelector } from 'util/applicationConfig';
import Message from 'i18n/components/Message';
import { EvaluateLabel } from '../aor/FieldTitle';
import V5DateTimePicker from './V5DateTimePicker';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import { useAppSelector } from 'reducers/rootReducer';

type Input = any;
type Meta = any;

const hideIfNoInput = branch(
    (props) => !props.input, // input is not provided
    renderNothing,
);

const styles = ({ palette, spacing }: Theme) =>
    createStyles({
        root: {
            /* ... */
        },
    });

const withFullWidth = (WrappedComponent) => {
    return (props) => {
        const fullWidth = useAppSelector(getUseFullWidthDateFieldsSelector);

        return <WrappedComponent {...props} fullWidth={fullWidth} />;
    };
};

export interface DateTimePickerProps {
    label: string;
    input: Input;
    meta: Meta;
    ariaInputProps?: {};
    renderLabel?: boolean;
    disabled?: boolean;
    options?: {};
    source?: string;
    overrideAriaLabel?: string;
}

interface DateTimePickerComponentProps extends WithStyles<typeof styles>, DateTimePickerProps {
    dateFormat: string;
    fullWidth: boolean;
}
interface DateTimePickerComponentState {
    key: number;
}
class DateTimePickerComponent extends React.Component<DateTimePickerComponentProps, DateTimePickerComponentState> {
    private baseId = uniqueId('datetimepicker');
    private errorMessageId = uniqueId('datetimepickerErrormsg');

    pickerInputRef: any;
    static defaultProps = {
        ariaInputProps: {},
        renderLabel: true,
    };
    state = { key: 0 };
    changeValue = (value) => {
        if (typeof value === 'string' && (value.trim().endsWith('A') || value.trim().endsWith('P'))) {
            value += 'M';
        }
        const iso = dateToDateTimeString(value);
        this.props.input.onChange(iso);
        this.props.input.onBlur(iso);
    };
    onBlur = (event) => {
        const isValidDate = moment(event.target.value, this.getDisplayFormat(), true).isValid();
        if (isValidDate) {
            this.changeValue(event.target.value);
            if (event.target.value.trim().endsWith('A') || event.target.value.trim().endsWith('P')) {
                this.setState(({ key }) => ({ key: key + 1 }));
            }
        } else {
            if (
                event.relatedTarget &&
                event.relatedTarget.nodeName === 'BUTTON' &&
                this.pickerInputRef === event.target
            ) {
                // skip clearing input
            } else {
                this.setState(({ key }) => ({ key: key + 1 }));
            }
        }
    };
    onChange = (c) => {
        this.changeValue(c);
    };
    clearInput = () => {
        this.props.input.onChange(null);
        this.props.input.onBlur('');
        this.pickerInputRef?.focus();
    };

    getDisplayFormat = () => `${this.props.dateFormat} hh:mm A`;
    render() {
        const { renderLabel, input, ariaInputProps, label, meta, disabled, options, overrideAriaLabel, dateFormat } =
            this.props;
        const { touched, error } = meta;
        const inputProps = {
            'aria-errormessage': (touched && error && this.errorMessageId) || undefined,
        };
        const offScreenInstructionsId = `${this.baseId}-instructions`;

        return (
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: this.props.fullWidth ? '100%' : undefined,
                }}
            >
                <EvaluateLabel label={label}>
                    {({ templatedLabel }) => (
                        <TextFieldUtils meta={meta}>
                            {({
                                createInputLabelProps,
                                fieldVariant,
                                muiErrorProp,
                                InputPropsClasses,
                                createFormHelperTextProps,
                                helperText,
                            }) => (
                                <>
                                    <div className="casetivity-off-screen" id={offScreenInstructionsId}>
                                        <Message
                                            id="widgets.datetime.helpertext"
                                            dm="Expected format is month month / day day / year year year year hour hour : minute minute am or pm"
                                        />
                                    </div>
                                    <KeyboardDateTimePicker
                                        key={this.state.key}
                                        inputRef={(ref) => {
                                            this.pickerInputRef = ref;
                                        }}
                                        InputLabelProps={{
                                            ...createInputLabelProps({
                                                shrink: true,
                                            }),
                                            htmlFor: templatedLabel,
                                        }}
                                        inputVariant={fieldVariant}
                                        InputProps={{
                                            inputProps: {
                                                'aria-describedby': offScreenInstructionsId,
                                                'aria-label': overrideAriaLabel ?? templatedLabel,
                                                ...inputProps,
                                                ...ariaInputProps,
                                                id: templatedLabel,
                                            },
                                            classes: InputPropsClasses,
                                        }}
                                        KeyboardButtonProps={{
                                            className: 'casetivity-hide-printing',
                                            'aria-label': `Select date from calendar for ${overrideAriaLabel ?? label}`,
                                        }}
                                        {...input}
                                        onChange={this.onChange}
                                        value={
                                            input.value
                                                ? moment(input.value).format(moment.HTML5_FMT.DATETIME_LOCAL)
                                                : null
                                        }
                                        onBlur={this.onBlur}
                                        margin="none"
                                        label={renderLabel && templatedLabel}
                                        error={muiErrorProp}
                                        helperText={helperText}
                                        disabled={disabled}
                                        format={this.getDisplayFormat()}
                                        FormHelperTextProps={createFormHelperTextProps(inputProps)}
                                        leftArrowButtonProps={{
                                            'aria-label': 'Previous month',
                                        }}
                                        rightArrowButtonProps={{
                                            'aria-label': 'Next month',
                                        }}
                                        {...options}
                                    />
                                    {!disabled && input.value && input.value.length > 0 && (
                                        <IconButton
                                            aria-label={`Clear date for ${overrideAriaLabel ?? templatedLabel}`}
                                            style={
                                                !fieldVariant || fieldVariant === 'standard'
                                                    ? {
                                                          position: 'absolute',
                                                          right: 30,
                                                          top: renderLabel ? 8 : -8,
                                                      }
                                                    : {
                                                          position: 'absolute',
                                                          right: 50,
                                                          top: 4,
                                                      }
                                            }
                                            onClick={this.clearInput}
                                        >
                                            <Clear />
                                        </IconButton>
                                    )}
                                </>
                            )}
                        </TextFieldUtils>
                    )}
                </EvaluateLabel>
            </div>
        );
    }
}

export const DateTimeV4: React.SFC<DateTimePickerProps> = compose(
    hideIfNoInput,
    withDateFormat,
    withStyles(styles),
    withFullWidth,
)(DateTimePickerComponent);

const DateTimeInputs = (props) => {
    const { useV5DatePicker } = useContext(themeOverrideContext);

    return <div>{useV5DatePicker ? <V5DateTimePicker {...props} /> : <DateTimeV4 {...props} />}</div>;
};

export default DateTimeInputs;
