import { Field } from './translation/types/index';
import { DataSource } from './translation/types/DataSource';
import { getLabelledBy } from './input/fieldUtils';

export type AriaSupportProps = {
    labelledBy?: string;
};

export function getAriaSupportProperties(row: Number = 0, col: Number = 0, fieldDefinition: Field) {
    const ariaConfigs: AriaSupportProps = {};
    const labelledByConfigValue = getLabelledBy(fieldDefinition);
    if (labelledByConfigValue) {
        let labelledByValue = '';
        if (labelledByConfigValue.startsWith('&')) {
            const parsedValue = parseLabelledBy(labelledByConfigValue);
            const isFlowable = fieldDefinition._dataSource === DataSource.FLOWABLE;
            // Lets set a min column value in case someone configuring overshoots the flowable minimum column of 1
            const columnValue = Math.max(isFlowable ? 1 : 0, col.valueOf() + parsedValue.col.valueOf());
            const calculatedValue = `${isFlowable ? 'flowable' : ''}expression:r${
                row.valueOf() + parsedValue.row.valueOf()
            }c${columnValue}`;
            labelledByValue = calculatedValue;
        } else {
            labelledByValue = labelledByConfigValue;
        }
        ariaConfigs.labelledBy = labelledByValue;
    }
    return ariaConfigs;
}

type ParsedLabelledBy = {
    row: Number;
    col: Number;
};

function parseLabelledBy(value: String): ParsedLabelledBy {
    const rowAndColParts = value.trim().toLowerCase().split('r')[1].split('c');
    return { row: Number(rowAndColParts[0]), col: Number(rowAndColParts[1]) };
}
