import { getAllFieldEntriesFromViewObj } from 'components/generics/utils/viewConfigUtils';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';
import { View } from 'reducers/ViewConfigType';

const getFieldsWithCalcsAndEditableWidgets = (view: View) => {
    if (view.viewType === 'SHOW') {
        return []; // In SHOW views, no widgets are editable.
    }
    let evc;
    try {
        evc = JSON.parse(view.config) as EntityViewConfig;
    } catch (e) {
        console.error(e);
        return [];
    }

    const fieldsWithWidgets = getAllFieldEntriesFromViewObj(view).reduce(
        (prev, [fieldId, field]) => {
            prev[fieldId] = true;
            return prev;
        },
        {} as {
            [fieldId: string]: true;
        },
    );
    return Object.keys(evc?.calcValueExpressions ?? {}).filter((key) => {
        const permanentlyDisabled = evc?.editableField
            ?.flatMap((obj) => (obj[key] ? [obj[key]] : []))
            .flatMap((expression) => {
                return expression.trim().startsWith('[') ? (JSON.parse(expression) as string[]) : [expression.trim()];
            })
            .some((exp) => exp.trim().toLowerCase() === 'false');
        return fieldsWithWidgets[key] && !permanentlyDisabled;
    });
};

export default getFieldsWithCalcsAndEditableWidgets;
