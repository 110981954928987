import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { withWidth, Typography, TableCell, FormHelperText } from '@material-ui/core';
import compose from 'recompose/compose';
import { stringify } from 'query-string';
import { adjustProps } from '../../../hoc/special/ReferenceManyFieldProvider';
import PopoverCreateButton from '../../../popovers/PopoverCreateButton';
import {
    getDefaultSort,
    getAccessLevelForEntity,
    allowsCreate,
    getAccessLevelForEntityField,
    getLongestRefonePathInPath,
    getRefEntityName,
    getAllPrefilters,
    allowsEdit,
} from '../../../../components/generics/utils/viewConfigUtils';
import { SORT_DESC } from 'components/generics/genericList/queryReducer';
import { GenericListWithPopovers, getRenderAtRowEnd } from 'components/generics/genericList';
import { RenderListArguments, Sort } from 'components/generics/genericList/List';
import { crudUpdate as crudUpdateAction } from 'sideEffect/crud/update/actions';
import { crudGetList as crudGetListAction } from 'sideEffect/crud/getList/actions';
import { getCustomViewName } from 'components/generics/utils/viewConfigUtils';
import { REF_MANY_PER_PAGE } from 'config';
import { renderTableHead } from 'components/generics/genericList/renderList';
import Pagination from 'components/generics/genericList/Pagination';
import { Table } from '@material-ui/core';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { withRefreshContext } from 'components/generics/form/refreshContext';
import { useHideCreateButton } from 'components/generics/genericList/ListActions';
import { activeTabContext } from 'components/generics/form/TabbableForm';
import memoizeOne from 'memoize-one';
import PopoverAddButton from 'fieldFactory/popovers/PopoverAddButton';
import RemoveChild from './RemoveChild';
import { getHidePaginationIfNotNeededSelector, getUseNativeSelectSelector } from 'util/applicationConfig';
import SafeHtmlAsReact from 'templatePage/components/SafeHtmlAsReact';
import { useEvaluateTemplate } from 'expressions/Provider/hooks/useKeyCachingEval';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';
import WithBackrefsToParent from './util/WithBackrefsToParent';
import WithUpdateDownloadedViews from './util/WithDownloadedDataUpdate';
import EmptyTable from './util/EmptyNoIntermediateLink';
import getNoResultsElement from 'components/generics/genericList/getNoResultsTextElement';
import getParentFieldInChild from './util/getParentFieldInChild';
import set from 'lodash/set';
import { refetchAllXManysContext } from './util/refetchAllXManysContext';
import Message from 'i18n/components/Message';
import { createGetEntities, createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import { FormState } from 'redux-form';
import { useEvaluateTemplateInFormContext } from 'expressions/hooks/allForms/useEvaluateTemplate';
import { refreshableContext } from 'components/WithRefreshableContext';
import { INLINE_MANY_PERPAGE } from 'fieldFactory/input/components/InlineMany/constants';

const DEFAULT_SORT = {
    field: 'id',
    order: SORT_DESC,
};

export interface RefManyProps {
    filter?: string;
    includeTopCreateButton?: boolean;
    openTo?: 'show' | 'edit';
    resourceBasePath: string;
    parentFieldInChild: string;
    record: {
        id: string;
        entityType: string;
    };
    resource: string;
    config: string;
    crudGetList: typeof crudGetListAction;

    parentEntityName: string;
    parentId: string;
    label: string | JSX.Element | null;
    displayOnly: boolean;
    source: string;
    referenceFieldsShouldFetchInitialData: boolean;
    embeddedInFormId: string;
    viewName: string;
    hasCreate: boolean;
    hasAdd?: boolean;
    hasEdit?: boolean;
    isForShow?: boolean;
    noClick?: boolean;
    disabled?: boolean;
    ariaInputProps?: {};
    refresh?: (e?: any, fullRefresh?: boolean) => void;
    openToEditAfterCreate?: boolean;
    hideCreateButton: boolean;
    renderOverrideNavButton?: (record: {}) => JSX.Element;
    hidePaginationIfNotNeeded?: boolean;
    isDynamicallyTemplatedFilter?: boolean;
    overrideRenderList?: (
        props: RenderListArguments & {
            renderAtRowEnd?: ReturnType<typeof getRenderAtRowEnd>;
            referencedByField?: string;
        },
    ) => JSX.Element | null;
    titleElement?: JSX.Element;
    createableIntermediateLinkIfMissing?: boolean;
    noPagination?: boolean;
    inlineMany?: {
        useCreateView?: boolean;
        createViewName?: string;
    };
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
    noEmptyTable?: boolean;
}
const makeMapStateToProps = () => {
    const getEntities = createGetEntities();
    const getValueSets = createGetValueSets();
    return (state: RootState, props: RefManyProps) => {
        return {
            entities: getEntities(state),
            valueSet: getValueSets(state),
            hidePaginationIfNotNeeded: props.hidePaginationIfNotNeeded || getHidePaginationIfNotNeededSelector(state),
            viewConfig: state.viewConfig,
            printMode: state.printMode,
            nativeSelect: getUseNativeSelectSelector(state),
        };
    };
};
interface RefManyComponentProps
    extends Omit<RefManyProps, 'hidePaginationIfNotNeeded'>,
        ReturnType<ReturnType<typeof makeMapStateToProps>> {}
interface RefManyComponentState {
    location: {
        pathname: string;
        search: string;
    };
    key: number;
}
class RefManyComponent extends React.Component<RefManyComponentProps, RefManyComponentState> {
    _getLongestRefoneChain = memoizeOne((path) =>
        getLongestRefonePathInPath(this.props.viewConfig, this.props.parentEntityName, path),
    );
    static defaultProps = {
        displayOnly: false,
        widthStyles: {},
        record: undefined,
        viewConfig: undefined,
        label: 'Show All',
        resourceBasePath: '',
        parentEntityName: '',
        parentFieldInChild: '',
        parentId: '',
    };
    constructor(props: RefManyComponentProps) {
        super(props);
        this.state = {
            location: {
                pathname: this.props.resourceBasePath,
                // search: `?${this.props.parentFieldInChild}%22%3A%22${this.props.record.id}%22%7D`,
                search: `INITIAL`,
            },
            key: 0,
        };
    }
    getDefaultSort = (): Sort => getDefaultSort(this.props.viewConfig, this.getViewName('LIST')) || DEFAULT_SORT;
    getPermanentFilterKey = () => {
        const { parentFieldInChild } = this.props;
        return parentFieldInChild;
    };
    getPermanentFilter = () => ({
        [this.getPermanentFilterKey()]: this.props.record.id,
    });
    getViewName = (viewType: 'LIST' | 'CREATE', resource = this.props.resource, config = this.props.config) =>
        getCustomViewName(viewType)(resource, this.props.viewConfig, config);

    refreshList = () => {
        this.setState(
            (state) => ({ ...state, key: state.key + 1 }),
            () => {
                if (this.props.refresh) {
                    this.props.refresh(undefined, true);
                }
            },
        );
    };
    getBackrefCreateButtonProps = () => {
        const { parentFieldInChild, parentEntityName, parentId } = this.props;
        return parentFieldInChild && parentFieldInChild.slice(0, -1 * '.id'.length).includes('.')
            ? {} /* Here we will have the 'getHardReference' function '*/
            : {
                  parentEntityName,
                  parentFieldInChild: parentFieldInChild && parentFieldInChild.slice(0, -1 * '.id'.length) + 'Id',
                  parentId,
              };
    };
    getLongestRefOneChain = (pfc = this.props.source) => this._getLongestRefoneChain(pfc);
    renderWithBackrefsToParent = (
        render: (
            args: null | {
                parentEntityName?: string;
                parentId?: string;
                parentFieldInChild?: string;
            },
        ) => JSX.Element | null,
        endWith: '.id' | 'Id' = 'Id', // Id is necessary to pass to the PopoverCreateButton so it sets the data on create
        // .id matches the way our list references are set, so use that to create List props

        // linkedEntityFormat describes how we should name linked-entity fields in "parentFieldInChild" field-path
        // linked<entityType> is used for query parameters,
        // e.g. ?linkedStaff.userId=<foo>&...
        // linkedEntity is used to set the reference on Create.
        // e.g. { "linkedEntityId": "<foo>"}
        linkedEntityFormat: 'linkedEntity' | 'linked<entityType>',
    ) => {
        const { parentEntityName, parentId, source } = this.props;
        return (
            <WithBackrefsToParent
                render={render}
                endWith={endWith}
                linkedEntityFormat={linkedEntityFormat}
                parentEntityName={parentEntityName}
                parentId={parentId}
                source={source}
            />
        );
    };
    renderCreateButton = (openEdit?: (id: string) => void) => {
        const { resource, config, hasEdit, viewConfig, openToEditAfterCreate } = this.props;
        const renderCreateButton = (options?: {} | null) => (
            <WithUpdateDownloadedViews
                editViewName={
                    !hasEdit || !allowsEdit(getAccessLevelForEntity(viewConfig, resource))
                        ? -1
                        : getCustomViewName('EDIT')(resource, viewConfig, config)
                }
                showViewName={getCustomViewName('SHOW')(resource, viewConfig, config)}
            >
                {({ maybeUpdateDownloadedViews }) => (
                    <PopoverCreateButton
                        evaluatedAdhocSPELVariables={this.props.evaluatedAdhocSPELVariables}
                        resource={resource}
                        label={'Create'}
                        onCreateCb={(id, data) => {
                            if (openToEditAfterCreate) {
                                this.props.refresh(undefined, true);
                                setImmediate(() => openEdit?.(id));
                            } else {
                                this.refreshList();
                            }
                            maybeUpdateDownloadedViews(id);
                        }}
                        viewName={this.getViewName('CREATE')}
                        {...options}
                    />
                )}
            </WithUpdateDownloadedViews>
        );
        return this.renderWithBackrefsToParent(renderCreateButton, 'Id', 'linkedEntity');
    };
    renderAddButton = () => {
        return this.renderWithBackrefsToParent(
            ({ parentEntityName, parentFieldInChild, parentId }) => (
                <PopoverAddButton
                    filter={this.props.filter}
                    hideCreate={this.props.hasCreate === false}
                    onAddCb={this.refreshList}
                    parentEntityName={parentEntityName}
                    parentFieldInChild={parentFieldInChild}
                    parentId={parentId}
                    resource={this.props.resource}
                />
            ),
            'Id',
            'linkedEntity',
        );
    };
    getLocation = (loc: { pathname: string; search: string }, parentFieldInChild?: string, parentId?: string) => {
        if (loc.search === 'INITIAL') {
            return {
                ...loc,
                search: `?${stringify({
                    filter: JSON.stringify({
                        [parentFieldInChild]: parentId,
                    }),
                })}`,
            };
        }
        return loc;
    };
    render() {
        const {
            parentEntityName,
            parentId,
            parentFieldInChild: _parentFieldInChild,
            displayOnly,
            source,
            viewConfig,
            referenceFieldsShouldFetchInitialData,
            embeddedInFormId,
            resource,
            hasCreate,
            hasAdd,
            printMode,
            config,
            disabled,
            hideCreateButton,
            ariaInputProps,
            noClick,
            openTo,
            titleElement,
            evaluatedAdhocSPELVariables,
            disallowClickAwayNavigation,
        } = this.props;
        const customTitleElement =
            this.props.label === '_NONE_' ? (
                <div />
            ) : (
                titleElement ?? (
                    <Typography
                        variant="h6"
                        style={
                            {
                                /* fontWeight: 'bold' */
                            }
                        }
                        component="div"
                    >
                        {this.props.label}
                    </Typography>
                )
            );
        const parentFieldInChild =
            source === 'revisions' && _parentFieldInChild && _parentFieldInChild.endsWith('.id')
                ? _parentFieldInChild.slice(0, -3) + 'Id' // revisions MUST use Id and not .id
                : _parentFieldInChild;
        const { key } = this.state;
        const accessLevel = getAccessLevelForEntity(viewConfig, resource);
        return this.renderWithBackrefsToParent(
            (backrefs) => {
                if (!backrefs) {
                    if (!this.props.createableIntermediateLinkIfMissing) {
                        return (
                            <div>
                                <Typography variant="h6" component="div">
                                    No{' '}
                                    {(() => {
                                        try {
                                            return (
                                                viewConfig.entities[
                                                    getRefEntityName(
                                                        viewConfig,
                                                        parentEntityName,
                                                        this.getLongestRefOneChain() || this.props.source,
                                                        'TRAVERSE_PATH',
                                                    )
                                                ].displayName || resource
                                            );
                                        } catch {
                                            return resource;
                                        }
                                    })()}{' '}
                                    to get {this.props.label} Data
                                </Typography>
                            </div>
                        );
                    }
                    const parentField = getParentFieldInChild({
                        viewConfig,
                        endWith: 'Id',
                        linkedEntityFormat: 'linkedEntity',
                        source,
                        parentEntityName,
                    }).parentFieldInChild;
                    return (
                        <div>
                            {customTitleElement}
                            <EmptyTable
                                inlineMany={
                                    this.props.inlineMany
                                        ? {
                                              source,
                                              parentEntityType: parentEntityName,
                                              parentId,
                                              useCreateView: this.props.inlineMany.useCreateView,
                                              createViewName: this.props.inlineMany.createViewName,
                                              evaluatedAdhocSPELVariables,
                                          }
                                        : undefined
                                }
                                parentFieldInChild={parentFieldInChild}
                                viewName={this.getViewName('LIST')}
                            />
                            {this.props.inlineMany ? null : (
                                <>
                                    {getNoResultsElement(<Message id="list.noResults" dm="No results found" />)}
                                    <PopoverCreateButton
                                        evaluatedAdhocSPELVariables={this.props.evaluatedAdhocSPELVariables}
                                        resource={resource}
                                        label={'Create'}
                                        onCreateCb={(id, data) => {
                                            this.refreshList();
                                        }}
                                        {...(() => {
                                            const x = {};
                                            const deepPathToSetParentIdAt = (() => {
                                                if (parentFieldInChild.endsWith('Id')) {
                                                    return parentFieldInChild;
                                                }
                                                if (parentFieldInChild.endsWith('.id')) {
                                                    return parentFieldInChild.slice(0, -3) + 'Id';
                                                }
                                                console.log({
                                                    parentFieldInChild,
                                                    source,
                                                    parentField,
                                                });
                                                throw new Error(
                                                    'Parent field in child should end with ".id" or "Id". Instead it is "' +
                                                        parentFieldInChild +
                                                        '". See console for more details.',
                                                );
                                            })();
                                            set(x, deepPathToSetParentIdAt, parentId);
                                            return {
                                                injectCreateValues: x,
                                                parentEntityName,
                                                parentFieldInChild: deepPathToSetParentIdAt,
                                                parentId,
                                            };
                                        })()}
                                        viewName={this.getViewName('CREATE')}
                                        hideFields={[parentField]}
                                    />
                                </>
                            )}
                        </div>
                    );
                }
                const { parentId: dynamic_parentId, parentFieldInChild: dynamic_parentFieldInChild } = backrefs;
                const accessLevelAsField = parentEntityName
                    ? getAccessLevelForEntityField(viewConfig, parentEntityName, source, 'TRAVERSE_PATH')
                    : accessLevel;
                const createAllowed = allowsCreate(Math.min(accessLevel, accessLevelAsField));
                const showCreate = hasCreate && createAllowed && !hideCreateButton && !disabled;
                // use editAllowed to determine whether to allow 'hasAdd'
                const editAllowed = allowsEdit(Math.min(accessLevel, accessLevelAsField));
                const showAdd = hasAdd && editAllowed && !disabled;
                const addBtn = this.renderAddButton();
                const referencedFromEntity = source !== 'revisions' ? !!parentEntityName : false;
                const referencedByField = source !== 'revisions' ? dynamic_parentFieldInChild : null;

                const renderCreate = <
                    Args extends { refresh?: () => void; onRowSelect?: RenderListArguments['onRowSelect'] },
                >(
                    r: Args,
                ) => {
                    return showCreate
                        ? this.renderCreateButton(
                              this.props.openToEditAfterCreate
                                  ? (id) => {
                                        r.refresh?.();
                                        r.onRowSelect([{ id }], { [id]: { id } }, 'edit');
                                    }
                                  : undefined,
                          )
                        : null;
                };
                return (
                    <refreshableContext.Consumer>
                        {({ wasRefreshed }) => (
                            <refetchAllXManysContext.Consumer>
                                {(refetchKey) => (
                                    <GenericListWithPopovers
                                        disallowClickAwayNavigation={disallowClickAwayNavigation}
                                        openTo={openTo}
                                        noClick={noClick}
                                        editViewName={
                                            // prevent edit button, otherwise default
                                            this.props.hasEdit === false ? -1 : undefined
                                        }
                                        fetchOnMount={
                                            wasRefreshed['process-level'] ||
                                            key !== 0 ||
                                            printMode ||
                                            dynamic_parentFieldInChild !== parentFieldInChild ||
                                            // referenceFieldsShouldFetchInitialData:
                                            // important this is last - if undefined, we do default behavior
                                            // (we don't want false if this is undefined)

                                            // if referenceFieldsShouldFetchInitialDate is false, we don't fetchOnMount
                                            // because the view mount handler will handle the fetch earlier.

                                            // fetch on mount anyways if the eager request would have to be made with templated filters using $[], which can't be done.
                                            (referenceFieldsShouldFetchInitialData === false &&
                                                this.props.isDynamicallyTemplatedFilter)
                                                ? true
                                                : referenceFieldsShouldFetchInitialData
                                        }
                                        showFilters={false}
                                        noRecentlyVisited={true}
                                        fetchKey={refetchKey}
                                        key={`${key}:${dynamic_parentId} ${printMode ? 'printMode' : ''}`}
                                        useCard={false}
                                        customTitleElement={customTitleElement}
                                        perPage={
                                            this.props.noPagination || printMode
                                                ? `${INLINE_MANY_PERPAGE}`
                                                : `${REF_MANY_PER_PAGE}`
                                        }
                                        renderActions={
                                            this.props.includeTopCreateButton
                                                ? (r) => <span style={{ flexBasis: '100%' }}>{renderCreate(r)}</span>
                                                : null
                                        }
                                        evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                        referencedFromEntity={referencedFromEntity}
                                        referencedByField={referencedByField}
                                        formId={`filterForm-${resource}-${this.props.record && this.props.record.id}`}
                                        displayRowEditButton={!displayOnly}
                                        canSelectRows={displayOnly}
                                        hasCreate={hasCreate || !displayOnly}
                                        multiSelectable={false}
                                        updateUrlFromFilter={false}
                                        embeddedInFormId={embeddedInFormId}
                                        createRedirectQueryString={`?parentEntity=${parentEntityName}&parentField=${parentFieldInChild}&parentId=${parentId}`}
                                        viewName={this.getViewName('LIST')}
                                        fakePush={(location) => {
                                            this.setState((state) => ({ ...state, location }));
                                        }}
                                        filter={{
                                            [dynamic_parentFieldInChild]: dynamic_parentId,
                                            ...getAllPrefilters(
                                                viewConfig,
                                                resource,
                                                this.getViewName('LIST'),
                                                this.props.entities,
                                                this.props.valueSet,
                                            ),
                                            ...getFilterFromFilterString(this.props.filter),
                                        }}
                                        location={this.getLocation(
                                            this.state.location,
                                            dynamic_parentFieldInChild,
                                            dynamic_parentId,
                                        )}
                                        resource={resource}
                                        reference={resource}
                                        showCreate={false}
                                        renderList={({ defaultRenderer, ...r }) => {
                                            const renderer = this.props.overrideRenderList ?? defaultRenderer;
                                            return renderer({
                                                ...r,
                                                defaultRenderer: this.props.overrideRenderList
                                                    ? defaultRenderer
                                                    : undefined,
                                                disabled,
                                                referencedByField,
                                                showCreate,
                                                ariaProps: { ...r.ariaProps, ...(ariaInputProps || {}) },
                                                renderOverrideNavButton: this.props.renderOverrideNavButton,
                                                renderAtRowEnd: hasAdd
                                                    ? (r, record) => [
                                                          <RemoveChild
                                                              onRemove={this.refreshList}
                                                              fieldToNull={dynamic_parentFieldInChild}
                                                              record={
                                                                  record as {
                                                                      id: string;
                                                                      title: string;
                                                                      entityType: string;
                                                                  }
                                                              }
                                                          />,
                                                      ]
                                                    : undefined,
                                            });
                                        }}
                                        sort={this.getDefaultSort()}
                                        config={config}
                                        renderNoResults={({ getDefaultNoResults, ...r }) => {
                                            if (this.props.noEmptyTable) {
                                                return (
                                                    <>
                                                        {getDefaultNoResults()}
                                                        {renderCreate(r)}
                                                        {showAdd ? addBtn : null}
                                                    </>
                                                );
                                            }
                                            if (this.props.inlineMany) {
                                                return (
                                                    <EmptyTable
                                                        disabled={this.props.disabled}
                                                        inlineMany={{
                                                            source,
                                                            parentEntityType: parentEntityName,
                                                            parentId,
                                                            useCreateView: this.props.inlineMany.useCreateView,
                                                            createViewName: this.props.inlineMany.createViewName,
                                                            renderNoData: () => getDefaultNoResults(),
                                                            evaluatedAdhocSPELVariables,
                                                        }}
                                                        parentFieldInChild={parentFieldInChild}
                                                        viewName={this.getViewName('LIST')}
                                                    />
                                                );
                                            }
                                            return (
                                                <React.Fragment>
                                                    <Table aria-hidden={true}>
                                                        {renderTableHead(
                                                            r,
                                                            undefined,
                                                            undefined,
                                                            0, // extra columns to append.
                                                            'DISABLED',
                                                        )}
                                                    </Table>
                                                    {getDefaultNoResults()}
                                                    {renderCreate(r)}
                                                    {showAdd ? addBtn : null}
                                                </React.Fragment>
                                            );
                                        }}
                                        renderPagination={
                                            this.props.noPagination
                                                ? () => null
                                                : (r) => {
                                                      return (
                                                          <div>
                                                              <div style={{ float: 'left' }}>{renderCreate(r)}</div>
                                                              <div style={{ float: 'left' }}>
                                                                  {showAdd ? addBtn : null}
                                                              </div>
                                                              <div style={{ float: 'right' }}>
                                                                  {this.props.hidePaginationIfNotNeeded &&
                                                                  r.total <= REF_MANY_PER_PAGE ? null : (
                                                                      <Pagination
                                                                          SelectProps={{
                                                                              inputProps: {
                                                                                  'aria-label': 'Rows per page',
                                                                              },
                                                                              native: this.props.nativeSelect,
                                                                          }}
                                                                          {...r}
                                                                      />
                                                                  )}
                                                              </div>
                                                              <div style={{ clear: 'both' }} />
                                                          </div>
                                                      );
                                                  }
                                        }
                                    />
                                )}
                            </refetchAllXManysContext.Consumer>
                        )}
                    </refreshableContext.Consumer>
                );
            },
            this.props.source === 'revisions' ? 'Id' : '.id',
            'linked<entityType>',
        );
    }
}

const enhance2 = compose(
    connect(makeMapStateToProps, {
        crudUpdate: crudUpdateAction,
        crudGetList: crudGetListAction,
    }),
    withRefreshContext,
    withWidth({
        initialWidth: 'md',
    }),
);
const RefMany = enhance2(RefManyComponent);

export default adjustProps()(
    ({
        reference,
        record,
        resource,
        relatedField,
        match,
        basePath,
        label,
        config,
        referenceFieldsShouldFetchInitialData,
        embeddedInFormId,
        source,
        hasAdd,
        hasCreate,
        disabled,
        hasEdit,
        ariaInputProps,
        printMode,
        noClick,
        renderLabel,
        openTo,
        includeTopCreateButton,
        overrideNavButtonHtml,
        hidePaginationIfNotNeeded,
        filter,
        isDynamicallyTemplatedFilter,
        overrideRenderList,
        titleElement,
        createableIntermediateLinkIfMissing,
        openToEditAfterCreate,
        noPagination,
        inlineMany,
        evaluatedAdhocSPELVariables,
        disallowClickAwayNavigation,
        noEmptyTable,
    }) => {
        const listViewName = useAppSelector((state: RootState) =>
            getCustomViewName('LIST')(reference, state.viewConfig, config),
        );
        const hideCreateButton = useHideCreateButton(listViewName);
        const evaluateNavButtonFromData = useEvaluateTemplate(overrideNavButtonHtml ?? '');
        const renderNavButton = useCallback(
            (record) => {
                const html = evaluateNavButtonFromData(record);
                return html ? (
                    <TableCell key="overridenav" padding="none">
                        <SafeHtmlAsReact html={html} />
                    </TableCell>
                ) : null;
            },
            [evaluateNavButtonFromData],
        );
        const formError = useAppSelector(
            (state: RootState) => (state.form[embeddedInFormId] as FormState)?.submitErrors?.[source],
        );
        const templatedLabel = useEvaluateTemplateInFormContext(label);

        return (
            <activeTabContext.Consumer>
                {(tabContext) => {
                    // context should have default value 'true' to be safe (only hide this when we know we are in a form, AND passing false)
                    if (
                        !printMode &&
                        tabContext._type === 'NON_ACTIVE_TAB' &&
                        tabContext.status === 'NOT_INITIALIZED'
                    ) {
                        return null;
                    }
                    return (
                        <>
                            <RefMany
                                noEmptyTable={noEmptyTable}
                                disallowClickAwayNavigation={disallowClickAwayNavigation}
                                evaluatedAdhocSPELVariables={evaluatedAdhocSPELVariables}
                                inlineMany={inlineMany}
                                openToEditAfterCreate={openToEditAfterCreate}
                                titleElement={titleElement}
                                overrideRenderList={overrideRenderList}
                                isDynamicallyTemplatedFilter={isDynamicallyTemplatedFilter}
                                filter={filter}
                                renderOverrideNavButton={overrideNavButtonHtml ? renderNavButton : undefined}
                                includeTopCreateButton={includeTopCreateButton}
                                openTo={openTo}
                                hidePaginationIfNotNeeded={hidePaginationIfNotNeeded}
                                hideCreateButton={hideCreateButton}
                                displayOnly={true}
                                hasCreate={hasCreate}
                                hasAdd={hasAdd}
                                resourceBasePath={`/${reference}`}
                                resource={reference}
                                config={config}
                                noClick={noClick}
                                ariaInputProps={ariaInputProps}
                                label={renderLabel !== false ? templatedLabel : '_NONE_'}
                                source={source}
                                hasEdit={hasEdit}
                                parentEntityName={resource}
                                parentFieldInChild={`${relatedField}.id`}
                                parentId={(record as any).id}
                                record={record}
                                basePath={basePath}
                                referenceFieldsShouldFetchInitialData={referenceFieldsShouldFetchInitialData}
                                embeddedInFormId={embeddedInFormId}
                                disabled={disabled}
                                createableIntermediateLinkIfMissing={createableIntermediateLinkIfMissing}
                                noPagination={noPagination}
                            />
                            {formError && (
                                <FormHelperText error style={{ fontSize: '1rem' }}>
                                    <p>
                                        The following error
                                        {Array.isArray(formError) && formError.length > 1 ? 's were' : ' was'} caught on
                                        submission of the data above{' '}
                                        {typeof label === 'string' && (label as string).trim() ? `(${label})` : ''}. You
                                        may have to inspect the associated records to correct, then attempt to
                                        re-submit.
                                    </p>
                                    {Array.isArray(formError)
                                        ? formError.map((err, i) => <p key={err}>{err}</p>)
                                        : formError}
                                </FormHelperText>
                            )}
                        </>
                    );
                }}
            </activeTabContext.Consumer>
        );
    },
);
