import get from 'lodash/get';
import stableStringify from 'fast-json-stable-stringify';
import isPojo from '../../../../util/isPojo';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';
import ViewConfig from 'reducers/ViewConfigType';
import set from 'lodash/set';
import clone from 'clone';
export interface ValuesetFieldAvailableConcepts {
    [field: string]:
        | {
              [conceptId: string]: boolean;
          }
        | '*';
}

export interface AvailableOptions {
    [field: string]: {
        empty: { name: string; value?: unknown } | null;
        options: {
            [stringifiedOption: string]: boolean;
        };
    };
}

export const replaceValuesetFieldsWithNullIfConceptsUnavailable = (
    values: {},
    valuesetFieldAvailableConcepts: ValuesetFieldAvailableConcepts,
    valuesetOneFields: {
        [field: string]: any;
    },
) => {
    const isOne = (f: string) => !!valuesetOneFields[f];
    let res = { ...values };
    Object.entries(valuesetFieldAvailableConcepts).forEach(([f, allowed]) => {
        const correctValue = (fieldIdPath: string) => {
            const value = get(values, fieldIdPath);
            if (value) {
                const isAllowed = allowed === '*' || (isOne(f) && allowed[value]);
                if (!isAllowed) {
                    const newValue = isOne(f) ? null : value && value.filter((id) => allowed[id]);
                    set(res, fieldIdPath, newValue);
                }
            }
        };
        if (isOne(f)) {
            correctValue(f); // <- this is ok for valueset fields, because we don't drill into the concept.
            // (I added that for REPORT forms, where 'Id' isn't appended necessarily.)
            correctValue(`${f}Id`);
        } else {
            correctValue(`${f}Ids`);
        }
    });
    return res;
};

export const replaceOptions = (
    values: {},
    availableOptions: AvailableOptions,
    replaceValuesWithEntryObjects: boolean = true,
) => {
    let res = { ...values };

    Object.entries(availableOptions).forEach(([f, { empty, options }]) => {
        const value = get(values, f);
        if (isPojo(value)) {
            if (options[stableStringify(value)]) {
                return;
            }
            set(res, f, empty);
            return;
        }
        if (!value) {
            set(res, f, empty);
            return;
        }
        if (typeof value === 'string' || typeof value === 'number') {
            if (typeof value === 'string') {
                if (value.startsWith('{') && value.endsWith('}')) {
                    try {
                        const parsed = JSON.parse(value);
                        if (options[stableStringify(parsed)]) {
                            if (replaceValuesWithEntryObjects) {
                                set(res, f, parsed);
                            }
                            return;
                        }
                    } catch (e) {
                        // continue to treat as plain value
                    }
                }
            }
            const optObjs = Object.keys(options).map((option) => JSON.parse(option));
            const foundOption = optObjs.find((o) => o.value && o.value === value);
            if (foundOption) {
                if (replaceValuesWithEntryObjects) {
                    set(res, f, foundOption);
                }
                return;
            }
            set(res, f, empty);
            return;
        }
    });
    return res;
};

export const getCalculateValuesBasedOnAvailableFields = (
    viewConfig: ViewConfig,
    entities: {},
    initialValues: {},
    formValues: {},
    entirelyHiddenOrDisabledFields: string[],
    valuesetFieldAvailableConcepts: ValuesetFieldAvailableConcepts = {},
    valuesetOneFields: {
        // just used to check if the field is a 'one' or a 'many' (it's a many if it's not in the list)
        [field: string]: any;
    } = {},
    availableOptions: AvailableOptions = {},
    filteredRefOneFields: string[] = [],
    evaluatedRefManyFilters: {
        [field: string]: {
            entityType: string;
            filter: {};
        };
    } = {},
    forceNull: string[] = [],
    dontAdjustValueBasedOnConceptExpressions: {
        [fieldId: string]: true;
    } = {},
    variableResults = {},
    defaultValuesWhenHiddenOrDisabled?: {},
) => {
    const adjustedRefManys = Object.entries(evaluatedRefManyFilters).flatMap(([field, { entityType, filter }]) => {
        const ids = formValues[field];
        if (ids && Array.isArray(ids)) {
            const resultingValue = ids.filter((id) => {
                return filterEntityByQueryRepresentation(viewConfig)(filter)({ id, entityType }, entities);
            });
            if (resultingValue.length < ids.length) {
                return [[field, resultingValue] as [string, string[]]];
            }
        }
        return [];
    });
    const merged = (() => {
        let res = clone(formValues);
        /*
            Okay, lets think through the order here. I would really like to rename 'filteredRefOneFields' to be 'forceNull'. That way I can use it for 'nullWhenHidden'.
            The problem is that entirelyHiddenOrDisabledFields is applied afterwards, and if the filtered-null field is hidden or disabled, the value is set back to the initial.
            That kind of makes sense, because if a field is non-editable, we aren't exactly worried about the filter.
            But on the other hand, how many times do we have a filter on a field that is being hidden or disabled, where we don't want that value changed?

            Moreover adjustedRefManys is after entirelyHiddenOrDisabled.

            The consequence of moving filteredRefOneFields after entirelyHiddenOrDisabledFields is that:
            if a field is filtered null but also hidden or disabled, and has an initial value, it will no longer take that value, and instead be null.
            I think we don't necessarily want that to happen if a field is hidden or disabled.
            So we should instead add a 'forceNull' list instead. It's easy to refactor this function anyway because it's only used in one place.
        */
        filteredRefOneFields.forEach((f) => {
            set(res, f, null);
        });
        entirelyHiddenOrDisabledFields.forEach((f) => {
            const [keyInDataToUnset, maybeDefaultExpressionResult] = [f, f + 'Id', f + 'Ids']
                .map((key) => {
                    return [key, get(defaultValuesWhenHiddenOrDisabled, key)];
                })
                .find(([, defaultValueResult]) => typeof defaultValueResult !== 'undefined') ?? [f, undefined];

            set(
                res,
                keyInDataToUnset,
                typeof maybeDefaultExpressionResult !== 'undefined'
                    ? maybeDefaultExpressionResult
                    : get(initialValues, f),
            );
        });
        adjustedRefManys.forEach(([f, ids]) => {
            set(res, f, ids);
        });
        forceNull.forEach((f) => {
            // if it's an array, we probably mean for it to be an empty array, not null.
            if (Array.isArray(get(res, f))) {
                set(res, f, []);
                return;
            }
            set(res, f, null);
        });
        Object.entries(variableResults).forEach(([k, v]) => {
            set(res, k, v);
        });
        return res;
    })();

    const withConceptAvailabilityApplied = replaceValuesetFieldsWithNullIfConceptsUnavailable(
        merged,
        Object.fromEntries(
            Object.entries(valuesetFieldAvailableConcepts).filter(
                ([k]) => !dontAdjustValueBasedOnConceptExpressions[k],
            ),
        ),
        valuesetOneFields,
    );

    const withOptionsAvailabilityApplied = replaceOptions(withConceptAvailabilityApplied, availableOptions, true);
    return withOptionsAvailabilityApplied;
};
