import { nullAdhocVariablesContext } from 'components/generics/form/EntityFormContext/util/getVariablesContextSelector';
import {
    getContainsCodes,
    getExpandEntity,
    getGetConceptIdsFromCodes,
    getLookupConceptIdsFromValuesetGroup,
    getLookupEntityField,
    getValuesetConceptIdsFromCodes,
    getValuesetReverseLookupUtilities,
} from 'expressions/contextUtils';
import getHtmlTableFromList from 'expressions/contextUtils/getHtmlTableFromList';
import { Concept } from 'fieldFactory/input/components/Concept';
import getMessage, { getLocale } from 'i18n/getMessage';
import { IntlShape } from 'react-intl';
import ViewConfig from 'reducers/ViewConfigType';
import { RootState } from 'reducers/rootReducer';
import isOffline from 'util/isOffline';
import { ValueSets } from 'valueSets/reducer';

const { getVar, getVars } = nullAdhocVariablesContext;

/**
 * @info This is only available in Task forms. It was added to conditionally hide spacer html expressions in table widgets
 * inside of task-forms, so that they disappear when switching to mobile view when all widgets stack vertically.
 * (widgets inside to table rows wrap when we reach 12 columns in a row, so we can insert expressions to force wrapping and implement
 * row/column positioning)
 *
 *
 * @returns whether the page width is mobile (narrow) or not. Determined by pixel width of the window.
 */
export const pageIsMobile = () => {
    // This will be overwritten in our context object by the actual connected function
    return false;
};

export const isPrintMode = () => {
    // This will be overwritten in our context object by the actual connected function
    return false;
};
export class LocaleContext {
    private intl: IntlShape;
    constructor(intl: IntlShape) {
        this.intl = intl;
    }
    public getLocale = () => {
        return this.intl ? getLocale(this.intl)() : 'en';
    };
    public getMessage = (messageId: string, values: Record<string, unknown> | string[]): string => {
        return this.intl ? getMessage(this.intl)(messageId, values) : messageId;
    };
}

export class EntityAndConceptLookupContext {
    private reverseLookups: ReturnType<typeof getValuesetReverseLookupUtilities>;
    private viewConfig: ViewConfig;
    private entities: Record<string, Record<string, unknown>> & {
        Concept?: Record<string, Concept>;
    };
    private valueSets: ValueSets;
    constructor(args: {
        valueSets: ValueSets;
        entities: Record<string, Record<string, unknown>> & {
            Concept?: Record<string, Concept>;
        };
        viewConfig: ViewConfig;
    }) {
        this.viewConfig = args.viewConfig;
        this.entities = args.entities;
        this.valueSets = args.valueSets;
        this.reverseLookups = getValuesetReverseLookupUtilities(args.valueSets, args.entities.Concept);
    }
    public valuesetIsLoaded = (valueSetCode: string): boolean => {
        return this.reverseLookups.valuesetIsLoaded(valueSetCode);
    };
    public getConceptFromCode = (valueSetCode: string, conceptCode: string): Concept => {
        return this.reverseLookups.getConceptFromCode(valueSetCode, conceptCode);
    };
    public getConceptFromDisplay = (valueSetCode: string, display: string): Concept => {
        return this.reverseLookups.getConceptFromDisplay(valueSetCode, display);
    };
    public getConceptIdFromCode = (valueSetCode: string, conceptCode: string): string => {
        return this.reverseLookups.getConceptIdFromCode(valueSetCode, conceptCode);
    };
    public getConceptIdFromDisplay = (valueSetCode: string, conceptDisplay: string): string => {
        return this.reverseLookups.getConceptIdFromDisplay(valueSetCode, conceptDisplay);
    };
    public getConceptCodeFromDisplay = (valueSetCode: string, conceptDisplay: string): string => {
        return this.reverseLookups.getConceptCodeFromDisplay(valueSetCode, conceptDisplay);
    };
    public isValidConcept = (valueSetCode: string, conceptId: string): boolean => {
        return this.reverseLookups.isValidConcept(valueSetCode, conceptId);
    };
    public isValidConceptFromCode = (valueSetCode: string, conceptCode: string): boolean => {
        return this.reverseLookups.isValidConceptFromCode(valueSetCode, conceptCode);
    };
    public isValidConceptFromDisplay = (valueSetCode: string, conceptDisplay: string): boolean => {
        return this.reverseLookups.isValidConceptFromDisplay(valueSetCode, conceptDisplay);
    };
    public isConceptInGroup = (valueSetCode: string, conceptId: string, group: string): boolean => {
        return this.reverseLookups.isConceptInGroup(valueSetCode, conceptId, group);
    };

    public containsCodes = (ids: string[], ...codes: string[]): boolean => {
        return getContainsCodes(
            (this.entities.Concept ?? {}) as {
                [id: string]: Concept;
            },
        )(ids, ...codes);
    };
    public lookupEntityData = (entityType: string, id: string, expression: string): unknown => {
        return getLookupEntityField(this.viewConfig, this.entities)(entityType, id, expression);
    };
    public expandEntityData = (entityType: string, id: string, paths: string[]): Record<string, unknown> => {
        return getExpandEntity(this.viewConfig, this.entities)(entityType, id, paths);
    };

    public mapToEntityData = (entityType: string, ids: null | string[], path: string): unknown[] => {
        if (!ids) {
            return [];
        }
        return ids.map((id) => this.lookupEntityData(entityType, id, path));
    };

    public lookupConceptIdsFromValuesetGroup = (valueSet: string, group: string): string[] => {
        return getLookupConceptIdsFromValuesetGroup(this.valueSets, this.entities)(valueSet, group);
    };

    public getConceptIdsFromCodes = (...codes: string[]): string[] => {
        return getGetConceptIdsFromCodes(this.entities.Concept)(...codes);
    };
    public getValuesetConceptIdsFromCodes = (valueSetCode: string, ...codes: string[]): string[] => {
        return getValuesetConceptIdsFromCodes(this.valueSets, this.entities)(valueSetCode, ...codes);
    };
}

export class CurrentUserContext {
    user: ViewConfig['user'];
    constructor(user: ViewConfig['user']) {
        this.user = user;
    }
    public getCurrentUserProperty = (property: string) => {
        return this.user?.properties?.[property] ?? null;
    };
    public currentUserHasRole = (roleName: string) => {
        return this.user?.roles?.some((r) => r.toLowerCase() === roleName.toLowerCase()) ?? false;
    };
    public getCurrentUserId = () => {
        return this.user?.id ?? null;
    };
    public getCurrentUserLogin = () => {
        return this.user?.login ?? null;
    };
    public userIsAnonymous = () => {
        return this.getCurrentUserLogin() === 'anonymousUser';
    };
}

export const isNumEqual = (value1: string | number, value2: string | number) => {
    const v1 = parseInt(value1 as any, 10);
    const v2 = parseInt(value2 as any, 10);
    return !isNaN(v1) && v1 === v2;
};
export const isZero = (value: any) => {
    return isNumEqual(value, 0);
};
export const isFrontend = () => {
    return true;
};
export const isBackend = () => {
    return false;
};

export const getAppConfig = (() => {
    let basicInfo: RootState['basicInfo'];
    return (appConfigName: string) => {
        try {
            if (!basicInfo) {
                basicInfo = JSON.parse((window as any).CASETIVITY_BASIC_INFO);
            }
            const found = basicInfo.application?.publicAppConfigs?.[appConfigName] ?? null;
            return found;
        } catch (e) {
            console.error(e);
            return null;
        }
    };
})();

export const getUserAgent = () => navigator.userAgent;

export { isOffline, getVar, getVars, getHtmlTableFromList };
