import uniq from 'lodash/uniq';
import {
    getDataTypeForFieldExpr,
    isFieldViewField,
    getViewIndexAndAdditionalConfigFields,
    getIsExpensiveForFieldExpr,
    getCustomViewName,
    getRefEntityName,
    getPathBackFromFieldPath,
    expandComponentFields,
    getAllFieldEntriesFromView,
    isRefManyField,
    getLongestRefonePathInPath,
} from '../../../components/generics/utils/viewConfigUtils/index';
import ViewConfigType, { FieldViewField, ViewField } from '../../../reducers/ViewConfigType';
import * as fieldTypes from '../../../components/generics/utils/fieldDataTypes';
import { ActionButtonExps } from 'viewConfigCalculations/actionButtonDisplayExpressions/ActionButtonExps';
import { fromNullable, fromPredicate, tryCatch } from 'fp-ts/lib/Option';
import get from 'lodash/get';
import getFields from 'components/generics/genericList/getFields';
import { RootState } from 'reducers/rootReducer';
import ViewConfig from '../../../reducers/ViewConfigType';
import traversePath from 'components/generics/utils/viewConfigUtils/traversePath';
import getFieldsRequiredForExpression from 'clients/utils/getFieldsRequiredForExpression';
import getFilterFromFilterString from 'fieldFactory/input/components/ListSelect/getFilterFromFilterString';

type View = ViewConfigType['views'][keyof ViewConfigType['views']];

const referenceFieldsWeMustExpand = [
    fieldTypes.REFONE,
    fieldTypes.REFMANY_JOIN,
    fieldTypes.VALUESET,
    fieldTypes.VALUESET_MANY,
    fieldTypes.REFMANYMANY,
];

const filterToValidExpansionPaths = (
    viewConfig: ViewConfigType,
    baseEntity: string,
    expansionsRequired: string[] = [],
) => {
    // get fields that require expansions
    return expansionsRequired.flatMap((fexp) => {
        if (fexp.endsWith('.all')) {
            return [fexp];
        }
        if (fexp.endsWith('Ids')) {
            // many-ids always require expansion
            return [fexp];
        }
        if (!fexp.includes('.') && fexp.endsWith('Id')) {
            return [];
        }
        const field = traversePath(viewConfig)(({ isManyReference, isOneReference, isValueset, field }) =>
            !isOneReference && !isManyReference && !isValueset && !field.expensive ? 'stop' : 'continue',
        )(baseEntity, fexp, false);
        if (field._t === 'completed') {
            return [fexp];
        }
        return field.pathBefore ? [field.pathBefore] : [];
    });
};
const getExpansionPathsForFieldSet = (
    baseEntity: string,
    _viewFields: FieldViewField[],
    viewConfig: ViewConfigType,
): string[] => {
    const viewFields = expandComponentFields(
        viewConfig,
        _viewFields.filter(isFieldViewField).map((f) => [f.field, f] as [string, FieldViewField]),
        baseEntity,
        {
            rebaseExpressionsWithinFields: true, // for filters
            replaceXmanyWithMultiCard: false,
        },
    )
        .expandedFieldsByRow.flat()
        .map((t) => t[1]) as FieldViewField[];
    // first get all fields containing a '.' that aren't REFMANY fields (they will fetch their own data)
    const fieldsWithExpansions: FieldViewField[] = viewFields.filter(
        (f) =>
            getDataTypeForFieldExpr(viewConfig, f.entity, f.field) !== 'REFMANY' &&
            getDataTypeForFieldExpr(viewConfig, f.entity, f.field) !== fieldTypes.REFMANY_JOIN &&
            f.field.indexOf('.') !== -1,
    );
    // then pop off the field so we get all the expansion paths we need
    const expansionPaths: string[] = fieldsWithExpansions.map((f) => f.field.slice(0, f.field.lastIndexOf('.')));

    // next, make sure we get all REFONE and ValueSet+valueSet_Many fields expanded
    const referenceFields: FieldViewField[] = viewFields.filter(
        (f) => referenceFieldsWeMustExpand.indexOf(getDataTypeForFieldExpr(viewConfig, f.entity, f.field)) !== -1,
    );
    const expensiveCalcsFields: string[] = viewFields
        .filter((f) => getIsExpensiveForFieldExpr(viewConfig, f.entity, f.field))
        .map((f) => f.field);

    // e.g. if we have
    // claimPayer.serviceClaim.serviceLog.enrollment.insurances
    // we want
    // claimPayer.serviceClaim.serviceLog.enrollment
    // The longest subpath of all x-1s
    const fieldsExpandedInOneToManySubPath: string[] = viewFields.flatMap((f) => {
        if (isRefManyField(viewConfig, f.entity, f.field, 'POP_LAST') && f.field.includes('.')) {
            try {
                const longestRef1Path = getLongestRefonePathInPath(viewConfig, baseEntity, f.field);
                if (!longestRef1Path) {
                    return [];
                }
                return [longestRef1Path];
            } catch (e) {
                console.error(e);
            }
        }
        return [];
    });
    const fieldsExpandedForInlineMany: string[] = viewFields.flatMap((f) => {
        if (f.widgetType === 'INLINE_MANY') {
            const { withPathsBack } = f.field.split('.').reduce(
                (prev, curr) => {
                    prev.pathForward.push(curr);
                    const currPath = prev.pathForward.join('.');
                    try {
                        const pathBack = getPathBackFromFieldPath(viewConfig, baseEntity, currPath, 'linkedEntity');
                        const fullPathBackAtCurrentDepth = prev.pathForward.concat(pathBack.split('.'));
                        prev.withPathsBack.push(fullPathBackAtCurrentDepth.join('.'));
                    } catch (e) {
                        console.error(e);
                    }
                    return prev;
                },
                {
                    pathForward: [] as string[],
                    withPathsBack: [] as string[],
                },
            );
            return withPathsBack;
        }
        return [];
    });

    // lets expand whatever is necessary for the ManyMany list views:
    // basically ([f.field], with whatever deep expansions necessary appended)
    const fieldsExpandedInManyMany: string[] = referenceFields
        .filter((f) => getDataTypeForFieldExpr(viewConfig, f.entity, f.field) === fieldTypes.REFMANYMANY)
        .map((f) => {
            const viewUsed = fromPredicate<string>(Boolean)(f.config)
                .chain((c) => tryCatch(() => JSON.parse(c)))
                .chain((c) => fromPredicate<string>(Boolean)(c.viewName))
                .getOrElseL(() => {
                    return getCustomViewName('LIST', false)(
                        getRefEntityName(viewConfig, f.entity, f.field, 'POP_LAST'),
                        viewConfig,
                        f.config,
                    );
                });
            const listFilterExpansions = fromPredicate<string>(Boolean)(f.config)
                .chain((c) => tryCatch(() => JSON.parse(c)))
                .chain((c) => fromPredicate<string>(Boolean)(c.listFilter))
                .chain((listFilter) => tryCatch(() => getFilterFromFilterString(listFilter)))
                .map((filterObj) => Object.keys(filterObj).map((k) => (k.includes('__') ? k.split('__')[0] : k)))
                .map((exps) => exps.map((exp) => f.field + '.' + exp))
                .getOrElse([]);
            const fields = getFields(viewConfig, viewUsed, true, f.field);
            const manyManyListViewFieldsWithDepth = fields.filter((vf) => {
                return (
                    isFieldViewField(vf) &&
                    (vf.field.indexOf('.') !== -1 ||
                        referenceFieldsWeMustExpand.indexOf(
                            getDataTypeForFieldExpr(viewConfig, vf.entity, vf.field, 'POP_LAST'),
                        ) !== -1 ||
                        getIsExpensiveForFieldExpr(viewConfig, vf.entity, vf.field, 'POP_LAST'))
                );
            }) as FieldViewField[];

            const expandAll = f.field; // `${f.field}.all` if we really want to be safe;
            if (manyManyListViewFieldsWithDepth.length > 0) {
                return (
                    expandAll +
                    `,${f.field}.` +
                    buildExpansionForFieldSet(
                        viewConfig.views[viewUsed].entity,
                        manyManyListViewFieldsWithDepth,
                        viewConfig,
                    )
                        .split(',')
                        .join(`,${f.field}.`) +
                    (listFilterExpansions.length > 0 ? ',' + listFilterExpansions : '')
                );
            }
            if (listFilterExpansions.length > 0) {
                return expandAll + ',' + listFilterExpansions.join(',');
            }
            return expandAll;
        });
    const configuredForExpansion: string[] = referenceFields
        .filter((f) => f.widgetType === 'SELECT')
        .flatMap((f) => {
            return fromNullable(f.config)
                .chain((c) => tryCatch(() => JSON.parse(c)))
                .mapNullable((c) => c.expansions)
                .getOrElse([])
                .map((path) => `${f.field}.${path}`);
        });
    const referenceFieldPaths: string[] = [...referenceFields.map((f) => f.field), ...fieldsExpandedInManyMany];
    const allPaths = uniq([
        ...expansionPaths,
        ...referenceFieldPaths,
        ...configuredForExpansion,
        ...expensiveCalcsFields,
        ...fieldsExpandedForInlineMany,
        ...fieldsExpandedInOneToManySubPath,
    ]);
    return allPaths;
};
export const combinePathsIntoExpansionString = (paths: string[]): string => {
    const uniquePaths = uniq(paths);
    const expansionPathsWithLongestOnly = uniquePaths.filter(
        (path) =>
            !uniquePaths.find(
                (otherPath) =>
                    otherPath.startsWith(path) && otherPath.length > path.length && otherPath[path.length] === '.',
            ),
    );

    // now sort in ascending order + alphabetical tie breaker, and concatenate
    return expansionPathsWithLongestOnly.sort((a, b) => a.length - b.length || b.localeCompare(a)).join(',');
};
export const buildExpansionForFieldSet = (
    baseEntity: string,
    viewFields: FieldViewField[],
    viewConfig: ViewConfigType,
    fieldsRequiredForValidations: string[] = [],
): string => {
    return combinePathsIntoExpansionString(
        uniq([...getExpansionPathsForFieldSet(baseEntity, viewFields, viewConfig), ...fieldsRequiredForValidations]),
    );
};

const convertFieldForValidationToUseableExpansion = (valFieldExp: string) => valFieldExp.split('_ALL_').join('all');

export const getRecordFieldsRequiredForActionButtons = (ve: ActionButtonExps[0]) =>
    fromNullable(ve)
        .mapNullable((ve) =>
            Object.values(ve)
                .flatMap((e) => e.expansionsRequired ?? [])
                .map(convertFieldForValidationToUseableExpansion)
                .filter((f) => f.startsWith('record.'))
                .map((f) => f.slice('record.'.length)),
        )
        .getOrElse([]);

const getFieldsRequiredForAdhocConfigExpressions = (pathInConfig: string) => (fields: ViewField[]) => {
    return fields.flatMap((f) => {
        if (!isFieldViewField(f)) {
            return [];
        }
        return fromPredicate<string>(Boolean)(f.config)
            .chain((configStr) => tryCatch(() => JSON.parse(configStr)))
            .mapNullable((config) => get(config, pathInConfig))
            .chain((exp) => fromPredicate<string>((exp) => typeof exp === 'string')(exp))
            .map((exp: string) => getFieldsRequiredForExpression(exp))
            .getOrElse([]);
    });
};

const getExpansionsSelector =
    (state: RootState) =>
    (
        _viewName: string,
        options?: {
            overrideViewConfig?: ViewConfig;
        },
    ): string => {
        const viewConfig = options?.overrideViewConfig ?? state.viewConfig;
        const entityValidations = state.entityValidations;
        const viewValidations = state.viewValidations;

        const [viewName, bpmConfigFields] = getViewIndexAndAdditionalConfigFields(
            _viewName,
            viewConfig,
            'ALWAYS_LINKEDENTITY',
        );
        const view: View = viewConfig.views[viewName];

        const entityVisibilityExpansionsRequired = Object.values(state.entityVisibility[viewName] ?? {})
            .flat()
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);
        const entityEditabilityExpansionsRequired = Object.values(state.entityEditability[viewName] ?? {})
            .flat()
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);
        const entityConceptExpressionsExpansionsRequired = Object.values(state.entityConceptExps[viewName] ?? {})
            .flat()
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);
        const entityFilterExpressionsExpansionsRequired = Object.values(state.viewItemFilterExps[viewName] ?? {})
            .flat()
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);
        const entityTemplateExpressionsExpansiosnRequired = Object.values(state.templateExps[viewName] ?? {})
            .flat()
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);
        const fieldsRequiredForValidationsOnMainEntity = (entityValidations[view.entity] || [])
            .flatMap((conf) => conf.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);

        const fieldsRequiredForValidationsOnView = (viewValidations[view.name] || [])
            .flatMap((conf) => conf.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);

        const entityCalcExpansionsRequired = Object.values(state.viewCalcValueExpressions[viewName] ?? {})
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);

        const entityDefaultValueExpansionsRequired = Object.values(state.viewDefaultValueExpressions[viewName] ?? {})
            .flatMap((ev) => ev.expansionsRequired ?? [])
            .map(convertFieldForValidationToUseableExpansion);

        const fieldsInView = expandComponentFields(
            viewConfig,
            getAllFieldEntriesFromView(viewConfig, viewName),
            viewConfig.views[viewName].entity,
            { rebaseExpressionsWithinFields: false, replaceXmanyWithMultiCard: state.printMode },
        )
            .expandedFieldsByRow.flat()
            .map((t) => t[1]);

        const fieldsRequiredForRefManyHasCreate = getFieldsRequiredForAdhocConfigExpressions('hasCreate')(
            fieldsInView.filter((f) => {
                return (
                    isFieldViewField(f) &&
                    (f.widgetType === 'MULTISELECT' || f.widgetType === 'MULTI_CARD') &&
                    getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANY'
                );
            }),
        );

        /**
         * This requires parsing so see if this is strictly necessary for every fetch.
         */
        const fieldsRequiredForHideEditButton = (() => {
            if (!view.config) {
                return [];
            }
            try {
                const parsedConfig = JSON.parse(view.config);
                const hideEditButtonConfig = parsedConfig['hideEditButton'];
                if (
                    hideEditButtonConfig &&
                    typeof hideEditButtonConfig === 'string' &&
                    !['true', 'false'].includes(hideEditButtonConfig.trim())
                ) {
                    return getFieldsRequiredForExpression(hideEditButtonConfig);
                }
                return [];
            } catch (e) {
                return [];
            }
        })();

        const fieldsRequiredForActionButtons = getRecordFieldsRequiredForActionButtons(
            state.actionButtonExps[view.name],
        );

        // any 'nested' fields, e.g. contact.firstName, we need to pull in any validations on the contact that reference 'firstName'
        // and include the fields on those as well.
        const fieldsRequiredForValidationsOnNestedFields =
            viewConfig.views[viewName].viewType === 'EDIT'
                ? uniq(
                      fieldsInView.flatMap((f) => {
                          if (isFieldViewField(f) && f.field.includes('.')) {
                              const [reference] = f.field.split('.');
                              const referenceEntity = viewConfig.entities[view.entity].fields[reference].relatedEntity;
                              const validations = fromNullable(entityValidations[referenceEntity]).map((v) => {
                                  return v.flatMap((validation) => {
                                      if (
                                          (validation.expansionsRequired ?? []).some((fr) => {
                                              // if the validation has some field in our view:
                                              return fieldsInView.some((fiv) => {
                                                  if (isFieldViewField(fiv)) {
                                                      return fiv.field === `${reference}.${fr}`;
                                                  }
                                                  return false;
                                              });
                                          })
                                      ) {
                                          // if a validation includes a field in our view, include all other fields required for that validation
                                          return (validation.expansionsRequired ?? []).map(
                                              (fr) => `${reference}.${fr}`,
                                          );
                                      }
                                      return [];
                                  });
                              });
                              return validations.getOrElse([]);
                          }
                          return [];
                      }),
                  )
                : []; // gets 'deep' fields.

        const viewFields: FieldViewField[] = [...fieldsInView, ...bpmConfigFields].filter(
            (f) => f.widgetType !== 'EXPRESSION',
        ) as FieldViewField[];

        const expansions = filterToValidExpansionPaths(
            viewConfig,
            view.entity,
            view.viewType === 'SHOW'
                ? [
                      ...entityVisibilityExpansionsRequired,
                      ...entityTemplateExpressionsExpansiosnRequired,
                      ...fieldsRequiredForRefManyHasCreate,
                      ...fieldsRequiredForActionButtons,
                      ...fieldsRequiredForHideEditButton,
                      ...entityCalcExpansionsRequired,
                      ...entityDefaultValueExpansionsRequired,
                  ]
                : [
                      ...entityVisibilityExpansionsRequired,
                      ...entityEditabilityExpansionsRequired,
                      ...entityConceptExpressionsExpansionsRequired,
                      ...entityFilterExpressionsExpansionsRequired,
                      ...entityTemplateExpressionsExpansiosnRequired,
                      ...fieldsRequiredForValidationsOnMainEntity,
                      ...fieldsRequiredForValidationsOnView,
                      ...fieldsRequiredForValidationsOnNestedFields,
                      ...fieldsRequiredForRefManyHasCreate,
                      ...fieldsRequiredForActionButtons,
                      ...entityCalcExpansionsRequired,
                      ...entityDefaultValueExpansionsRequired,
                  ],
        );
        return buildExpansionForFieldSet(view.entity, viewFields, viewConfig, expansions);
    };

export default getExpansionsSelector;
