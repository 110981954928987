import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import WidgetFactory from '../service/WidgetFactory';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { fromNullable, some } from 'fp-ts/lib/Option';
import { createSelector } from 'reselect';
import { DashboardGrid, LayoutItem, Widget } from 'dashboard2/dashboard-config/types';
import memoizeOne from 'memoize-one';
import { DashboardConfigWithWidgetMap } from 'dashboard2/dashboard-config/load-dashboards/reducer';
import { evaluateExpression } from 'expressions/evaluate';
import { buildContext } from 'spelContext/buildContext';

type WithWidgetsProps = {
    // either dashboard name (to look up) or an direct configuration
    dashboard: string | DashboardConfigWithWidgetMap;
    children: (args: {
        grid: DashboardGrid | null;
        widgetElements: null | ReturnType<typeof WidgetFactory.createAllWidgets>;
    }) => JSX.Element | null;
};
const getCurrentDashboard = <Props extends { dashboard: string | DashboardConfigWithWidgetMap }>(
    state: RootState,
    props: Props,
) =>
    typeof props.dashboard !== 'string'
        ? some(props.dashboard)
        : fromNullable(state.dashboard.dashboards.configs[props.dashboard]).map((dc) => dc.dashboardConfig);

const getCurrentDashboardId = <Props extends { dashboard: string | DashboardConfigWithWidgetMap }>(
    state: RootState,
    props: Props,
) => (typeof props.dashboard !== 'string' ? null : state.dashboard.dashboards.configs[props.dashboard]?.id);

const createGetWidgetElementsSelector = <
    P extends { dashboard: string | DashboardConfigWithWidgetMap; applyVisibilityExpressions?: boolean },
>() => {
    const getLayoutObject = memoizeOne((layoutItems: LayoutItem[]) => {
        return layoutItems.reduce<{
            [i: string]: LayoutItem;
        }>((prev, curr) => {
            prev[curr.i] = curr;
            return prev;
        }, {});
    });
    return createSelector(
        (state: RootState, props: P): DashboardConfigWithWidgetMap | null =>
            getCurrentDashboard(state, props).getOrElse(null),
        getCurrentDashboardId,
        (state: RootState) => state.viewConfig,
        (state: RootState, props: P) => props.applyVisibilityExpressions,
        (dc, dashConfigId, viewConfig, applyVisibilityExpressions) => {
            if (dc) {
                const layout = dc.grid.layouts && dc.grid.layouts.md && getLayoutObject(dc.grid.layouts.md);
                // so we order things in the dom in a way that makes sense, tabbing-wise
                const widgetDefinitions = layout
                    ? Object.values(dc.widgets).sort((a, b) => {
                          // If any coordinate (x or y) is undefined, the subtraction will result in NaN.
                          // Let's default the result to zero using the || operator.
                          const c1 = layout[a.id]?.y - layout[b.id]?.y || 0;
                          const c2 = layout[a.id]?.x - layout[b.id]?.x || 0;
                          return c1 === 0 ? c2 : c1;
                      })
                    : Object.values(dc.widgets);

                if (applyVisibilityExpressions === false) {
                    return WidgetFactory.createAllWidgets(widgetDefinitions, dashConfigId);
                }
                const filteredWidgetDefinitions = widgetDefinitions.flatMap((def) => {
                    const visExp = def.definition?.visibilityExpression;
                    if (!visExp) {
                        return [def];
                    }
                    try {
                        const res = evaluateExpression(
                            visExp,
                            buildContext({
                                // we only need user properties, since we're probably solely concerned with 'roles' and #currentUserHasRole here.
                                // So we can avoid passing lots of other things.
                                viewConfig,
                            }),
                        );
                        if (res) {
                            return [def];
                        }
                        return [];
                    } catch (e) {
                        console.error(e);
                        const replaceWithWidget: Widget = {
                            type: 'HtmlWidget',
                            id: def.id,
                            title: def.title,
                            definition: {
                                html: `<div style="paddingLeft: 1rem"><p>An error occurred while evaluating the visibility expression:</p> <code class="casetivity-code">${visExp}<code/><p>The error has been logged to the console - see it for more details.</p></div>`,
                            },
                        };
                        return [replaceWithWidget];
                    }
                });
                return WidgetFactory.createAllWidgets(filteredWidgetDefinitions, dashConfigId);
            }
            return null;
        },
    );
};
const makeMapStateToProps = () => {
    const getWidgetElements = createGetWidgetElementsSelector();
    return (state: RootState, props: WithWidgetsProps) => {
        return {
            widgetElements: getWidgetElements(state, props),
            grid: getCurrentDashboard(state, props)
                .map((c) => c.grid)
                .getOrElse(null),
        };
    };
};
interface WithWidgetsComponentProps extends WithWidgetsProps, ReturnType<ReturnType<typeof makeMapStateToProps>> {}
class WithWidgetsComponent extends React.Component<WithWidgetsComponentProps> {
    render() {
        const { children, grid, widgetElements } = this.props;
        return children({ widgetElements, grid });
    }
}

export const useWidgets = (dashboard: string | DashboardConfigWithWidgetMap, applyVisibilityExpressions?: boolean) => {
    const getElementWidgetsSelector = useMemo(createGetWidgetElementsSelector, []);
    const widgetElements = useAppSelector((state: RootState) =>
        getElementWidgetsSelector(state, { dashboard, applyVisibilityExpressions }),
    );
    const grid = useAppSelector((state: RootState) =>
        getCurrentDashboard(state, { dashboard })
            .map((c) => c.grid)
            .getOrElse(null),
    );
    return { widgetElements, grid };
};

export default connect(makeMapStateToProps)(WithWidgetsComponent);
