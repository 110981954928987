import React from 'react';
import { Dialog, Card, CardHeader, CardContent, Button, CardActions } from '@material-ui/core';
import Themed from 'components/Themed';
import {
    getServerValidationErrors,
    translateAjaxError,
    constructSubmissionValidationsTree,
    SubmissionValidationsTree,
    ErrorMessage,
} from 'sideEffect/other/notificationEpic';
import { AjaxError } from 'rxjs/ajax';

type ErrorListProps = {
    validationTree: SubmissionValidationsTree;
    baseEntity: string; // just in case we want to look up labels in the viewConfig.
    headerText?: string;
};

export const ErrorList: React.FC<ErrorListProps> = ({ validationTree, headerText = 'Validation Errors' }) => {
    const renderErrors = (errors: ErrorMessage[]) => (
        <ul>
            {errors.map((error, index) => (
                <li key={index}>
                    <b>{error.message}</b>
                    {error.fieldsInExpression && error.fieldsInExpression.length > 0 && (
                        <ul>
                            {error.fieldsInExpression.map((field, idx) => (
                                <li key={idx}>{field}</li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    );

    const renderFieldErrors = (fieldErrors: { [field: string]: SubmissionValidationsTree }) => (
        <ul>
            {Object.entries(fieldErrors).map(([field, tree]) => (
                <li key={field}>
                    {field}
                    <div>{renderErrors(tree.errors)}</div>
                    <div>{renderFieldErrors(tree.fieldErrors)}</div>
                </li>
            ))}
        </ul>
    );

    return (
        <div>
            {headerText && <h3>{headerText}</h3>}
            {renderErrors(validationTree.errors)}
            {renderFieldErrors(validationTree.fieldErrors)}
        </div>
    );
};

// example
// const validationTree: SubmissionValidationsTree = {
//     errors: [
//         {
//             message: 'Top level error message',
//             fieldsInExpression: ['field1', 'field2'],
//         },
//     ],
//     fieldErrors: {
//         user: {
//             errors: [
//                 {
//                     message: 'User error message',
//                     fieldsInExpression: ['email', 'username'],
//                 },
//             ],
//             fieldErrors: {
//                 email: {
//                     errors: [{ message: 'Invalid email format' }],
//                     fieldErrors: {},
//                 },
//                 username: {
//                     errors: [{ message: 'Username is required' }],
//                     fieldErrors: {},
//                 },
//             },
//         },
//     },
// };
// when we dispatch the SubmissionError, we want to dispatch the base level 'errors'.
// For referenced errors, we want to look to see if the path + 'field' is found, and dispatch the error for that.
// Also: display errors which don't map onto registered fields on the page near the bottom.

interface ErrorDialogProps {
    alertError: AjaxError | null;
    clearAlert: () => void;
    resource: string;
}

const ErrorDialog = (props: ErrorDialogProps) => {
    const { clearAlert, alertError } = props;
    const serverValidationErrors =
        alertError && constructSubmissionValidationsTree(getServerValidationErrors(alertError));

    return (
        <Dialog
            TransitionProps={
                {
                    // https://github.com/dequelabs/axe-core/issues/146
                    role: 'presentation',
                } as any
            }
            onClose={clearAlert}
            open={Boolean(alertError)}
        >
            <div>
                {alertError && (
                    <Themed>
                        {({ theme }) => (
                            <Card>
                                <CardHeader title="Submission Failed" />
                                <CardContent style={{ color: theme.palette.error.main }}>
                                    {serverValidationErrors ? (
                                        <ErrorList
                                            baseEntity={props.resource}
                                            validationTree={serverValidationErrors}
                                        />
                                    ) : (
                                        translateAjaxError(alertError)
                                    )}
                                </CardContent>
                                <CardActions>
                                    <Button
                                        style={{ margin: '2px' }}
                                        variant="contained"
                                        color="primary"
                                        onClick={clearAlert}
                                    >
                                        Close
                                    </Button>
                                </CardActions>
                            </Card>
                        )}
                    </Themed>
                )}
            </div>
        </Dialog>
    );
};
export default ErrorDialog;
