import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { processActivityEventCreator } from 'bpm/actions/processActivityEvent';
import { EvaluatedFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import useProcessIsOpen from './hooks/useProcessIsOpen';
import createActivitiesSelector from './selectors/activitiesSelector';
import { createTask } from 'bpm/createTask/actions';
import Check from '@material-ui/icons/Check';
import { push } from 'connected-react-router';
import { useGetProcessConf } from 'bpm/hooks/useGetProcessConfItem';
import deepEql from 'fast-deep-equal';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { disableFormsaveNotifierDec, disableFormsaveNotifierInc } from 'formSaveNotifier/actions';
import { useSingleKeyCachingExpression } from 'expressions/Provider/hooks/useKeyCachingEval';

type ActivityStatus =
    | {
          _type: 'initial';
      }
    | {
          _type: 'pending';
      }
    | {
          _type: 'error';
          errorMessage: string;
      }
    | {
          _type: 'success';
      };
const Activity: React.FC<{
    taskDefinitionKey: string;
    processId: string;
    onReset?: () => void;
    children: (props: {
        onClick: () => void;
        status: ActivityStatus;
        resetStatus: () => void;
        anySuccess: boolean;
    }) => JSX.Element;
}> = ({ taskDefinitionKey, processId, children }) => {
    const [status, setStatus] = useState<ActivityStatus>({ _type: 'initial' });
    const [anySuccess, setAnySuccess] = useState(false);
    const dispatch = useDispatch();
    const resetStatus = useCallback(() => {
        setStatus({ _type: 'initial' });
    }, [setStatus]);
    const onClick = () => {
        setStatus({ _type: 'pending' });
        dispatch(disableFormsaveNotifierInc());
        dispatch(
            createTask(
                {
                    taskDefinitionKey,
                    processInstanceId: processId,
                },
                (response) => {
                    setAnySuccess(true);
                    setStatus({
                        _type: 'success',
                    });
                    if (response.processInstanceId) {
                        setTimeout(() => {
                            dispatch(push(`/processes/${response.processInstanceId}/tasks/${response.id}/start`));
                            setImmediate(() => {
                                dispatch(disableFormsaveNotifierDec());
                            });
                        }, 200);
                    } else {
                        setTimeout(() => {
                            dispatch(push(`/tasks/${response.id}`));
                            setImmediate(() => {
                                dispatch(disableFormsaveNotifierDec());
                            });
                        }, 200);
                    }
                },
                {
                    '*': () => {
                        setStatus({
                            _type: 'error',
                            errorMessage: 'An error occurred.',
                        });
                        dispatch(disableFormsaveNotifierDec());
                    },
                },
            ),
        );
    };
    return children({
        onClick,
        status,
        resetStatus,
        anySuccess,
    });
};

// We only need one of these!
const linkedEntityIsDirtySelector = createSelector(
    (state: RootState) => state.form['record-form']?.registeredFields,
    (state: RootState) => state.form['record-form']?.initial,
    (state: RootState) => state.form['record-form']?.values,
    (registeredFields, initial, values) => {
        if (!registeredFields) {
            return false;
        }
        return !Object.keys(registeredFields).every((k) => {
            return deepEql(get(initial, k) ?? null, get(values, k) ?? null);
        });
    },
);

const ConfirmIfLinkedEntityDirty = ({
    children,
    onClick,
}: {
    onClick: () => void;
    children: (props: { onClick: () => void }) => JSX.Element;
}) => {
    const linkedEntityIsDirty = useAppSelector(linkedEntityIsDirtySelector);
    const [dialogOpen, setDialogOpen] = useState(false);
    const handleClick = useCallback(() => {
        if (!linkedEntityIsDirty) {
            onClick();
            return;
        }
        setDialogOpen(true);
    }, [linkedEntityIsDirty, onClick]);
    return (
        <>
            <Dialog open={dialogOpen}>
                <DialogTitle>You have unsaved changes</DialogTitle>
                <DialogContent>Would you like to continue anyways?</DialogContent>
                <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="contained" onClick={() => setDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={onClick}>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
            {children({
                onClick: handleClick,
            })}
        </>
    );
};

const MaybeCallCbAfterTimeIfStillMounted = ({ start, cb, time }: { cb: () => void; time: number; start: boolean }) => {
    const prevStartRef = useRef(start);
    useEffect(() => {
        if (!prevStartRef.current && start) {
            // changed to 'true'
            const to = setTimeout(cb, time);
            return () => clearTimeout(to);
        }
        prevStartRef.current = start;
    }, [start, cb, time]);
    return null;
};

const Activities = ({ processId, refresh }: { processId: string; refresh?: () => void }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(processActivityEventCreator.getProcessActivities(processId));
    }, [processId, dispatch]);

    const activitiesSelector = useMemo(createActivitiesSelector, []);
    const activities = useAppSelector((state: RootState) => activitiesSelector(state, { processId }));

    if ((activities.status === 'pending' && !activities.prevData) || activities.status === 'initial') {
        return <CircularProgress size={24} />;
    }
    if (activities.status === 'error' && !activities.prevData) {
        const error = activities.error;
        const msg = error instanceof Error ? error.message : error.body || error.status;
        return (
            <Tooltip title={`Ad-hoc Tasks not available${msg ? `: ${msg}` : ''}`}>
                <ErrorIcon />
            </Tooltip>
        );
    }
    const activityData = activities.status === 'success' ? activities.data : activities.prevData;
    if (activityData.length === 0) {
        //  No adhoc tasks found
        return null;
    }
    return (
        <div style={{ marginRight: '-.5em', textAlign: 'right' }}>
            {activityData.map((activity) => {
                return (
                    <Activity onReset={refresh} processId={processId} taskDefinitionKey={activity.id}>
                        {({ onClick, status, resetStatus, anySuccess }) => (
                            <>
                                <MaybeCallCbAfterTimeIfStillMounted
                                    time={1000}
                                    cb={resetStatus}
                                    start={status._type === 'success'}
                                />
                                <ConfirmIfLinkedEntityDirty onClick={onClick}>
                                    {({ onClick }) => (
                                        <Button
                                            style={{ marginRight: '.5em', marginBottom: '.5em' }}
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            key={activity.id}
                                            onClick={onClick}
                                            disabled={status._type === 'pending' || status._type === 'success'}
                                            endIcon={
                                                status._type === 'error' ? (
                                                    <Tooltip title={status.errorMessage}>
                                                        <ErrorIcon color="error" />
                                                    </Tooltip>
                                                ) : status._type === 'pending' ? (
                                                    <CircularProgress size={14} />
                                                ) : status._type === 'success' ? (
                                                    <Check color="primary" />
                                                ) : anySuccess ? (
                                                    <Check color="inherit" />
                                                ) : undefined
                                            }
                                        >
                                            <EvaluatedFormattedMessage message={activity.name} />
                                        </Button>
                                    )}
                                </ConfirmIfLinkedEntityDirty>
                            </>
                        )}
                    </Activity>
                );
            })}
        </div>
    );
};

export const useListActivitiesInline = (processId: string) => {
    const conf = useGetProcessConf(processId);
    const listActivitiesInlineConfig = conf.isSome() ? conf.value?.listActivitiesInline : false;
    return useSingleKeyCachingExpression(listActivitiesInlineConfig, {}, false);
};

const AllActivitiesArea = ({ processId, refresh }: { processId: string; refresh?: () => void }) => {
    const processIsOpen = useProcessIsOpen(processId);
    const show = useListActivitiesInline(processId);
    if (!processIsOpen || !show) {
        return null;
    }
    return <Activities processId={processId} refresh={refresh} />;
};

export default AllActivitiesArea;
