import { isFieldViewField, getDataTypeForFieldExpr, getAllFieldsFromView } from '../../../utils/viewConfigUtils/index';
import ViewConfig, { ViewField } from '../../../../../reducers/ViewConfigType';
import produce from 'immer';
import set from 'lodash/set';
import get from 'lodash/get';

export const forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions =
    (viewConfig: ViewConfig, fieldDefinitions: ViewField[]) => (values: Record<string, unknown>) => {
        const booleanFields = fieldDefinitions.flatMap((f) => {
            if (
                isFieldViewField(f) &&
                getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'BOOLEAN' &&
                f.widgetType !== 'NULLABLE_BOOLEAN'
            ) {
                return [f.field];
            }
            return [];
        });
        const arrayFields = fieldDefinitions.flatMap((f) => {
            if (
                isFieldViewField(f) &&
                f.widgetType !== 'INLINE_MANY' &&
                (getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'VALUESETMANY' ||
                    getDataTypeForFieldExpr(viewConfig, f.entity, f.field, 'POP_LAST') === 'REFMANYMANY')
            ) {
                // Sometimes we expand the *Ids, but not the main data.
                // So let's just focus on *Ids
                return [f.field + 'Ids'];
            }
            return [];
        });
        const result = produce(values, (draftValues) => {
            booleanFields.forEach((fexp) => {
                set(draftValues, fexp, Boolean(get(values, fexp)));
            });
            arrayFields.forEach((fexp) => {
                if (!get(values, fexp)) {
                    set(draftValues, fexp, []);
                }
            });
            return draftValues;
        });
        return result;
    };

const forceBooleanFieldsBooleanAndArrayFieldsArrays = (viewConfig: ViewConfig, viewName: string) => (values: {}) => {
    return forceBooleanFieldsBooleanAndArrayFieldsArraysForFieldDefinitions(
        viewConfig,
        getAllFieldsFromView(viewConfig, viewName),
    )(values);
};
export default forceBooleanFieldsBooleanAndArrayFieldsArrays;
