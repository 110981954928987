import React, { FunctionComponent } from 'react';
import UniversalViewWizard, {
    UniversalViewWizardAction,
    UniversalViewWizardState,
    UniversalViewWizardProps,
} from 'layout-editor/UniversalViewWizard';
import Step1 from 'layout-editor/UniversalViewWizard/steps/Step1';
import Step2CreateEditShow from 'layout-editor/UniversalViewWizard/steps/Step2CreateEditShow';
import Step2List from 'layout-editor/UniversalViewWizard/steps/Step2List';
import Step2Match from 'layout-editor/UniversalViewWizard/steps/Step2Match';
import Step2Merge from 'layout-editor/UniversalViewWizard/steps/Step2Merge';
import Step3CreateEditShow from 'layout-editor/UniversalViewWizard/steps/Step3CreateEditShow';
import Step3List from 'layout-editor/UniversalViewWizard/steps/Step3List';
import Step3Match from 'layout-editor/UniversalViewWizard/steps/Step3Match';
import Step3Merge from 'layout-editor/UniversalViewWizard/steps/Step3Merge';
import Step4List from 'layout-editor/UniversalViewWizard/steps/Step4List';
import Result from 'layout-editor/UniversalViewWizard/steps/Result';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import WithErrorBoundary from 'components/generics/fields/WithErrorBoundary';
import { ViewDefWizardProps } from './ViewDefWizardProps';
import Step3Split from 'layout-editor/UniversalViewWizard/steps/Step3Split';

interface BreadCrumbNavProps {
    steps: string[];
    currStep: UniversalViewWizardState['stepState']['step'];
    dispatch: (action: UniversalViewWizardAction) => void;
}
const BreadCrumbNav: FunctionComponent<BreadCrumbNavProps> = (props) => {
    const { currStep, steps, dispatch } = props;
    return (
        <Breadcrumbs aria-label="breadcrumb">
            {steps.slice(0, typeof currStep === 'number' ? currStep - 1 : -1).map((stepName, i) => {
                return (
                    <Link
                        key={i}
                        color="inherit"
                        href="#"
                        onClick={() =>
                            dispatch({
                                type: 'to',
                                step: (i + 1) as 1 | 2 | 3 | 4,
                            })
                        }
                    >
                        {stepName}
                    </Link>
                );
            })}
            <Typography color="textPrimary">
                {typeof currStep === 'number' ? steps[currStep - 1] : steps[steps.length - 1]}
            </Typography>
        </Breadcrumbs>
    );
};

const ViewDefWizard: FunctionComponent<ViewDefWizardProps> = ({ initialValues, onSubmit, mode = 'CREATE_NEW' }) => {
    const renderSteps: UniversalViewWizardProps['children'] = ({ state, dispatch }) => {
        const handleSubmit = ({ type, payload }) => {
            if (type === 'replace') {
                dispatch({ type: 'next', viewData: payload });
            } else {
                dispatch({
                    type: 'next',
                    viewData: { ...state.view, ...payload },
                });
            }
        };
        const elem = (() => {
            switch (state.step) {
                case 1: {
                    return <Step1 initialValues={state.view} onSubmit={handleSubmit} />;
                }
                case 2: {
                    if (
                        state.type === 'CREATE' ||
                        state.type === 'EDIT' ||
                        state.type === 'SHOW' ||
                        state.type === 'COMPONENT'
                    ) {
                        return <Step2CreateEditShow initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'LIST') {
                        return <Step2List initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'MATCH') {
                        return <Step2Match initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'MERGE' || state.type === 'SPLIT') {
                        return <Step2Merge initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    return null;
                }
                case 3: {
                    if (state.type === 'CREATE' || state.type === 'EDIT' || state.type === 'SHOW') {
                        return <Step3CreateEditShow initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'LIST') {
                        return <Step3List initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'MATCH') {
                        return <Step3Match initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'SPLIT') {
                        return <Step3Split initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    if (state.type === 'MERGE') {
                        return <Step3Merge initialValues={state.view} onSubmit={handleSubmit} />;
                    }
                    return null;
                }
                case 4: {
                    if (state.type === 'LIST') {
                        return (
                            <Step4List
                                initialValues={state.view}
                                onSubmit={() =>
                                    dispatch({
                                        type: 'next',
                                        viewData: state.view,
                                    })
                                }
                            />
                        );
                    }
                    return null;
                }
                case 'review': {
                    if (mode === 'CREATE_NEW') {
                        return <Result diffFrom="entityview if exists" mode="POST" initialValues={state.view} />;
                    }
                    return (
                        <Result
                            prevViewName={initialValues.name}
                            diffFrom="viewDef if exists"
                            mode="PUT"
                            initialValues={state.view}
                        />
                    );
                }
                default:
                    return null;
            }
        })();
        return (
            <div>
                <BreadCrumbNav
                    steps={
                        state.type === 'LIST'
                            ? [
                                  'Step 1: Basic View Information',
                                  'Step 2: Edit Columns and Search',
                                  'Step 3: Configure',
                                  'Step 4: Review',
                                  'Result',
                              ]
                            : state.type === 'MATCH' || state.type === 'MERGE'
                            ? [
                                  'Step 1: Basic View Information',
                                  'Step 2: Edit Columns',
                                  'Step 3: Review with data',
                                  'Result',
                              ]
                            : [
                                  'Step 1: Basic View Information',
                                  'Step 2: Add Page Elements',
                                  'Step 3: Configure',
                                  'Result',
                              ]
                    }
                    currStep={state.step}
                    dispatch={dispatch}
                />
                <WithErrorBoundary>{elem}</WithErrorBoundary>
            </div>
        );
    };
    return <UniversalViewWizard view={initialValues}>{renderSteps}</UniversalViewWizard>;
};

export default ViewDefWizard;
