import React, { createContext, useCallback, useContext, useMemo, useReducer } from 'react';

interface RefreshableContext {
    refresh: (tag: string) => void;
    wasRefreshed: {
        [tag: string]: boolean;
    };
}
export const refreshableContext = createContext<RefreshableContext>({
    refresh: (tag: string) => {},
    wasRefreshed: {},
});

const RefreshableProvider: React.FC<{
    tag: string;
    children: (ctxt: { refresh: () => void; thisWasRefreshed: boolean }) => JSX.Element;
}> = ({ tag, children }) => {
    const { refresh: outerRefresh, wasRefreshed: outerWasRefreshed } = useContext(refreshableContext);
    const INITIAL = 1;
    const [key, _refresh] = useReducer((state) => state + 1, INITIAL);
    const refresh = useCallback(
        (_tag: string) => {
            if (_tag !== tag) {
                outerRefresh(_tag);
                return;
            }
            setTimeout(_refresh, 0);
        },
        [tag, outerRefresh],
    );
    const thisWasRefreshed = key !== INITIAL;
    const wasRefreshed = useMemo(() => {
        return {
            ...outerWasRefreshed,
            [tag]: thisWasRefreshed,
        };
    }, [tag, thisWasRefreshed, outerWasRefreshed]);

    const ctxt: RefreshableContext = useMemo(() => {
        return {
            refresh,
            wasRefreshed,
        };
    }, [refresh, wasRefreshed]);
    const refreshThis = useCallback(() => refresh(tag), [tag, refresh]);
    return (
        <refreshableContext.Provider key={key} value={ctxt}>
            {children({ refresh: refreshThis, thisWasRefreshed })}
        </refreshableContext.Provider>
    );
};
export default RefreshableProvider;
