import React, { useMemo } from 'react';
import debounce from 'lodash/debounce';
import { WrappedFieldInputProps, EventOrValueHandler } from 'redux-form';
import { TextFieldProps, TextField } from '@material-ui/core';
import { flushSync } from 'react-dom';
import { Cancelable } from 'lodash';

export const isEvent = (candidate: any): candidate is React.ChangeEvent<HTMLInputElement> =>
    !!(candidate && candidate.stopPropagation && candidate.preventDefault);

/*
    add as needed.
*/
type BaseValueType = string | number | null;

export const getValue = <ValueType extends BaseValueType>(
    eventOrValue: React.ChangeEvent<HTMLInputElement> | ValueType,
    normalize?: (value: ValueType) => ValueType,
): ValueType => {
    const value = isEvent(eventOrValue) ? (eventOrValue.target.value as unknown as ValueType) : eventOrValue;
    if (normalize) {
        return normalize(value);
    }
    return value;
};

export interface TextFieldInputProps<ValueType> {
    onBlur: EventOrValueHandler<React.ChangeEvent<HTMLInputElement>>;
    onChange: EventOrValueHandler<React.ChangeEvent<HTMLInputElement>>;
    value: ValueType;
}
export interface TextInputProps<ValueType> {
    emptyInitialValue: ValueType;
    debounceTime?: number;
    /*
        normalizeState only normalizes the internally held value - it doesn't change the value passed to onChange/onBlur
        (we pass the original Event object or value instead.)
        So if normalizeState is passed, that same function should generally be applied outside the component as well,
        to the values propagated in onChange/onBlur before being stored in our form state.
    */
    normalizeState?: (value: ValueType) => ValueType;
    input?: TextFieldInputProps<ValueType>;
    renderInput: (arg: {
        value: ValueType;
        onBlur: WrappedFieldInputProps['onBlur'];
        onChange: WrappedFieldInputProps['onChange'];
    }) => JSX.Element;
}

interface TextInputState<ValueType> {
    text: ValueType;
    debounceInProgress: boolean;
    lastPropagatedValue: ValueType;
    abortDebounce: boolean;
    prevInputValue: ValueType;
}
const stateUpdate = {
    completeDebounce:
        <ValueType extends BaseValueType>(value: ValueType) =>
        (state: TextInputState<ValueType>): TextInputState<ValueType> => ({
            ...state,
            text: value,
            debounceInProgress: false,
            lastPropagatedValue: value,
        }),
    loadExternalValue: <ValueType extends BaseValueType>(
        state: TextInputState<ValueType>,
        value,
    ): TextInputState<ValueType> => ({
        ...state,
        text: value,
        lastPropagatedValue: value,
        abortDebounce: true,
        prevInputValue: value,
    }),
    changeImmediateText:
        <ValueType extends BaseValueType>(value: ValueType, prevInputValue: ValueType) =>
        (state: TextInputState<ValueType>): TextInputState<ValueType> => ({
            ...state,
            text: value,
            debounceInProgress: true,
            abortDebounce: false,
            prevInputValue,
        }),
    bypassDebounce:
        <ValueType extends BaseValueType>(value: ValueType, prevInputValue: ValueType) =>
        (state: TextInputState<ValueType>): TextInputState<ValueType> => ({
            ...state,
            text: value,
            debounceInProgress: false,
            lastPropagatedValue: value,
            abortDebounce: true,
            prevInputValue,
        }),
};
export class TextInput<ValueType extends BaseValueType> extends React.Component<
    TextInputProps<ValueType>,
    TextInputState<ValueType>
> {
    state: TextInputState<ValueType> = {
        text: this.props.emptyInitialValue,
        debounceInProgress: false,
        lastPropagatedValue: this.props.emptyInitialValue,
        abortDebounce: false,
        prevInputValue: this.props.emptyInitialValue,
    };
    debouncedOnChange: ((e: React.ChangeEvent<HTMLInputElement> | ValueType) => void) & Cancelable;
    static getDerivedStateFromProps<ValueType extends BaseValueType>(
        { input: { value } }: TextInputProps<ValueType>,
        state: TextInputState<ValueType>,
    ) {
        if (value !== state.lastPropagatedValue && value !== state.prevInputValue) {
            return stateUpdate.loadExternalValue(state, value);
        }
        return null;
    }
    constructor(props: TextInputProps<ValueType>) {
        super(props);
        this.debouncedOnChange = debounce((e: React.ChangeEvent<HTMLInputElement> | ValueType) => {
            if (this.state.abortDebounce) {
                return;
            }
            const value = getValue(e, this.props.normalizeState);
            // We flushSync to prevent React from batching our state updates. If they all happen at once, the getDerivedStateFromProps
            // won't be able to tell if the external value changed, or we changed internally, due to the check:
            // value !== state.lastPropagatedValue && value !== state.prevInputValue
            // So we have to make then happen one at a time.
            flushSync(() => {
                this.setState(stateUpdate.completeDebounce(value));
                this.props.input.onChange(e);
            });
            this.setState((state) => {
                // Ensure we set prevInputValue to what the external value is.
                return { ...state, prevInputValue: value };
            });
        }, this.props.debounceTime || 250);
    }
    componentWillUnmount(): void {
        this.debouncedOnChange?.cancel?.();
    }
    onChange = (e: React.ChangeEvent<HTMLInputElement> | ValueType) => {
        if (isEvent(e)) {
            e.persist();
        }
        const value = getValue(e, this.props.normalizeState);
        this.setState(stateUpdate.changeImmediateText(value, this.props.input.value), () => {
            this.debouncedOnChange(e);
        });
    };
    onBlur = (e: React.ChangeEvent<HTMLInputElement> | ValueType) => {
        if (isEvent(e)) {
            e.persist();
        }
        (this.debouncedOnChange as any).cancel();
        const value = getValue(e, this.props.normalizeState);
        this.setState(stateUpdate.bypassDebounce(value, this.props.input.value), () => {
            this.props.input.onChange(e);
            this.props.input.onBlur(e);
            // we need to update the prevInputValue to what's outside, otherwise it will stick around and never get updated.
            this.setState((state) => ({ ...state, prevInputValue: value }));
        });
    };
    render() {
        return this.props.renderInput({
            onBlur: this.onBlur,
            onChange: this.onChange,
            value: this.state.text,
        });
    }
}
export default TextInput;

export const DebouncedField = React.forwardRef<HTMLInputElement, TextFieldProps & { debounceTime?: number }>(
    (props, ref) => {
        const { onChange, onBlur, value, debounceTime } = props;
        const input = useMemo(() => {
            return {
                onChange,
                onBlur,
                value,
            };
        }, [onChange, onBlur, value]);
        return (
            <TextInput
                debounceTime={debounceTime}
                emptyInitialValue=""
                input={input as any}
                renderInput={(args) => <TextField {...props} {...args} ref={ref} />}
            />
        );
    },
);
