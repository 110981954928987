export const calculateElementHeight = (element: HTMLElement, includeChildren: boolean): number => {
    let totalHeight = 0;
    if (includeChildren) {
        totalHeight += element?.offsetHeight;
        totalHeight += Array.from(element?.children).reduce(
            (acc, child) => acc + (child as HTMLElement)?.offsetHeight,
            0,
        );
    } else {
        totalHeight += (element.firstChild as HTMLDivElement).offsetHeight;
    }
    return Math.ceil(totalHeight / 72) || 1;
};
