import { MapType, StaticType, Types } from 'ace-editor/util/evalStatically/evalStatically';
import { assignIntrinsicType, figureName, filterFind } from './utils';
import { ReflectionKind } from 'typedoc/dist/lib/models/reflections/kind';
import { ContainerReflection, DeclarationReflection } from 'typedoc/dist/lib/serialization/schema';
import { handleArrayType, handleReferenceType } from './handleTypes';

const processNodeChildren = (
    obj: DeclarationReflection,
    fullDoc: ContainerReflection,
    mapNodeToType: (nextNode: DeclarationReflection, parentClass?) => StaticType,
) => {
    const staticValue = {};
    obj.children?.forEach((child) => {
        const nextNode = filterFind(fullDoc, (node) => node.id === child.id);
        if (nextNode) {
            const methodName: string = figureName(child);
            staticValue[methodName] = mapNodeToType(nextNode, obj);
        }
        if (!nextNode) {
            console.warn(`No node found in :${child}`);
        }
    });
    return staticValue;
};

export const typedocToStaticType = (fullDoc: ContainerReflection, startNode: DeclarationReflection): StaticType => {
    const memo: { [key: string]: StaticType } = {};
    const mapNodeToType: (obj: DeclarationReflection, parentClass?) => StaticType = (
        obj: DeclarationReflection,
        parentClass,
    ) => {
        switch (obj.kind) {
            case ReflectionKind.TypeLiteral:
                memo[obj.id] = Types.map(false, {});
                (memo[obj.id] as MapType).__className = obj.name;

                const staticValue = processNodeChildren(obj, fullDoc, mapNodeToType);
                (memo[obj.id] as MapType).staticValue = staticValue;

                return memo[obj.id];

            case ReflectionKind.Class: {
                memo[obj.id] = Types.map(false, {});
                (memo[obj.id] as MapType).__className = obj.name;
                let typeParameters = {};
                if (obj.typeParameters) {
                    obj.typeParameters.forEach((type) => {
                        typeParameters[type.name] = Types.unknown;
                    });
                }
                if (Object.keys(typeParameters).length > 0) {
                    (memo[obj.id] as MapType).parameters = typeParameters;
                }
                const staticValue = processNodeChildren(obj, fullDoc, mapNodeToType);
                (memo[obj.id] as MapType).staticValue = staticValue;

                return memo[obj.id];
            }
            case ReflectionKind.Method:
            case ReflectionKind.Property: {
                let objType = obj.signatures?.[0].type ?? obj.type;
                if (objType?.type === 'intrinsic') {
                    return assignIntrinsicType(obj);
                }
                if (objType?.type === 'array' && 'elementType' in objType) {
                    return handleArrayType(objType, obj, memo, fullDoc, mapNodeToType, parentClass);
                }
                if (objType?.type === 'reference') {
                    return handleReferenceType(objType, obj, memo, fullDoc, mapNodeToType, parentClass);
                }
                if (objType?.type === 'query') {
                    return handleReferenceType(objType, obj, memo, fullDoc, mapNodeToType, parentClass);
                }

                throw new Error('Expected numerical id with reference on typedoc property node.');
            }
            default: {
                throw new Error('Unexpected Reflection Kind');
            }
        }
    };
    return mapNodeToType(startNode);
};
