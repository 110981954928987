import { Card, Divider } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import prismTheme from 'react-syntax-highlighter/dist/cjs/styles/prism/darcula';
import { ContainerReflection } from 'typedoc';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { pi } from './docs';
import { DeclarationReflection } from 'typedoc';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

export const findFunctionOrMethodByName = (
    item: DeclarationReflection,
    functionName: string,
): DeclarationReflection => {
    if (item.name === functionName && (item.kindString === 'Function' || item.kindString === 'Method')) {
        return item;
    }
    return (
        item.children?.reduce((prev, subitem) => prev || findFunctionOrMethodByName(subitem, functionName), null) ??
        null
    );
};

export interface FunctionDocProps {
    functionName: string;
    docJson?: ContainerReflection;
}
const FunctionTypeDoc: React.FC<FunctionDocProps> = ({ functionName, docJson = pi }) => {
    const item = React.useMemo(() => {
        return findFunctionOrMethodByName(docJson as unknown as DeclarationReflection, functionName);
    }, [functionName, docJson]);

    if (!item) {
        return null;
    }

    const getSeverity = (tag: string): 'info' | 'error' | 'warning' => {
        switch (tag) {
            case '@deprecated':
                return 'error';
            case '@warning':
                return 'warning';
            case '@info':
                return 'info';
            default:
                return null;
        }
    };
    const renderBlockTags = (
        blockTags: ContainerReflection['children'][number]['signatures'][number]['comment']['blockTags'],
    ) => {
        const DepsAndWarns = blockTags.flatMap((tag, index) => {
            const severity = getSeverity(tag.tag);
            if (!severity) {
                return [];
            }
            const contentText = tag.content.map((content) => content.text).join(' ');
            return [
                <Alert
                    key={`${tag.tag}-${index}`}
                    severity={severity}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                >
                    <Typography variant="body2">
                        <strong>{tag.tag.replace('@', '')}</strong>: {contentText}
                    </Typography>
                </Alert>,
            ];
        });
        return DepsAndWarns.length > 0 ? (
            <>
                {DepsAndWarns}
                <Divider />
            </>
        ) : null;
    };

    const renderParameterType = (
        type: ContainerReflection['children'][number]['signatures'][number]['parameters'][number]['type'],
        isRest = false,
    ): [JSX.Element, string] => {
        switch (type.type) {
            case 'reference':
                return [<em>{type.name}</em>, type.name];
            case 'array': {
                if (!isRest) {
                    const [jsxRep, stringRep] = renderParameterType(type.elementType);
                    return [<span>({jsxRep})[];</span>, `(${stringRep})[]`];
                }
            }
            // eslint-disable-next-line no-fallthrough
            case 'rest': {
                const [jsxRep, stringRep] = renderParameterType(type.elementType);
                const needsParens = stringRep.includes(' ');

                return [
                    <>...{needsParens ? <>({jsxRep})</> : jsxRep}[]</>,
                    `...${needsParens ? '(' + stringRep + ')' : stringRep}[]`,
                ];
            }
            case 'literal':
                return [<em>{'' + type.value}</em>, '' + type.value];
            case 'intersection':
            case 'union':
                const sep = type.type === 'intersection' ? ' & ' : ' | ';
                return [
                    <>
                        {type.types.map((t, i, arr) => {
                            const [jsxRep, stringRep] = renderParameterType(t);
                            const isLast = i === arr.length - 1;
                            return (
                                <React.Fragment key={i}>
                                    {stringRep.includes(' ') ? <>({jsxRep})</> : jsxRep}
                                    {!isLast ? sep : null}
                                </React.Fragment>
                            );
                        })}
                    </>,
                    type.types
                        .map((t) => renderParameterType(t)[1])
                        .map((e) => (e.includes(' ') ? `(${e})` : e))
                        .join(sep),
                ];
            case 'intrinsic':
                return [<em>{'' + type.name}</em>, '' + type.name];
            default:
                const msg = 'TODO: render this parameter type: \n' + JSON.stringify(type, null, 1);
                return [<span>{msg}</span>, msg];
        }
    };
    const renderSignature = (signature: ContainerReflection['children'][number]['signatures'][number]) => {
        const renderCommonDisplayPart = (
            c: ContainerReflection['children'][number]['signatures'][number]['comment']['summary'][number],
        ) => {
            switch (c.kind) {
                case 'code': {
                    return (
                        <ReactMarkdown
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '');

                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            style={prismTheme}
                                            PreTag="div"
                                            language={match[1]}
                                            children={String(children).replace(/\n$/, '')}
                                            {...props}
                                        />
                                    ) : (
                                        <code className={className ? className : ''} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                            }}
                        >
                            {c.text}
                        </ReactMarkdown>
                    );
                }
                case 'text': {
                    return <ReactMarkdown>{c.text}</ReactMarkdown>;
                }
                case 'inline-tag': {
                    return <span> TODO: unimplemented inline tag.</span>;
                }
                default:
                    return 'unimplemented tag:\n' + JSON.stringify(c, null, 1);
            }
        };
        const returns: JSX.Element[] = [];
        const examples: JSX.Element[] = [];
        const throws: JSX.Element[] = [];
        const unknown: JSX.Element[] = [];
        signature?.comment?.blockTags?.forEach((t, i) => {
            const content = t.content.map((c, ix) => (
                <span key={t.tag + ':' + i + ':' + ix}>{renderCommonDisplayPart(c)}</span>
            ));
            content.forEach((c) => {
                const bucket =
                    t.tag === '@returns'
                        ? returns
                        : t.tag === '@example'
                        ? examples
                        : t.tag === '@throws'
                        ? throws
                        : unknown;
                bucket.push(c);
            });
        });
        return (
            <Card style={{ padding: '1rem' }}>
                <h3>{signature.name}</h3>
                <Divider />
                {signature?.comment?.blockTags && renderBlockTags(signature.comment.blockTags)}
                <SyntaxHighlighter
                    style={prismTheme}
                    PreTag="div"
                    language="typescript"
                    children={`${signature.name}(${
                        signature.parameters
                            ?.map(
                                (param, i, arr) =>
                                    `${arr.length > 0 ? '\n\t' : ''}${param.name}: ${
                                        renderParameterType(param.type, param.flags.isRest)[1]
                                    }${arr.length > 0 && i === arr.length - 1 ? '\n' : ''}`,
                            )
                            ?.join(', ') ?? ''
                    }): ${renderParameterType(signature.type)[1]}`}
                />
                <code style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
                    {signature.name}(
                    {signature.parameters?.map((param, i, arr) => (
                        <span key={i}>
                            {param.name}: {renderParameterType(param.type, param.flags.isRest)[0]}
                            {i !== arr.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                    ): {renderParameterType(signature.type)[0]}
                </code>
                <Divider />
                {signature?.comment?.summary?.reduce((prev, curr, i) => {
                    prev.push(<span key={i}>{renderCommonDisplayPart(curr)}</span>);
                    return prev;
                }, []) ?? null}
                <b>Parameters</b>
                <ul>
                    {(() => {
                        const params: JSX.Element[] = [];
                        signature.parameters?.forEach((param, i) => {
                            params.push(
                                <li key={i}>
                                    <b>{param.name}</b>: {renderParameterType(param.type, param.flags.isRest)[0]}
                                    <div>
                                        {param.comment?.summary?.reduce((prev, curr, i) => {
                                            prev.push(<span key={i}>{renderCommonDisplayPart(curr)}</span>);
                                            return prev;
                                        }, [])}
                                    </div>
                                </li>,
                            );
                        });
                        return params;
                    })()}
                </ul>
                <div>
                    {returns.length > 0 ? <b>Returns</b> : null}
                    {signature.type ? <>&nbsp;{renderParameterType(signature.type)[0]}</> : null}
                    {returns}
                </div>
                <div>
                    {examples.length > 0 ? <b>Examples</b> : null}
                    {examples}
                </div>
                <div>
                    {throws.length > 0 ? <b>Throws</b> : null}
                    {throws}
                </div>
            </Card>
        );
    };
    return <div>{item.signatures?.map((s, i) => <div key={i}>{renderSignature(s)}</div>) ?? null}</div>;
};
export default FunctionTypeDoc;
