import { ProcessVariableEntry } from 'bpm/components/ProcessDetail/ProcessVariables/getProcessVariables';
import { TaskForm } from '../../../../../reducers/taskFormType';

const fillTaskFormValuesWithProcessVariables = (variables: ProcessVariableEntry[], taskForm: TaskForm) => {
    const variableIdsToValues: {
        [id: string]: any;
    } = (variables ?? []).reduce((prev, curr) => {
        prev[curr.id] = curr.value;
        return prev;
    }, {});

    const fields = taskForm.fields.map((f) => ({ ...f, value: f.value ?? variableIdsToValues[f.id] }));
    return {
        ...taskForm,
        fields,
    };
};

export default fillTaskFormValuesWithProcessVariables;
