import React from 'react';
import { ReactElement, Component, ReactNode } from 'react';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { Card, CardActions, Collapse, CardContent, Tab, Tabs, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Warning from '@material-ui/icons/Warning';
import PriorityHigh from '@material-ui/icons/PriorityHigh';
import Storage from '@material-ui/icons/Storage';
import { withStyles, Theme } from '@material-ui/core/styles';
import toClass from 'recompose/toClass';
import classnames from 'classnames';
import { Subtract } from 'utility-types';
import { connect } from 'react-redux';
// import './rwt.css';
import ResizeObserver from 'resize-observer-polyfill';
import { Tabs as RWTabs, Tab as RWTab, TabPanel as RWTabPanel, TabList as RWTabList } from 'react-web-tabs';
import { getView, viewHasTabs, getTabsTitlesFromView } from '../utils/viewConfigUtils';
import { RootState } from '../../../reducers/rootReducer';
import tabRecentAction from '../../../actions/tabRecent';
import EnhancedRGridWithVis from './EnhancedRGridTabbable';
import BackTo from '../button/BackTo';
import TogglePrintMode from '../../TogglePrintMode';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import Forward from '@material-ui/icons/Forward';
import { Link } from 'react-router-dom';
import { userAgent } from 'userAgent';
import { Cancelable } from 'lodash';
import { layoutModeContext } from 'components/layouts/LayoutMode';
import Themed from 'components/Themed';
import { HideBackToController } from './hooks/configurations/hideBackTo';
import { HidePopupNavController } from './hooks/configurations/hidePopupNavArrow';
import isOffline from 'util/isOffline';
import EditViewInPlaceSwitch from 'layout-editor/editViewInPlaceContext/Switch';
import { expressionTesterOpenContext } from 'expression-tester/hooks/useExpressionTesterOpen';
import { getUseDisablePopupNavigationArrowSelector } from 'util/applicationConfig';

type FormTabsContext = {
    setTab?: (tabName: string) => void;
};
export const formTabsContext = React.createContext<FormTabsContext>({});

const getMyLink =
    ({ entityType, id, isShow }: { entityType: string; id: string; isShow: boolean }) =>
    (props) =>
        <Link to={`/${entityType}/${id}${isShow ? '/show' : ''}`} {...props} />;

type InTabProviderState = (
    | {
          _type: 'ACTIVE_TAB';
          status: 'INITIALIZED';
      }
    | {
          _type: 'NON_ACTIVE_TAB';
          status: 'INITIALIZED' | 'NOT_INITIALIZED';
      }
) & { tabKey: string };
type NotInTabContext = {
    _type: 'NOT_IN_A_TAB';
};
type InTabContext = InTabProviderState;

type TabContext = InTabContext | NotInTabContext;
export const activeTabContext = React.createContext<TabContext>({ _type: 'NOT_IN_A_TAB' });

interface TabProviderProps {
    isActive: boolean;
    tabKey: string;
}

interface TabProviderComponentProps extends TabProviderProps {}
class TabProviderComponent extends React.Component<TabProviderComponentProps, InTabProviderState> {
    static getDerivedStateFromProps(
        nextProps: TabProviderComponentProps,
        prevState: InTabProviderState,
    ): InTabProviderState {
        const tabKey = nextProps.tabKey;
        if (nextProps.isActive && prevState._type !== 'ACTIVE_TAB') {
            return {
                _type: 'ACTIVE_TAB',
                status: 'INITIALIZED',
                tabKey,
            };
        }
        if (!nextProps.isActive && prevState._type === 'ACTIVE_TAB') {
            return {
                _type: 'NON_ACTIVE_TAB',
                status: 'INITIALIZED',
                tabKey,
            };
        }
        if (prevState.tabKey !== nextProps.tabKey) {
            return {
                ...prevState,
                tabKey,
            };
        }
        return null;
    }
    constructor(props: TabProviderComponentProps) {
        super(props);
        this.state = this.props.isActive
            ? {
                  _type: 'ACTIVE_TAB',
                  status: 'INITIALIZED',
                  tabKey: this.props.tabKey,
              }
            : {
                  _type: 'NON_ACTIVE_TAB',
                  status: 'NOT_INITIALIZED',
                  tabKey: this.props.tabKey,
              };
    }

    render() {
        return <activeTabContext.Provider value={this.state}>{this.props.children}</activeTabContext.Provider>;
    }
}
export const TabProvider: React.ComponentType<TabProviderProps> = TabProviderComponent;

const isIos: boolean = userAgent.isIos();
const noScrollHandlers = window['__CASETIVITY_TEST'];

enum TabOptions {
    SECTIONS = 1,
    HORIZONTAL = 2,
    VERTICAL = 3,
}

interface PanesProps {
    paneWidth?: string | number;
    panes: React.ReactNode;
}
const Panes: React.ComponentType<PanesProps> = toClass((props) => (
    <div style={{ height: '100%', width: props.paneWidth || '100%' }}>{props.panes}</div>
));

interface RWTLSprops {
    defaultTab: string | null;
    panes: React.ReactNode;
    tabs: React.ReactNode;
    onChange: Function;
    width: Breakpoint;
    topLocation?: number;
}

export const tabHasError = (tabsWithErrors: string[], tabKey: string) => (tabKey, tabIx) =>
    (tabsWithErrors || []).includes(tabKey) && tabKey !== tabIx;

export class RWTabListS extends Component<RWTLSprops, { windowOffsetTop: number; recalcKey: number }> {
    div: HTMLDivElement | null;
    thisRect: ClientRect | DOMRect;
    tabList: HTMLElement | null = null;
    tabListRect: ClientRect | DOMRect;
    panes: any;
    panesClientHeight: number;
    handleScroll: ((event: any) => void) & Cancelable;
    _isMounted: boolean = false;
    debouncedOnScrollFn: (() => void) & Cancelable;
    _resizeObserver: ResizeObserver;
    state = {
        windowOffsetTop: 0,
        recalcKey: 0,
    };
    componentDidMount() {
        if (!isIos && !noScrollHandlers) {
            this._isMounted = true;
            this.handleScroll = throttle((event) => {
                this.getDomInfo();
                let top = window.pageYOffset || document!.documentElement!.scrollTop;
                if (this._isMounted) {
                    this.setState((state) =>
                        top !== state.windowOffsetTop
                            ? {
                                  windowOffsetTop: top,
                                  ...state,
                              }
                            : state,
                    );
                }
            }, 10);
            window.addEventListener('scroll', this.handleScroll);
            this.getDomInfo();
            this.debouncedOnScrollFn = debounce(() => {
                this.getDomInfo();
                if (this._isMounted) {
                    this.setState((prev) => ({
                        recalcKey: prev.recalcKey + 1,
                        ...prev,
                    }));
                }
            }, 75);
            const bodyElement = document.getElementsByTagName('body')[0];
            this._resizeObserver = new ResizeObserver((entries) => {
                this.debouncedOnScrollFn();
            });

            this._resizeObserver.observe(bodyElement);
        }
    }

    componentWillUnmount() {
        if (!isIos && !noScrollHandlers) {
            this._isMounted = false;
            if (this.debouncedOnScrollFn) {
                this.debouncedOnScrollFn.cancel();
            }
            if (this.handleScroll) {
                this.handleScroll.cancel();
            }
            window.removeEventListener('scroll', this.handleScroll);
            this._resizeObserver.disconnect();
        }
    }

    getDomInfo = () => {
        if (this._isMounted) {
            if (this.div) {
                this.thisRect = this.div.getBoundingClientRect();
            }
            if (this.tabList) {
                this.tabListRect = this.tabList.getBoundingClientRect();
            }
            if (this.panes) {
                this.panesClientHeight = this.panes.clientHeight;
            }
        }
    };
    getTabItemWidth = () => (this.props.width === 'xl' ? 220 : 200);
    getStyle(theme?: Theme) {
        if (this.thisRect) {
            /*
            useful for debugging

            console.log(
                `${this.thisRect.top} < ${this.state.windowOffsetTop} &&
                ${this.tabListRect.height} < ${window.innerHeight} &&
                ${this.thisRect.top} + ${this.panesClientHeight} >
                ${this.state.windowOffsetTop} + ${window.innerHeight}
                `
            );
            */

            const { topLocation = 0 } = this.props;
            const practicalTopOffset = this.state.windowOffsetTop + topLocation;
            const practicalInnerHeight = window.innerHeight - topLocation;
            if (
                this.thisRect.top < practicalTopOffset && // scrolled into
                this.tabListRect.height < practicalInnerHeight - practicalTopOffset && // tab list will fit into the window
                this.thisRect.top + this.panesClientHeight >= practicalTopOffset + practicalInnerHeight
                // ^ bottom of content is on the screen
            ) {
                return {
                    position: 'fixed',
                    top: topLocation,
                    backgroundColor: theme.palette.background.paper,
                    zIndex: 100,
                    margin: 0,
                    width: /* this.tabListRect.width */ this.getTabItemWidth(),
                };
            }
        }
        return { width: this.getTabItemWidth() /* '100%' */ };
    }
    render() {
        const { onChange, panes, tabs, defaultTab } = this.props;
        return (
            <Themed>
                {({ theme }) => (
                    <div
                        ref={(ref) => {
                            this.div = ref;
                        }}
                        style={{ width: '100%' }}
                    >
                        <RWTabs defaultTab={defaultTab} vertical={true} onChange={onChange}>
                            <div
                                style={
                                    this.tabListRect && this.getStyle(theme).position === 'fixed'
                                        ? {
                                              minWidth: this.tabListRect.width,
                                              height: this.tabListRect.height,
                                          }
                                        : {}
                                }
                            >
                                <div
                                    ref={(ref) => {
                                        this.tabList = ref;
                                    }}
                                >
                                    <RWTabList style={this.getStyle(theme)} children={tabs} />
                                </div>
                            </div>
                            <div ref={(ref) => (this.panes = ref)} style={{ width: '100%' }}>
                                <Panes panes={panes} />
                            </div>
                        </RWTabs>
                    </div>
                )}
            </Themed>
        );
    }
}

const styles = (theme) => ({
    actions: {
        cursor: 'pointer',
        display: 'flex',
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
    },
    blueish: {
        backgroundColor: 'rgba(13, 71, 161, 0.1)',
    },
    warning: {
        color: theme.palette.warning.main,
    },
    error: {
        color: theme.palette.error.main,
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        marginLeft: 'auto',
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});
interface TabbableFormProps {
    isPopover?: boolean;
    recentTabResource: RootState['recentTab']['resource'];
    disablePopupNavigationArrow?: boolean;
    recentTabKey: RootState['recentTab']['tabKey'];
    recentTabRecordId: RootState['recentTab']['id'];
    classes: any;
    title: string | ReactNode;
    actions: ReactElement<{ save: (redirect?: string) => () => void }>;
    viewName: string;
    viewConfig: RootState['viewConfig'];
    toolbar?: ReactElement<{
        handleSubmitWithRedirect: (redirect: string) => Function;
        invalid: boolean;
        submitOnEnter: boolean;
    }>;
    theme: Theme;
    contentContainerStyle?: {};
    useTabs?: boolean;
    baseFields?: ReactElement<{}>[];
    fieldsByTab?: {};
    tabsWithErrors?: string[];
    width: Breakpoint;
    isLoading: boolean;
    printTemplates?: ReactNode;
    key?: number;
    tabRecent: (resource: string | null, viewType: string | null, tabKey: string | null, id: string | null) => void;
    record?: {
        id?: string;
        entityType?: string;
    };
    printMode: boolean;
    entitySubmitsInTaskContext?: RootState['entitySubmitsInTaskContext'];
    renderAbove?: () => JSX.Element;
    disallowClickAwayNavigation?: boolean;
    isShow: boolean;
    embeddedInMultiCard?: boolean;
}
const getResource = (props: TabbableFormProps) => props.viewConfig.views[props.viewName].entity;
const getViewType = (props: TabbableFormProps) => props.viewConfig.views[props.viewName].viewType;

interface TabbableFormState {
    tabKey: string | null | undefined;
    tabs: TabOptions;
}
const getSavedTabKey = (nextProps: TabbableFormProps) => {
    return getResource(nextProps) === nextProps.recentTabResource &&
        nextProps.record &&
        nextProps.record.id === nextProps.recentTabRecordId &&
        nextProps.fieldsByTab &&
        nextProps.recentTabKey &&
        nextProps.fieldsByTab[nextProps.recentTabKey]
        ? nextProps.recentTabKey
        : null;
};
class TabbableFormComponent extends Component<TabbableFormProps, TabbableFormState> {
    static defaultProps = {
        baseFields: [],
        fieldsByTab: {},
        contentContainerStyle: { borderTop: 'solid 1px #e0e0e0' },
        tabsWithErrors: [],
        useTabs: true,
        activeField: null,
    };
    state = {
        tabKey: undefined,
        tabs: TabOptions.VERTICAL,
    } as TabbableFormState;
    static getDerivedStateFromProps(nextProps: TabbableFormProps, prevState: TabbableFormState) {
        const savedTabKey = getSavedTabKey(nextProps);
        if (savedTabKey !== null && prevState.tabKey === null && savedTabKey !== prevState.tabKey) {
            return {
                ...prevState,
                tabKey: savedTabKey,
            };
        }
        return null;
    }
    componentDidMount() {
        this.setState({
            tabKey: null,
        });
    }
    getResource = (props = this.props) => getResource(props);
    getViewType = (props = this.props) => getViewType(props);
    handleChangeTab = (tabKey) => {
        this.setState((state) => (tabKey !== state.tabKey ? { ...state, tabKey } : state));
    };

    componentWillUnmount() {
        if (this.props.fieldsByTab && this.props.record && !this.props.embeddedInMultiCard) {
            this.props.tabRecent(
                this.getResource(),
                this.getViewType() as string,
                this.state.tabKey,
                this.props.record.id || null,
            );
        }
    }
    getIsActiveTab = (tabKey: string, tabIx: number) =>
        this.state.tabKey === tabKey || (tabIx === 0 && !this.state.tabKey);
    render() {
        const {
            contentContainerStyle,
            theme,
            toolbar,
            viewConfig,
            viewName,
            actions,
            fieldsByTab = {},
            width,
            classes,
            isLoading,
            printMode,
            record,
            isPopover,
            entitySubmitsInTaskContext,
            key, // provided to rerender the entire form if necessary (just provide a different key)
            renderAbove,
            disallowClickAwayNavigation,
            disablePopupNavigationArrow,
            isShow,
        } = this.props;
        const view = getView(viewConfig, viewName);
        const pageHasTabs = viewHasTabs(view);
        const tabTitles = pageHasTabs ? Object.keys(fieldsByTab) : [];
        // const MyLink = props => <Link to={`/${record.entityType}/${record.id}${isShow ? '/show' : ''}`} {...props} />;
        const { entityType, id } = record;
        const MyLink = getMyLink({ entityType, id, isShow });
        const CardElem = printMode ? ('div' as const) : Card;
        return (
            <formTabsContext.Provider value={{ setTab: this.handleChangeTab }}>
                {renderAbove?.()}
                <CardElem style={{ opacity: isLoading ? 0.8 : 1, padding: '.5em' }} key={key}>
                    {record &&
                    isPopover &&
                    !isOffline() &&
                    !disallowClickAwayNavigation &&
                    !disablePopupNavigationArrow ? (
                        <HidePopupNavController viewName={viewName}>
                            <IconButton aria-label="Navigate to record" component={MyLink} style={{ float: 'right' }}>
                                <Forward color="primary" />
                            </IconButton>
                        </HidePopupNavController>
                    ) : null}
                    <HideBackToController viewName={viewName}>
                        <div style={{ float: 'right', marginTop: '.5em' }}>
                            <BackTo />
                        </div>
                    </HideBackToController>
                    {isShow && !isPopover && (
                        <div style={{ float: 'right' }}>
                            <TogglePrintMode />
                        </div>
                    )}
                    {!isPopover && (
                        // Hide this in view editing wizard, since it's hardcoded open.
                        <expressionTesterOpenContext.Consumer>
                            {(mode) =>
                                mode === 'OPEN_EXPRESSIONPANEL' ? null : (
                                    <div style={{ float: 'right' }}>
                                        <EditViewInPlaceSwitch />
                                    </div>
                                )
                            }
                        </expressionTesterOpenContext.Consumer>
                    )}
                    <b>{this.props.title}</b>
                    <form
                        autoComplete="off"
                        style={{ marginBottom: '.5em' }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            return false;
                        }}
                    >
                        {!printMode && actions}
                        <div style={{ clear: 'both' }} />
                        <div>
                            <activeTabContext.Provider value={{ _type: 'NOT_IN_A_TAB' }}>
                                <EnhancedRGridWithVis
                                    isShow={isShow}
                                    lastRowDropdownsFlipUp={!pageHasTabs}
                                    fields={this.props.baseFields}
                                />
                            </activeTabContext.Provider>
                        </div>
                    </form>
                    {printMode &&
                        tabTitles.map((tabKey, tabIx) => (
                            <div style={{ breakInside: 'avoid' }} key={tabIx + '_pm'}>
                                <div
                                    style={{
                                        width: '100%',
                                        marginTop: '1em',
                                        fontSize: 20,
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {view.tabs![tabKey].label}
                                </div>
                                <div
                                    style={{
                                        width: '100%',
                                        height: 1,
                                        backgroundColor: 'black',
                                    }}
                                />
                                <EnhancedRGridWithVis
                                    key={printMode ? 'printMode' : 'foo'}
                                    isShow={isShow}
                                    fields={fieldsByTab[tabKey]}
                                    lastRowDropdownsFlipUp={tabIx === tabTitles.length - 1}
                                />
                            </div>
                        ))}
                    {!printMode &&
                        (width === 'xs' || this.state.tabs === TabOptions.SECTIONS) &&
                        tabTitles.map((tabKey, tabIx) => (
                            <div
                                key={tabIx + (printMode ? 'printMode' : '')}
                                style={{
                                    borderTop: tabIx === 0 ? '1px solid rgba(0,0,0,0.4)' : undefined,
                                    borderBottom: '1px solid rgba(0,0,0,0.4)',
                                    padding: 0,
                                }}
                            >
                                <CardActions
                                    className={
                                        printMode ? classes.actions : classnames(classes.actions, classes.blueish)
                                    }
                                    disableSpacing={true}
                                    onClick={() => {
                                        if (!printMode) {
                                            this.handleChangeTab(this.state.tabKey === tabKey ? null : tabKey);
                                        }
                                    }}
                                >
                                    <span
                                        style={{
                                            flex: 1,
                                            paddingLeft: printMode ? undefined : '1em',
                                            paddingBottom: printMode ? undefined : '5px',
                                            fontSize: printMode ? 20 : 18,
                                            fontWeight: 'bold',
                                            ...(tabHasError(this.props.tabsWithErrors, this.state.tabKey)(tabKey, tabIx)
                                                ? { color: theme.palette.error.main }
                                                : {}),
                                            ...(entitySubmitsInTaskContext?.type === 'loaded' &&
                                            entitySubmitsInTaskContext.entries[tabKey]
                                                ? {
                                                      color: theme.palette.warning.main,
                                                  }
                                                : {}),
                                        }}
                                    >
                                        {view.tabs![tabKey].label}
                                    </span>
                                    {!printMode && (
                                        <IconButton
                                            className={classnames(classes.expand, {
                                                [classes.expandOpen]: printMode || this.state.tabKey === tabKey,
                                            })}
                                            aria-expanded={printMode || this.state.tabKey === tabKey}
                                            aria-label="Show more"
                                        >
                                            <ExpandMoreIcon />
                                        </IconButton>
                                    )}
                                </CardActions>
                                <Collapse
                                    in={printMode || this.state.tabKey === tabKey}
                                    timeout={0}
                                    mountOnEnter={true}
                                    unmountOnExit={false}
                                    style={
                                        printMode || this.state.tabKey === tabKey ? { overflow: 'unset' } : undefined
                                    }
                                >
                                    <CardContent style={printMode ? { paddingRight: 0 } : undefined}>
                                        <TabProvider isActive={this.state.tabKey === tabKey} tabKey={tabKey}>
                                            <EnhancedRGridWithVis
                                                key={printMode ? 'printMode' : 'foo'}
                                                isShow={isShow}
                                                fields={fieldsByTab[tabKey]}
                                                lastRowDropdownsFlipUp={tabIx === tabTitles.length - 1}
                                            />
                                        </TabProvider>
                                    </CardContent>
                                </Collapse>
                            </div>
                        ))}
                    {!printMode && width !== 'xs' && this.state.tabs === TabOptions.HORIZONTAL && pageHasTabs && (
                        <Tabs
                            value={this.state.tabKey}
                            style={contentContainerStyle}
                            onChange={(_, value) => this.handleChangeTab(value)}
                        >
                            {tabTitles.map((tabKey, tabIx) => (
                                <Tab
                                    key={`Tab-${tabKey}`}
                                    label={
                                        <span
                                            style={
                                                tabHasError(this.props.tabsWithErrors, this.state.tabKey)(tabKey, tabIx)
                                                    ? { color: theme.palette.error.main }
                                                    : entitySubmitsInTaskContext?.type === 'loaded' &&
                                                      entitySubmitsInTaskContext.entries[tabKey]
                                                    ? {
                                                          color: theme.palette.warning.main,
                                                      }
                                                    : undefined
                                            }
                                        >
                                            {view.tabs![tabKey].label}
                                        </span>
                                    }
                                    value={tabIx}
                                />
                            ))}
                        </Tabs>
                    )}
                    {width !== 'xs' && this.state.tabs === TabOptions.HORIZONTAL && pageHasTabs && (
                        <div className={classes.formStyle}>
                            <EnhancedRGridWithVis
                                isShow={isShow}
                                lastRowDropdownsFlipUp={true}
                                fields={fieldsByTab[getTabsTitlesFromView(view)[this.state.tabKey || 0]]}
                            />
                        </div>
                    )}
                    {!printMode && width !== 'xs' && this.state.tabs === TabOptions.VERTICAL && pageHasTabs && (
                        <layoutModeContext.Consumer>
                            {({ mode }) => (
                                <RWTabListS
                                    // As the tab array changes, it gets react-web-tabs
                                    // into a weird state where a tab can be unmounted but still rendering.
                                    // so lets just remount the whole thing in that case.
                                    key={tabTitles.join(',')}
                                    topLocation={mode === 'new' ? 64 : 0}
                                    defaultTab={this.state.tabKey}
                                    width={width}
                                    onChange={(value) => this.handleChangeTab(value)}
                                    panes={tabTitles.map((tabKey, tabIx) => {
                                        return (
                                            <RWTabPanel
                                                tabId={tabKey}
                                                key={tabIx}
                                                style={{ width: '100%' }}
                                                render={() => {
                                                    const isActiveTab = this.getIsActiveTab(tabKey, tabIx);
                                                    return (
                                                        <TabProvider isActive={isActiveTab} tabKey={tabKey}>
                                                            <div
                                                                className={classes.formStyle}
                                                                style={{ width: '100%' }}
                                                            >
                                                                {view.tabs![tabKey].label && (
                                                                    <h2 id={`header:${tabKey}`}>
                                                                        {view.tabs![tabKey].label}
                                                                    </h2>
                                                                )}
                                                                <EnhancedRGridWithVis
                                                                    // NEW NOTE: We are doing this in the individual problematic fields now.
                                                                    // i.e. LongTextInput needs to be remounted when hidden/displayed for some reason
                                                                    // remount when visible due to display errors.
                                                                    // key={isActiveTab ? 'SELECTED' : 'NOT_SELECTED'}
                                                                    isShow={isShow}
                                                                    lastRowDropdownsFlipUp={true}
                                                                    fields={fieldsByTab[tabKey]}
                                                                />
                                                            </div>
                                                        </TabProvider>
                                                    );
                                                }}
                                            />
                                        );
                                    })}
                                    tabs={tabTitles.map((tabKey, tabIx) => {
                                        const label = view.tabs![tabKey].label;
                                        const hasError = tabHasError(this.props.tabsWithErrors, this.state.tabKey)(
                                            tabKey,
                                            tabIx,
                                        );
                                        const isActiveTab = this.getIsActiveTab(tabKey, tabIx);

                                        const hasPendingOfflineSubmit =
                                            entitySubmitsInTaskContext?.type === 'loaded' &&
                                            entitySubmitsInTaskContext?.entries?.find((e) => e.tabKey === tabKey);
                                        const hasPendingOfflineSubmitConflict =
                                            hasPendingOfflineSubmit?.error === 'conflict';
                                        return (
                                            <RWTab style={{ textAlign: 'left' }} key={tabIx} tabFor={tabKey}>
                                                <span
                                                    style={
                                                        hasPendingOfflineSubmitConflict && !isActiveTab
                                                            ? { color: theme.palette.error.main }
                                                            : hasPendingOfflineSubmit && !isActiveTab
                                                            ? { color: theme.palette.warning.main }
                                                            : hasError
                                                            ? { color: theme.palette.error.main }
                                                            : {
                                                                  color: isActiveTab
                                                                      ? theme.palette.getContrastText(
                                                                            theme.palette.primary.main,
                                                                        )
                                                                      : theme.palette.getContrastText(
                                                                            theme.palette.background.paper,
                                                                        ),
                                                              }
                                                    }
                                                >
                                                    {label}
                                                </span>
                                                {hasError || hasPendingOfflineSubmit ? (
                                                    <span style={{ position: 'relative' }}>
                                                        {hasPendingOfflineSubmitConflict ? (
                                                            <PriorityHigh
                                                                fontSize="small"
                                                                className={!isActiveTab ? classes.error : undefined}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    position: 'absolute',
                                                                    bottom: 0,
                                                                }}
                                                            />
                                                        ) : hasPendingOfflineSubmit ? (
                                                            <Storage
                                                                titleAccess="Includes pending offline submissions"
                                                                fontSize="small"
                                                                className={!isActiveTab ? classes.warning : undefined}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    position: 'absolute',
                                                                    bottom: 0,
                                                                }}
                                                            />
                                                        ) : (
                                                            <Warning
                                                                fontSize="small"
                                                                className={hasError ? classes.error : undefined}
                                                                style={{
                                                                    marginLeft: 10,
                                                                    position: 'absolute',
                                                                    bottom: 0,
                                                                }}
                                                            />
                                                        )}
                                                    </span>
                                                ) : null}
                                            </RWTab>
                                        );
                                    })}
                                />
                            )}
                        </layoutModeContext.Consumer>
                    )}
                    <div style={{ height: '1em' }} />
                    {toolbar}
                </CardElem>
            </formTabsContext.Provider>
        );
    }
}

type TabbableFormType = React.SFC<
    Subtract<TabbableFormProps, { classes: TabbableFormProps['classes']; theme: TabbableFormProps['theme'] }>
>;
const TabbableForm: TabbableFormType = withStyles(styles, { withTheme: true })(
    TabbableFormComponent,
) as TabbableFormType;

const mapStateToProps = (state: RootState) => {
    return {
        recentTabResource: state.recentTab.resource,
        recentTabKey: state.recentTab.tabKey,
        recentTabRecordId: state.recentTab.id,
        printMode: state.printMode,
        entitySubmitsInTaskContext: state.entitySubmitsInTaskContext,
        disablePopupNavigationArrow: getUseDisablePopupNavigationArrowSelector(state),
    };
};

const TF = connect(mapStateToProps, { tabRecent: tabRecentAction })(TabbableForm);
export default TF;
