import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
    getEntityId,
    getTabsTitlesFromView,
    getFieldInstanceEntriesFromView,
    getViewFieldInstanceEntriesForTab,
    getDataTypeForFieldExpr,
    expandComponentFields,
} from '../utils/viewConfigUtils/index';
import { getPrintTemplatesByEntityConfId as getPrintTemplateAction } from 'printTemplate/actions';
import { setAsTopView, unsetAsTopView } from '../../../popoverStackManagement/actions';
import labelField from '../utils/labelField';
import { FieldFactoryContext } from '../../../fieldFactory/Broadcasts';
import { Config } from 'fieldFactory/ConfigTypes';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from '../../../fieldFactory/translation/types/DataSource';
import { RootState, useAppSelector } from '../../../reducers/rootReducer';
import TabbableForm from '../form/TabbableForm';
import EntityExpressionTester from 'expression-tester/entity-form';
import useWidth from 'util/hooks/useWidth';
import useViewConfig from 'util/hooks/useViewConfig';
import { ViewField } from 'reducers/ViewConfigType';
import fromEntries from 'util/fromentries';
import { useIsPopover } from '../genericEdit/useIsPopover';
import useEntities from 'util/hooks/useEntities';
import { fromNullable } from 'fp-ts/lib/Option';
import NavToWizardFab from 'layout-editor/components/NavigateToWizardFab';
import useFieldsByTab from '../form/hooks/useFieldsByTab';
import useTabToPrefetch from '../form/hooks/useTabToPrefetch';
import isOffline from 'util/isOffline';
import { useOverrideTitle } from '../form/hooks/configurations/overrideTitle';
import Title, { DisplayAboveWidth } from '../title/Title';
import useRegisterViewDetails from 'popoverStackManagement/viewDetailsStack/useRegisterViewDetails';
import { CasetivityViewContextProvider } from 'util/casetivityViewContext';

export const useMinimalRecord = (resource: string, id: string) => {
    const entities = useEntities();
    const dataLoaded = fromNullable(entities[resource])
        .mapNullable((e) => e[id])
        .fold(false, () => true);
    const minimalRecord = useMemo(() => {
        return {
            id,
            entityType: resource,
        };
    }, [id, resource]);
    return { dataLoaded, minimalRecord };
};

type RenderTitleElement = (props: { titleElem: JSX.Element }) => JSX.Element;
interface ShowFormProps {
    id: string;
    actions?: any;
    formId?: string;
    useTabs?: boolean;
    toolbar?: React.ReactElement<any>;
    resource: string;
    viewName: string;
    referenceFieldsShouldFetchInitialData?: boolean;
    createMobileAppBar?: boolean;
    renderTitleElement?: RenderTitleElement;
    embeddedInFormId?: string;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
    disallowClickAwayNavigation?: boolean;
    embeddedInMultiCard?: boolean;
    defaultValues?: Record<string, unknown>;
}
const ShowFormInnerComponent: FunctionComponent<ShowFormProps> = (props) => {
    const {
        viewName,
        id,
        formId,
        resource,
        referenceFieldsShouldFetchInitialData,
        embeddedInFormId,
        embeddedInMultiCard,
        defaultValues,
    } = props;
    const viewConfig = useViewConfig();
    const width = useWidth();
    // TODO: FIX THIS (it was causing update loops)
    const isPopover = /* true; */ useIsPopover(formId);
    const { dataLoaded, minimalRecord } = useMinimalRecord(resource, id);
    const fieldFactory = React.useContext(FieldFactoryContext);
    const printMode = useAppSelector((state: RootState) => state.printMode);

    const tabToPrefetch = useTabToPrefetch({
        id,
        viewName,
        resource,
    });

    const defaultValuesRef = React.useRef(defaultValues);
    defaultValuesRef.current = defaultValues;
    const getValuesPatch = useCallback(() => {
        return defaultValuesRef.current;
    }, []);

    const getGenerateFields = React.useMemo(() => {
        const config: Config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.DISPLAY,
            validate: false,
            connected: false,
            options: {
                addUnderlineAndMinHeight: true,
                getOwnData: true,
            },
        };
        return (tabKey: string) =>
            fieldFactory(config)({
                match: { id },
                embeddedInFormId: embeddedInFormId || formId,
                referenceFieldsShouldFetchInitialData: (() => {
                    if (!tabKey || tabKey === tabToPrefetch) {
                        return false;
                    }
                    return printMode || referenceFieldsShouldFetchInitialData;
                })(),
                shouldFetchValueset: false,
                isForShow: true,
                getValuesPatch,
            });
    }, [
        fieldFactory,
        id,
        formId,
        tabToPrefetch,
        referenceFieldsShouldFetchInitialData,
        printMode,
        embeddedInFormId,
        getValuesPatch,
    ]);
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(getPrintTemplateAction(getEntityId(viewConfig, resource)));
        if (formId) {
            dispatch(setAsTopView(formId));
            return () => {
                dispatch(unsetAsTopView(formId));
            };
        }
    }, []); // eslint-disable-line

    useRegisterViewDetails(formId, 'entity', id);
    const offlineDownloadedListViews = useAppSelector((state: RootState) => state.offlineDownloadedListViews);
    const offlineDownloadedRef1Views = useAppSelector((state: RootState) => state.offlineDownloadedRef1Views);
    const offlineDownloadedFields = useMemo(() => {
        /**
         * offlineDownloadedListViews etc. are kept around, so we need to be careful they aren't present except in
         * the offline app, where if we are on a route, we have the guarantee they were applied from the saved state.
         */
        if (!isOffline()) {
            return null;
        }
        if (!offlineDownloadedListViews && !offlineDownloadedRef1Views) {
            return null;
        }
        return { ...offlineDownloadedListViews?.[resource]?.[id], ...offlineDownloadedRef1Views?.[resource]?.[id] };
    }, [offlineDownloadedListViews, offlineDownloadedRef1Views, id, resource]);

    const fieldExtractor = React.useCallback(
        (tabKey?: string) => {
            const filterEntries = ([, viewField]: [string, ViewField]) => {
                if (!offlineDownloadedFields) {
                    return true;
                }
                if (
                    viewField.widgetType === 'MULTISELECT' ||
                    (viewField.widgetType === 'SELECT' &&
                        getDataTypeForFieldExpr(viewConfig, viewField.entity, viewField.field, 'POP_LAST') === 'REFONE')
                ) {
                    return Boolean(offlineDownloadedFields?.[viewField.field]?.[viewField.config]);
                }
                return true;
            };

            const fieldsToGenerate = tabKey
                ? getViewFieldInstanceEntriesForTab(viewConfig, viewName, viewConfig.views[viewName].tabs[tabKey])
                : getFieldInstanceEntriesFromView(viewConfig, viewName);

            return !dataLoaded
                ? undefined
                : getGenerateFields(tabKey || null)(
                      expandComponentFields(viewConfig, fieldsToGenerate, viewConfig.views[viewName].entity, {
                          rebaseExpressionsWithinFields: true,
                          replaceXmanyWithMultiCard: printMode && !embeddedInMultiCard,
                      })
                          .expandedFieldsByRow.flat()
                          .filter(filterEntries),
                  )
                      .filter((f) => f)
                      .map((f) =>
                          labelField(
                              f,
                              minimalRecord,
                              resource,
                              `/${resource}`,
                              f.props.source || null,
                              f.props.fieldInstanceIdentifier,
                          ),
                      );
        },
        [
            viewName,
            dataLoaded,
            minimalRecord,
            resource,
            viewConfig,
            getGenerateFields,
            printMode,
            offlineDownloadedFields,
            embeddedInMultiCard,
        ],
    );
    const isLoading = useAppSelector((state: RootState) => state.admin.loading > 0);

    const baseFields = useMemo(fieldExtractor, [fieldExtractor]);

    const _fieldsByTab = useMemo(
        () =>
            fromEntries(
                getTabsTitlesFromView(viewConfig.views[viewName]).map((tabKey) => [tabKey, fieldExtractor(tabKey)]),
            ),
        [viewConfig, viewName, fieldExtractor],
    );
    const fieldsByTab = useFieldsByTab(_fieldsByTab, 'show');

    const { element: overrideTitleElement, EditTitleElem } = useOverrideTitle(viewName);
    const displayAboveWidth = props.createMobileAppBar ? 'xs' : undefined;

    const defaultRenderTitleElem: RenderTitleElement = ({ titleElem }) => (
        <DisplayAboveWidth displayAboveWidth={displayAboveWidth}>
            <h1>{titleElem}</h1>
        </DisplayAboveWidth>
    );

    const renderTitle: RenderTitleElement = props.renderTitleElement ?? defaultRenderTitleElem;

    const TitleElem = renderTitle({
        titleElem: overrideTitleElement ?? (
            <Title component={React.Fragment} resource={resource} id={id} displayAboveWidth={displayAboveWidth} />
        ),
    });

    return (
        <TabbableForm
            embeddedInMultiCard={props.embeddedInMultiCard}
            isShow
            disallowClickAwayNavigation={props.disallowClickAwayNavigation}
            isPopover={isPopover}
            isLoading={isLoading}
            record={minimalRecord}
            width={width}
            title={
                EditTitleElem ? (
                    <div style={{ display: 'flex' }}>
                        {TitleElem}&nbsp;{EditTitleElem}
                    </div>
                ) : (
                    TitleElem
                )
            }
            actions={props.actions}
            viewName={viewName}
            toolbar={props.toolbar}
            viewConfig={viewConfig}
            //  { borderTop: 'solid 1px #e0e0e0' }
            // contentContainerStyle={contentContainerStyle}
            useTabs={props.useTabs}
            baseFields={baseFields}
            fieldsByTab={fieldsByTab}
        />
    );
};

const ShowForm: FunctionComponent<ShowFormProps> = (props) => {
    const { id, resource } = props;
    const record = React.useMemo(() => {
        return {
            id,
            entityType: resource,
        };
    }, [id, resource]);
    return (
        <CasetivityViewContextProvider currentViewContext="entity">
            <EntityExpressionTester
                type="SHOW"
                record={record}
                viewName={props.viewName}
                evaluatedAdhocSPELVariables={props.evaluatedAdhocSPELVariables}
            >
                {({ renderLayoutEditor, renderEditActionsElem, defaultValues }) => (
                    <>
                        {renderLayoutEditor?.()}
                        <ShowFormInnerComponent
                            {...props}
                            defaultValues={defaultValues}
                            actions={renderEditActionsElem?.(id) ?? props.actions}
                        />

                        <NavToWizardFab viewName={props.viewName} />
                    </>
                )}
            </EntityExpressionTester>
        </CasetivityViewContextProvider>
    );
};

export default ShowForm;
