import isPojo from '../isPojo';
import ViewConfig from '../../reducers/ViewConfigType';
import { getRefEntityName } from '../../components/generics/utils/viewConfigUtils';
import isPlainObject from 'lodash/isPlainObject';

const getKvAllowed =
    (
        initialData: Record<string, unknown>,
        submittingData: Record<string, unknown>,
        fieldsToSubmit: string[], // previously named 'visibleAndEditableFields'
        // but changed because we want to submit hidden fields with 'removeRelatedEntitiesIfHidden'
    ) =>
    (k: string) => {
        if (submittingData['partialUpdate'] && checkEquality(initialData[k], submittingData[k]) && fieldsToSubmit) {
            if (!fieldsToSubmit.includes(k)) {
                // the field is uneditable, and has the original values. Don't send it.
                return false;
            }
        }
        return true;
    };

const filterFieldsToKey = (fields: string[], k: string) =>
    fields?.filter((p) => p.startsWith(k + '.'))?.map((p) => p.slice(p.indexOf('.') + 1));
/**
 * recursively remove partialUpdate from comparison, and treat null and empty string the same.
 */
const checkEquality = (left: unknown, right: unknown) => {
    if (isPlainObject(left) && isPlainObject(right)) {
        const { partialUpdate: pleft, ...leftRest } = left as any;
        const { partialUpdate: pright, ...rightRest } = right as any;

        return (
            Object.keys(leftRest).length === Object.keys(rightRest).length &&
            Object.entries(leftRest).every(([k, leftValue]) => checkEquality(leftValue, rightRest[k]))
        );
    }
    if (Array.isArray(left) && Array.isArray(right)) {
        const foundRight: boolean[] = [];
        return (
            left.length === right.length &&
            left.every((leftValue) =>
                right.some((rightValue, i) => {
                    if (foundRight[i]) {
                        // no duplicates, so skip checking this.
                        return false;
                    }
                    return (
                        checkEquality(leftValue, rightValue) &&
                        (() => {
                            foundRight[i] = true;
                            return true;
                        })()
                    );
                }),
            )
        );
    }
    if ((left === '' && right === null) || (right === '' && left === null)) {
        return true;
    }
    return left === right;
};

type Entities = {
    [entityName: string]: {
        [id: string]: {
            id: string;
            entityType: string;
            entityVersion?: number; // Concepts don't always have their entityVersion
        };
    };
};

const getInsertIdsAndVersionNumbers = (viewConfig: ViewConfig, entities: Entities) => {
    const insertIdsAndVersionNumbers = (
        initialValues: Record<string, unknown>,
        submissionData: Record<string, unknown>,
        rootEntity: string,
        // previously named 'visibleAndEditableFields'
        // but changed because we want to submit hidden fields with 'removeRelatedEntitiesIfHidden'
        fieldsToSubmit?: string[],
    ): Record<string, unknown> => {
        const kvAllowed = getKvAllowed(initialValues, submissionData, fieldsToSubmit);

        const getEntityVersionFromRecord = (entityType: string, id: string) => {
            return entities[entityType]?.[id]?.entityVersion;
        };
        const res: Record<string, unknown> = {};
        if (submissionData?.['id'] === null) {
            // I think in this case, it means we should delete it.
            return undefined;
        }
        const submittingId = (submissionData?.['id'] ?? initialValues?.['id'] ?? null) as string | null;
        if (submittingId) {
            res['id'] = submittingId;
            const entityVersion = getEntityVersionFromRecord(rootEntity, submittingId);
            if (entityVersion) {
                res['entityVersion'] = entityVersion;
            }
        } else {
            // No id in the data...
            // IF THE DATA IS UNTOUCHED, don't send it.
            // We only have no ID if we need to create a record -
            // but if no data was changed, we can be reasonably sure that's not the intention.
            const { partialUpdate, ...onlySubmissionData } = submissionData;

            const isUntouched = checkEquality(initialValues, onlySubmissionData);
            if (isUntouched) {
                // don't send this.
                return undefined;
            }
        }
        Object.entries(submissionData).forEach(([k, v]) => {
            if (!isPojo(v)) {
                // We only want to send data on partialUpdate if the value changed OR it was editable (deliberately left unchanged)
                if (kvAllowed(k) || k === 'entityType') {
                    res[k] = v;
                }
                return;
            }
            const refEntityName = (() => {
                try {
                    return getRefEntityName(viewConfig, rootEntity, k, 'TRAVERSE_PATH');
                } catch (e) {
                    console.error(e);
                    return null;
                }
            })();
            if (!refEntityName || refEntityName === 'CasetivityEntity') {
                // Let's see if we can look up entityType from initialValue
                const initialEntityType = initialValues[k]?.['entityType'];
                if (!initialEntityType) {
                    // not sure what the entity type here is, so it's hard to recurse and fill in info
                    res[k] = v;
                    return;
                }
                const newValue = insertIdsAndVersionNumbers(
                    initialValues[k] as Record<string, unknown>,
                    v as Record<string, unknown>,
                    initialEntityType,
                    filterFieldsToKey(fieldsToSubmit, k),
                );
                res[k] = newValue;
                return;
                // recurse
            }
            // if we can look
            const newValue = insertIdsAndVersionNumbers(
                initialValues[k] as Record<string, unknown>,
                v as Record<string, unknown>,
                refEntityName,
                filterFieldsToKey(fieldsToSubmit, k),
            );
            res[k] = newValue;
            return;
        });
        return res;
    };
    return insertIdsAndVersionNumbers;
};

export default getInsertIdsAndVersionNumbers;
