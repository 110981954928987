import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@material-ui/core';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import prismTheme from 'react-syntax-highlighter/dist/cjs/styles/prism/darcula';
import { ContainerReflection, DeclarationReflection, SignatureReflection } from 'typedoc/dist/lib/serialization/schema';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { getDefById } from './getFDoc/getFDoc';
import ExpandMore from '@material-ui/icons/ExpandMore';
import getClassDoc from './getFDoc/getClassDoc';

const i: ContainerReflection = require('f/docs/i.json');

interface FunctionDocProps {
    className?: string;
    method?: string;
}
const FDoc: React.FC<FunctionDocProps> = ({ className, method }) => {
    const item = getClassDoc(i, className);
    const renderParameterType = (
        type: ContainerReflection['children'][number]['signatures'][number]['parameters'][number]['type'],
    ): [JSX.Element, string] => {
        switch (type.type) {
            case 'query':
                if (type.queryType.type === 'reference') {
                    return [<em>{type.queryType.name}</em>, type.queryType.name];
                }
                throw new Error('Unhandled query type', type.queryType.type);
            case 'reference':
                return [<em>{type.name}</em>, type.name];
            case 'array': {
                const [jsxRep, stringRep] = renderParameterType(type.elementType);
                return [<span>({jsxRep})[];</span>, `(${stringRep})[]`];
            }
            case 'literal':
                return [<em>{'' + type.value}</em>, '' + type.value];
            case 'intersection':
            case 'union':
                const sep = type.type === 'intersection' ? ' & ' : ' | ';
                return [
                    <>
                        {type.types.map((t, i, arr) => {
                            const [jsxRep, stringRep] = renderParameterType(t);
                            const isLast = i === arr.length - 1;
                            return (
                                <React.Fragment key={i}>
                                    {stringRep.includes(' ') ? <>({jsxRep})</> : jsxRep}
                                    {!isLast ? sep : null}
                                </React.Fragment>
                            );
                        })}
                    </>,
                    type.types
                        .map((t) => renderParameterType(t)[1])
                        .map((e) => (e.includes(' ') ? `(${e})` : e))
                        .join(sep),
                ];
            case 'rest': {
                const [jsxRep, stringRep] = renderParameterType(type.elementType);
                const needsParens = stringRep.includes(' ');

                return [
                    <>...{needsParens ? <>({jsxRep})</> : jsxRep}[]</>,
                    `...${needsParens ? '(' + stringRep + ')' : stringRep}[]`,
                ];
            }
            case 'intrinsic':
                return [<em>{'' + type.name}</em>, '' + type.name];
            case 'reflection':
                const declaration = type.declaration;
                if (declaration.kindString === 'Type literal') {
                    const el = (
                        <>
                            {'{'}
                            {declaration.children?.map((c, i, arr) => {
                                const isLast = i === arr.length - 1;
                                return (
                                    <div style={{ marginLeft: '1em' }} key={c.id}>
                                        {'' + c.name + ': '}
                                        {renderParameterType(c.type)[0]}
                                        {!isLast ? ',' : ''}
                                    </div>
                                );
                            })}
                            {'}'}
                        </>
                    );
                    const str =
                        '{' +
                        (declaration.children
                            ?.map((c) => '\n\t' + c.name + ': ' + renderParameterType(c.type)[1])
                            ?.join(',') ?? '') +
                        '}';
                    return [el, str];
                }
            // let it fall through
            default:
                const msg = 'TODO: render this parameter type: \n' + JSON.stringify(type, null, 1);
                return [<span>{msg}</span>, msg];
        }
    };

    const renderCodeSignature = (signature: SignatureReflection) => (
        <code style={{ marginTop: '.5rem', marginBottom: '.5rem' }}>
            {signature.name}(
            {signature.parameters?.map((param, i, arr) => (
                <span key={i}>
                    {param.name}: {renderParameterType(param.type)[0]}
                    {i !== arr.length - 1 ? ', ' : ''}
                </span>
            ))}
            ): {renderParameterType(signature.type)[0]}
        </code>
    );

    const renderCodeSignatureSummary = (c: DeclarationReflection) =>
        c.signatures?.length > 1 ? (
            <code>
                {`${c.name}(...): `}
                {renderParameterType(c.signatures[0].type)[0]} {`(${c.signatures.length} overloads)`}
            </code>
        ) : (
            renderCodeSignature(c.signatures[0])
        );
    const renderSignature = (signature: ContainerReflection['children'][number]['signatures'][number]) => {
        const renderCommonDisplayPart = (
            c: ContainerReflection['children'][number]['signatures'][number]['comment']['summary'][number],
        ) => {
            switch (c.kind) {
                case 'code': {
                    return (
                        <ReactMarkdown
                            components={{
                                code({ node, inline, className, children, ...props }) {
                                    const match = /language-(\w+)/.exec(className || '');

                                    return !inline && match ? (
                                        <SyntaxHighlighter
                                            style={prismTheme}
                                            PreTag="div"
                                            language={match[1]}
                                            children={String(children).replace(/\n$/, '')}
                                            {...props}
                                        />
                                    ) : (
                                        <code className={className ? className : ''} {...props}>
                                            {children}
                                        </code>
                                    );
                                },
                            }}
                        >
                            {c.text}
                        </ReactMarkdown>
                    );
                }
                case 'text': {
                    return <ReactMarkdown>{c.text}</ReactMarkdown>;
                }
                case 'inline-tag': {
                    return <span> TODO: unimplemented inline tag.</span>;
                }
                default:
                    return 'unimplemented tag:\n' + JSON.stringify(c, null, 1);
            }
        };
        const returns: JSX.Element[] = [];
        const examples: JSX.Element[] = [];
        const throws: JSX.Element[] = [];
        const unknown: JSX.Element[] = [];
        signature.comment?.blockTags?.forEach((t, i) => {
            const content = t.content.map((c, ix) => (
                <span key={t.tag + ':' + i + ':' + ix}>{renderCommonDisplayPart(c)}</span>
            ));
            content.forEach((c) => {
                const bucket =
                    t.tag === '@returns'
                        ? returns
                        : t.tag === '@example'
                        ? examples
                        : t.tag === '@throws'
                        ? throws
                        : unknown;
                bucket.push(c);
            });
        });
        return (
            <div style={{ padding: '1rem' }}>
                <SyntaxHighlighter
                    style={prismTheme}
                    PreTag="div"
                    language="typescript"
                    children={`${signature.name}(${
                        signature.parameters
                            ?.map(
                                (param, i, arr) =>
                                    `${arr.length > 0 ? '\n\t' : ''}${param.name}: ${
                                        renderParameterType(param.type)[1]
                                    }${arr.length > 0 && i === arr.length - 1 ? '\n' : ''}`,
                            )
                            ?.join(', ') ?? ''
                    }): ${renderParameterType(signature.type)[1]}`}
                />
                {/* {renderCodeSignature(signature)} */}
                <Divider />
                {signature.comment?.summary?.reduce((prev, curr, i) => {
                    prev.push(<span key={i}>{renderCommonDisplayPart(curr)}</span>);
                    return prev;
                }, []) ?? null}
                <b>Parameters</b>
                <ul>
                    {(() => {
                        const params: JSX.Element[] = [];
                        signature.parameters?.forEach((param, i) => {
                            params.push(
                                <li key={i}>
                                    <b>{param.name}</b>: {renderParameterType(param.type)[0]}
                                    <div>
                                        {param.comment?.summary?.reduce((prev, curr, i) => {
                                            prev.push(<span key={i}>{renderCommonDisplayPart(curr)}</span>);
                                            return prev;
                                        }, []) ?? null}
                                    </div>
                                </li>,
                            );
                        });
                        return params;
                    })()}
                </ul>
                <div>
                    {returns.length > 0 || signature.type ? <b>Returns</b> : null}
                    {signature.type ? <>&nbsp;{renderParameterType(signature.type)[0]}</> : null}
                    {returns}
                </div>
                <div>
                    {examples.length > 0 ? <b>Examples</b> : null}
                    {examples}
                </div>
                <div>
                    {throws.length > 0 ? <b>Throws</b> : null}
                    {throws}
                </div>
            </div>
        );
    };

    const renderProperty = (property: DeclarationReflection) => {
        return (
            <div>
                <b>{property.name}</b>
                <SyntaxHighlighter
                    style={prismTheme}
                    PreTag="div"
                    language="typescript"
                    children={`${property.name}: ${renderParameterType(property.type)[1]}`}
                />
            </div>
        );
    };

    const renderClass = (item: DeclarationReflection) => {
        const properties = item.children.filter((c) => c.kindString === 'Property');
        const methods = item.children.filter((c) => c.kindString === 'Method');
        return (
            <div>
                <Typography variant="h6">Class {item.name}</Typography>
                {properties.length > 0 ? <Typography variant="body1">Properties</Typography> : null}
                <ul>
                    {properties.map((c) => (
                        <li key={c.id}>{renderProperty(c)}</li>
                    ))}
                </ul>
                <div style={{ marginLeft: '1em', marginTop: '1em' }}>
                    {methods.length > 0 ? <Typography variant="subtitle1">Methods</Typography> : null}
                    {methods.map((c) => {
                        return (
                            <Accordion key={c.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls={`panel${c.id}-content`}
                                    id={`panel${c.id}-header`}
                                >
                                    {renderCodeSignatureSummary(c)}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ width: '100%' }}>
                                        {c.signatures.map((s) => (
                                            <div key={s.id}>{renderSignature(s)}</div>
                                        ))}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
            </div>
        );
    };

    if (!item) {
        return null;
    }
    const renderItem = (item: DeclarationReflection) => {
        if (item.kindString === 'Method') {
            return (
                <div>
                    {renderCodeSignatureSummary(item)}
                    <ul>
                        {item.signatures.map((s, i) => (
                            <li key={s.id}>
                                <Divider />
                                <div style={{ marginTop: '1em' }}>
                                    {renderCodeSignature(s)}
                                    <div style={{ paddingLeft: '1em' }}>{renderSignature(s)}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
        if (item.kindString === 'Function' && item.signatures?.[0]) {
            return (
                <div>
                    {/* TODO: link in docs? */}
                    <Typography variant="h6">Function #{item.name}</Typography>
                    <div style={{ marginLeft: '1em' }}>{renderCodeSignature(item.signatures[0])}</div>
                </div>
            );
        }
        if (item.kindString === 'Class') {
            return <div>{renderClass(item)}</div>;
        }
        if (item.kindString === 'Property') {
            if (item.type?.type === 'reference') {
                const def = getDefById(item.type.id)(i);
                return renderItem(def);
            }
            return renderProperty(item);
        }
        return null;
    };
    if (method) {
        const methodFound = item.children?.find((c) => c.kindString === 'Method' && c.name === method) ?? null;
        return methodFound && renderItem(methodFound);
    }
    return renderItem(item);
};
export default FDoc;
