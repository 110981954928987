import { ContainerReflection, DeclarationReflection } from 'typedoc/dist/lib/serialization/schema';

const getClassDoc = (doc: ContainerReflection, className: string): DeclarationReflection => {
    return (
        doc.children.flatMap((modul) =>
            modul.children.filter((c) => c.kindString === 'Class' && c.name === className),
        )[0] ?? null
    );
};

export default getClassDoc;
