import React, { useRef, useState } from 'react';
import { Button, CircularProgress, Tooltip } from '@material-ui/core';
import { CheckCircle, Error as ErrorIcon } from '@material-ui/icons';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import buildHeaders from 'sideEffect/buildHeaders';
const config = require('../config.js');

export const SyncTextTemplatesButton = () => {
    const [status, setStatus] = useState('INITIAL');
    const [dialogOpen, setDialogOpen] = useState(false);
    const [message, setMessage] = useState('');
    const abortController = useRef(null);

    const handleSyncTextTemplates = async (): Promise<void> => {
        if (abortController.current) abortController.current.abort();
        abortController.current = new AbortController();

        setStatus('LOADING');
        setDialogOpen(true);
        try {
            const request = new Request(`${config.BACKEND_BASE_URL}api/admin/configs/sync-text-templates`, {
                method: 'POST',
                credentials: 'same-origin',
                headers: buildHeaders({
                    includeCredentials: true,
                }),
                body: JSON.stringify({}),
                signal: abortController.current.signal,
            });
            const response = await fetch(request);
            if (response.ok) {
                const alertMessage = response.headers.get('Alert-Message') || 'Text templates synced to database';
                setMessage(`${alertMessage} Click to reset and sync again.`);
                setStatus('SUCCESS');
            } else {
                const errorBody = await response.json();
                throw new Error(`Failed with status: ${response.status}, Message: ${errorBody.message}`);
            }
        } catch (error) {
            if (!abortController.current.signal.aborted) {
                setMessage(`Error: ${error}`);
                setStatus('ERROR');
            } else {
                setMessage('Request cancelled by user.');
                setStatus('ERROR');
            }
        }
        setDialogOpen(false);
    };

    const handleCancel = () => {
        abortController.current.abort();
        setDialogOpen(false);
        setStatus('INITIAL');
        setMessage('Request has been cancelled.');
    };
    const handleClick = () => {
        if (status !== 'INITIAL') {
            setStatus('INITIAL');
            return;
        }
        handleSyncTextTemplates();
    };

    return (
        <div style={{}}>
            <Tooltip title={message || 'Sync Text Templates'}>
                <Button variant="contained" color="primary" onClick={handleClick} aria-label="Sync Text Templates">
                    {status === 'INITIAL' && null}
                    {status === 'LOADING' && <CircularProgress size={24} aria-label="Loading" />}
                    {status === 'SUCCESS' && <CheckCircle aria-label="Success Check Mark" />}
                    {status === 'ERROR' && <ErrorIcon color="error" aria-label="Error Icon" />}
                    Sync Text Templates
                </Button>
            </Tooltip>
            <Dialog open={dialogOpen} onClose={handleCancel}>
                <DialogContent>
                    <DialogContentText>Syncing text templates to the database. Please wait...</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel Request
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
