import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TableCell, TableRow, Tooltip, IconButton, useTheme, makeStyles, CircularProgress } from '@material-ui/core';
import Clear from '@material-ui/icons/HighlightOffTwoTone';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import { getAdjustedFieldSource } from '../../utils/viewConfigUtils';
import useViewConfig from 'util/hooks/useViewConfig';
import { isFieldViewField } from '../../utils/viewConfigUtils';
import ControlledValidatedForm from 'fieldFactory/input/components/InlineMany/ControlledValidatedRowForm';
import Save from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import classNames from 'classnames';
import { crudUpdate } from 'sideEffect/crud/update/actions';
import set from 'lodash/set';
import useEntities from 'util/hooks/useEntities';
import traverseGetData from '@mkanai/casetivity-shared-js/lib/viewConfigSchema/traverseGetData';
import { AjaxError } from 'rxjs/ajax';
import Alert from '@material-ui/lab/Alert/Alert';
import merge from 'lodash/merge';
import { enqueueSnackbar } from 'notistack/actions';
import {
    ListRowFormContextProvider,
    listRowFormContext,
} from 'components/generics/form/EntityFormContext/EditableListRow';
import { ExpressionsByField } from './WithInlineEditableExpressions';
import FocusTrap from 'focus-trap-react';
import { useAppStore } from 'reducers/rootReducer';

const useStyles = makeStyles((theme) => ({
    foreGround: {
        background: theme.palette.background.paper,
        display: 'inline-block',
        position: 'relative',
        zIndex: 200,
        marginRight: 1,
    },
    field: {
        borderRadius: 3,
        marginTop: '-5px',
    },
    rowActions: {
        borderRadius: 3,
        marginLeft: 2,
    },
    disabled: {
        opacity: 0.4,
    },
}));

function walkTheDOM(node: Node, cb: (node: Node) => void) {
    cb(node);
    node = node.firstChild;
    while (node) {
        walkTheDOM(node, cb);
        node = node.nextSibling;
    }
}

const FocusInputChild: React.FC<{}> = (props) => {
    const divRef = useRef<HTMLDivElement>();
    useEffect(() => {
        walkTheDOM(divRef.current, (node) => {
            if ((node as Element).tagName === 'INPUT') {
                (node as HTMLInputElement).focus();
            }
        });
    }, []);
    return <div ref={divRef}>{props.children}</div>;
};

const EditableFields = ({
    fields,
    resource,
    record,
    onClose,
    onSave,
    autoFocusField,
    editableExpressions,
}: {
    fields: React.ReactElement<any>[];
    resource: string;
    record: {};
    onClose?: () => void;
    onSave?: () => void;
    autoFocusField?: string;
    editableExpressions?: ExpressionsByField;
}) => {
    const id = record['id'];
    const classes = useStyles();
    const viewConfig = useViewConfig();
    const fieldFactory = useContext(FieldFactoryContext);

    const entities = useEntities();

    const viewFields = useMemo(() => fields.map((f) => JSON.parse(f.props['data-originaldefinition'])), [fields]);
    const inputFields = useMemo(() => {
        const config = {
            dataSource: DataSource.ENTITY,
            mode: Mode.INPUT_NOWARN,
            validate: true,
            connected: 'ff',
            options: {
                getOwnData: true,
                hideCheckboxLabel: true,
                ff: true,
            },
        };
        return fieldFactory(config)({
            record,
            resource,
            basePath: `/${resource}`,
            referenceFieldsShouldFetchInitialData: true,
            shouldFetchValueset: true,

            isPopover: true, // <- I think

            overrideFieldValueIfDisabled: true,
            isForCreate: true,
            fetchOnMount: true,
            noPad: true, // remove padding on sides of field.
            style: {
                marginTop: '-5px',
            },
        })(
            viewFields.map((vf) => {
                const getWithPostfix = getAdjustedFieldSource(viewConfig)({ entity: resource });
                return [isFieldViewField(vf) ? getWithPostfix(vf, vf.field) : vf.field, vf];
            }),
        );
    }, [fieldFactory, viewConfig, record, resource, viewFields /* autoFocusField */]);

    const editableFieldSources: {
        [source: string]: true;
    } = useMemo(
        () =>
            inputFields
                .filter((inputField, i) => fields[i].props.inlineEditable && !inputField.props.disabled)
                .reduce((prev, curr) => {
                    prev[curr.props.source] = true;
                    return prev;
                }, {}),
        [inputFields, fields],
    );

    const denormalized = useMemo(() => {
        const setValue = (path: string, mutateObj: {}) =>
            traverseGetData(
                viewConfig,
                path,
                { ...(record as { id: string }), entityType: resource },
                entities,
                false,
            ).fold(null, (value) => {
                set(mutateObj, path, value);
            });
        const editableFieldData = fields
            .filter((field, i) => editableFieldSources[inputFields[i].props.source])
            .reduce((prev, curr) => {
                const source: string = curr.props.source;
                setValue(source, prev);
                source
                    .split('.')
                    .slice(0, -1)
                    .forEach((curr, i, arr) => {
                        const untilNow = arr.slice(0, i + 1);

                        setValue([...untilNow, 'entityVersion'].join('.'), prev);
                        setValue([...untilNow, 'id'].join('.'), prev);
                        set(prev, [...untilNow, 'partialUpdate'].join('.'), true);
                    });
                return prev;
            }, {});

        return Object.values(editableExpressions ?? {})
            .flatMap((e) => e.flatMap((meta) => meta.dataPaths))
            .reduce((prev, curr) => {
                setValue(curr, prev);
                return prev;
            }, editableFieldData);
    }, [viewConfig, fields, inputFields, editableFieldSources, record, resource, entities, editableExpressions]);

    const [values, setValues] = useState(denormalized);

    useEffect(() => {
        const main = document.getElementById('maincontent');
        const divv = document.createElement('div');
        divv.classList.add('blanket');
        main.style.position = 'relative';
        main.appendChild(divv);
        return () => {
            main.removeChild(divv);
            main.style.position = '';
        };
    }, []);

    useEffect(() => {
        const body = document.body;
        const handleEsc = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };
        body.addEventListener('keydown', handleEsc);
        return () => {
            body.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]);

    const theme = useTheme();
    const store = useAppStore();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<AjaxError>(null);
    // close on mutating values so we don't have to change the 'submit' function every time.
    const valuesRef = useRef(values);
    valuesRef.current = values;
    const submit = useCallback(() => {
        const { __invalid, ...rest } = valuesRef.current as any;

        const initialEmptyValues = Object.keys(editableFieldSources).reduce((prev, source) => {
            // might have to set empty arrays and stuff for e.g. valueset-manys, although null might work fine as well.
            set(prev, source, null);
            return prev;
        }, {});

        const saveData = merge(initialEmptyValues, rest);
        setError(null);
        setLoading(true);
        store.dispatch(
            crudUpdate({
                previousData: denormalized,
                data: {
                    id,
                    partialUpdate: true,
                    ...saveData,
                },
                resource,
                cb: () => {
                    setLoading(false);
                    store.dispatch(
                        enqueueSnackbar({
                            message: 'Record saved successfully',
                            options: {
                                ContentProps: {
                                    className: 'prod',
                                    style: { backgroundColor: theme.palette.success.dark },
                                },
                                variant: 'success',
                            },
                        }),
                    );
                    onSave();
                },
                errorsCbs: {
                    '*': (err) => {
                        setError(err);
                        setLoading(false);
                    },
                },
            }),
        );
    }, [store, denormalized, id, resource, onSave, editableFieldSources, theme.palette.success.dark]);

    useEffect(() => {
        const body = document.body;
        const handleEnter = (e) => {
            if (e.keyCode === 13) {
                submit();
            }
        };
        body.addEventListener('keydown', handleEnter);
        return () => {
            body.removeEventListener('keydown', handleEnter);
        };
    }, [submit]);

    // error.message is going to be something like 'ajax error 400' so not very useful.
    const errorMessage = !!error && (error.status === 0 ? 'Network Error, try again' : 'An error occurred.');

    return (
        <ControlledValidatedForm
            initialValues={denormalized}
            resource={resource}
            registeredFields={viewFields.map(({ field }) => field)}
            values={values}
            setValues={setValues}
        >
            {(props) => {
                return (
                    <ListRowFormContextProvider
                        fieldDefinitions={viewFields}
                        initialValues={props.initialValues}
                        values={props.values}
                        entityType={resource}
                        overrides={{
                            editableExps: editableExpressions,
                        }}
                    >
                        <FocusTrap
                            focusTrapOptions={{
                                allowOutsideClick: true,
                                clickOutsideDeactivates: true,
                            }}
                        >
                            <TableRow>
                                {inputFields.map((field, i) => {
                                    if (!editableFieldSources[field.props.source]) {
                                        return (
                                            <TableCell style={{ padding: 0, paddingLeft: i === 0 ? '1rem' : 0 }}>
                                                {React.cloneElement(fields[i], { record })}
                                            </TableCell>
                                        );
                                    }
                                    const editableField = React.cloneElement(field, {
                                        style: { paddingLeft: 0, paddingRight: 0, marginTop: '-5px' },
                                    });
                                    return (
                                        <TableCell
                                            style={{ padding: 0, minWidth: 150 }}
                                            onKeyDown={(e) => {
                                                // submit on enter key pressed
                                                if (e.keyCode === 13) {
                                                    // bypass our outer 'enter' handling in useEffect-added event listener
                                                    e.stopPropagation();
                                                    // end any current debounces on the field.
                                                    (e.target as any).blur?.();

                                                    setLoading(true);
                                                    // let a render pass occur
                                                    setTimeout(() => {
                                                        submit();
                                                    }, 100);
                                                }
                                            }}
                                        >
                                            <listRowFormContext.Consumer>
                                                {(lfc) => (
                                                    <div
                                                        className={classNames(
                                                            classes.foreGround,
                                                            classes.field,
                                                            lfc.disabledFields[field.props.source]
                                                                ? classes.disabled
                                                                : undefined,
                                                        )}
                                                    >
                                                        {field.props.source === autoFocusField ? (
                                                            <FocusInputChild>{editableField}</FocusInputChild>
                                                        ) : (
                                                            editableField
                                                        )}
                                                    </div>
                                                )}
                                            </listRowFormContext.Consumer>
                                        </TableCell>
                                    );
                                })}
                                <TableCell style={{ padding: 0 }}>
                                    <div style={{ display: 'flex' }}>
                                        {errorMessage && (
                                            <div className={classes.foreGround} style={{ borderRadius: '50%' }}>
                                                <div
                                                    role="alert"
                                                    aria-live="assertive"
                                                    className="casetivity-off-screen"
                                                >
                                                    {errorMessage}
                                                </div>
                                                <Tooltip title={errorMessage}>
                                                    <span
                                                        style={{
                                                            padding: 6.75,
                                                            flex: '0 0 auto',
                                                            display: 'inline-flex',
                                                            justifyContent: 'center',
                                                            textAlign: 'center',
                                                            verticalAlign: 'middle',
                                                        }}
                                                    >
                                                        <ErrorIcon fontSize="large" color="error" />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        )}
                                        <div className={classes.foreGround} style={{ borderRadius: '50%' }}>
                                            <div
                                                className={classes.foreGround}
                                                style={{ position: 'fixed', top: 100, right: 50 }}
                                            >
                                                <Alert role="alertdialog" aria-live="assertive" severity="info">
                                                    <p>Press Enter to submit.</p>
                                                    <p>Press Esc to exit.</p>
                                                </Alert>
                                            </div>
                                            <Tooltip title={loading ? 'Saving...' : 'Save'}>
                                                <span>
                                                    <IconButton
                                                        disabled={loading}
                                                        aria-label="Save"
                                                        color="primary"
                                                        onClick={submit}
                                                    >
                                                        {loading ? <CircularProgress size={24} /> : <Save />}
                                                    </IconButton>
                                                </span>
                                            </Tooltip>
                                        </div>
                                        <div className={classes.foreGround} style={{ borderRadius: '50%' }}>
                                            <Tooltip title="Cancel">
                                                <IconButton disabled={loading} aria-label="Cancel" onClick={onClose}>
                                                    <Clear />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </TableCell>
                            </TableRow>
                        </FocusTrap>
                    </ListRowFormContextProvider>
                );
            }}
        </ControlledValidatedForm>
    );
};

export default EditableFields;
