import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { fType } from 'ace-editor/util/StaticTypes/f';
import { StaticType } from 'ace-editor/util/evalStatically/evalStatically';
import FDoc from 'expression-tester/util/FDoc';

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const LazyRenderChildren = (props: { children: () => JSX.Element | JSX.Element[] }) => {
    return <>{props.children()}</>;
};

interface FTreeViewProps {}
export default function FTreeView(props: FTreeViewProps) {
    const classes = useStyles();

    const renderTree = (node: StaticType, path: string[], child?: React.ReactNode) => (
        <TreeItem key={path.join('.')} nodeId={path.join('.')} label={path.join('.')}>
            {child}
            {node._type === 'map'
                ? Object.entries(node.staticValue).map(([k, v]) => {
                      const filledKey = k.endsWith('(') ? `${k}...)` : k;
                      const isMethod = filledKey.endsWith(')');
                      return (
                          <LazyRenderChildren>
                              {() =>
                                  renderTree(
                                      v,
                                      path.concat(filledKey),
                                      isMethod ? (
                                          <div style={{ border: '1px solid black', backgroundColor: 'whitesmoke' }}>
                                              <FDoc className={node.__className} method={k.slice(0, k.indexOf('('))} />
                                          </div>
                                      ) : null,
                                  )
                              }
                          </LazyRenderChildren>
                      );
                  })
                : node._type}
        </TreeItem>
    );

    /* It's a bit nicer to see everything expanded */
    return (
        <div>
            {['f().date', 'f().instant', 'f().time', 'f().list'].map((root) => {
                return (
                    <TreeView
                        key={root}
                        className={classes.root}
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpanded={root.split('.')}
                        defaultExpandIcon={<ChevronRightIcon />}
                    >
                        {fType._type === 'map' && renderTree(fType.staticValue[root.split('.')[1]], root.split('.'))}
                    </TreeView>
                );
            })}
        </div>
    );
}
