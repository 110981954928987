import { AdhocBpmFormBuilder } from 'bpm/task-form-editor/AdhocBpmFormBuilder';
import React, { useCallback, useMemo } from 'react';
import { BpmFormBuilderProps } from '../BpmFormBuilderProps';

const BpmFormBuilderField: React.FC<BpmFormBuilderProps> = ({
    input: { value, onChange },
    meta,
    label,
    renderLabel,
}) => {
    const currentForm = useMemo(() => {
        if (!value) {
            return undefined;
        }
        try {
            return JSON.parse(value).definition;
        } catch (e) {
            console.error(e);
            return undefined;
        }
    }, [value]);

    const handleFormChange = useCallback(
        (value) => {
            onChange(
                JSON.stringify({
                    definition: value,
                }),
            );
        },
        [onChange],
    );
    return (
        <AdhocBpmFormBuilder
            noOutcomes
            currentForm={currentForm}
            setCurrentForm={handleFormChange}
            label={label}
            renderLabel={renderLabel}
        />
    );
};

export default BpmFormBuilderField;
