import React, { useContext, useMemo, useCallback, useReducer, useRef } from 'react';
import { TaskForm } from '../../reducers/taskFormType';
import { FieldFactoryContext } from 'fieldFactory/Broadcasts';
import MovableGrid from './MovableGrid2';
import { getFields } from 'bpm/start-form/components/StartForm';
import { IconButton } from '@material-ui/core';
import produce from 'immer';
import set from 'lodash/set';
import get from 'lodash/get';
import Clear from '@material-ui/icons/Clear';
// import MovableGrid, { Layout } from './MovableGrid';
import { Mode } from 'fieldFactory/Mode';
import { DataSource } from 'fieldFactory/translation/types/DataSource';
import Popup from 'components/Popup';
import { LayoutState } from 'layout-editor/demo/layoutReducer';
import EditTaskFormField from 'bpm/task-form-editor/EditField';
import { FormFieldUnion } from 'fieldFactory/translation/fromFlowable/types';
import createGetOptionsSelector from 'bpm/form-context-utils/etc/getOptionsSelector';

interface EditableTaskFormLayoutProps {
    formDefinition: TaskForm;
    onFormDefinitionChange: (args: { formDefinition: TaskForm }) => void;
}

const getFieldTypeFromType = (type: FormFieldUnion['type']): FormFieldUnion['fieldType'] => {
    switch (type) {
        case 'expression':
            return 'ExpressionFormField';
        case 'dropdown':
        case 'radio-buttons':
            return 'OptionFormField';
        default:
            return 'FormField';
    }
};

/*
TODO:
handle variables.
I don't think these are being used anywhere...
decide whether to keep or remove.
formDefinition.fields.filter(f => !(f.type === 'expression' && f.value && f.value.startsWith('$=['))
*/
const EditableTaskFormLayout: React.FC<EditableTaskFormLayoutProps> = ({ formDefinition, onFormDefinitionChange }) => {
    const fieldFactory = useContext(FieldFactoryContext);
    const createField = useCallback(
        (fieldDefinition: TaskForm['fields'][any]) => {
            let e = fieldFactory({
                dataSource: DataSource.FLOWABLE,
                mode: Mode.INPUT_NOWARN,
                validate: false,
                connected: true,
                options: {
                    ignoreFilters: true,
                },
            })(
                {
                    replacePeriodsInFieldName: '_',
                    options: {
                        fullWidth: true,
                    },
                    overrideFieldValueIfDisabled: true,
                    shouldFetchValueset: true,
                },
                null,
                createGetOptionsSelector({
                    fields: [fieldDefinition],
                }),
            )([fieldDefinition])[0];
            console.log({ e });
            return e;
        },
        [fieldFactory],
    );

    /*
        Neal request: if no row provided for some fields, place them at the bottom, span 4, each on their own row.
    */
    const bottomEmptyRow = useMemo(() => {
        let bottomEmptyRow =
            Object.values(formDefinition.fields).reduce((prev, curr) => {
                return Math.max(prev, curr.params?.row ? parseInt(curr.params.row) : prev);
            }, 0) + 1;
        return bottomEmptyRow;
    }, [formDefinition]);
    const nextRow = useRef(bottomEmptyRow);
    const fields = useMemo(() => {
        return getFields(
            fieldFactory,
            formDefinition,
            {
                ignoreFilters: true,
                getDefaultRow: () => nextRow.current++,
                getDefaultSpan: () => 4,
            },
            undefined,
            true,
        );
    }, [fieldFactory, formDefinition]);

    const handleLayoutChange = useCallback(
        (args: { layout: LayoutState }) => {
            const newFormDef = produce(formDefinition, (draftState) => {
                draftState.fields = args.layout.map((f) => {
                    const { x, y, w, 'data-originaldefinition': json } = f;
                    const fieldData = JSON.parse(json);
                    set(fieldData, 'params.row', y + 1);
                    set(fieldData, 'params.column', x + 1);
                    set(fieldData, 'params.span', w);

                    // clear out empty strings and empty values to prevent clutter
                    ['params.configs.editable', 'params.configs.visibility', 'params.availableConcepts'].forEach(
                        (path) => {
                            if (!get(fieldData, path)) {
                                set(fieldData, path, undefined);
                            }
                        },
                    );
                    if (get(fieldData, 'params.configs.validation') === '[]') {
                        set(fieldData, 'params.configs.validation', undefined);
                    }
                    const configs = fieldData?.params?.configs;
                    if (configs && Object.entries(configs).filter(([, v]) => typeof v !== 'undefined').length === 0) {
                        set(fieldData, 'params.configs', undefined);
                    }
                    return fieldData;
                });
            });
            onFormDefinitionChange({ formDefinition: newFormDef });
        },
        [formDefinition, onFormDefinitionChange],
    );
    const [refreshKey, refresh] = useReducer((state) => state + 1, 1);

    return (
        <div>
            {fields && (
                <MovableGrid
                    overlapInsert
                    // No overflow area if search
                    MAX_COLS={24}
                    key={refreshKey}
                    refresh={refresh}
                    // recalculateHeightKey={recalculateHeightKey}
                    createField={createField}
                    renderFieldAdder={({ addField, renderToggler, initialValues }) => (
                        <Popup
                            renderToggler={renderToggler}
                            renderDialogContent={({ closeDialog }) => {
                                return (
                                    <div
                                        style={{
                                            minWidth: '70vw',
                                            maxWidth: '1200px',
                                            width: '100%',
                                            padding: '1em',
                                            border: '1px solid black',
                                        }}
                                    >
                                        <b>Task Form Field</b>
                                        <IconButton style={{ float: 'right' }} size="small" onClick={closeDialog}>
                                            <Clear />
                                        </IconButton>
                                        <EditTaskFormField
                                            taskForm={formDefinition}
                                            initialData={initialValues}
                                            onSubmit={(_fd) => {
                                                const fd = {
                                                    ..._fd,
                                                    fieldType: getFieldTypeFromType(_fd.type),
                                                };
                                                if (fd.type === 'expression') {
                                                    addField({
                                                        ...fd,
                                                        value: fd.expression,
                                                    });
                                                } else {
                                                    addField(fd);
                                                }
                                                closeDialog();
                                            }}
                                        />
                                    </div>
                                );
                            }}
                        />
                    )}
                    fields={fields}
                    onLayoutChange={handleLayoutChange}
                    columnStartsAt={1}
                />
            )}
        </div>
    );
};
export default EditableTaskFormLayout;
