import React, { useMemo } from 'react';
import getRenderUnpersistedInlineList from './renderUnpersistedInlineManyList';

export interface InlineManyManyProps {
    overrideRenderList: ReturnType<typeof getRenderUnpersistedInlineList>;
    reference: string;
    disabled?: boolean;
    evaluatedAdhocSPELVariables?: Record<string, unknown>;
}
const InlineManyMany = (props: InlineManyManyProps) => {
    const data = useMemo(() => ({}), []);

    return (
        <div>
            {props.overrideRenderList({
                data,
                resource: props.reference,
                disabled: props.disabled,
                evaluatedAdhocSPELVariables: props.evaluatedAdhocSPELVariables,
            })}
        </div>
    );
};
export default InlineManyMany;
