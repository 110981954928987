import {
    isFieldFromFlowable,
    getConfigProperty,
    getFlowableFieldConfigProperty,
    isFieldFromEntity,
} from '../../input/fieldUtils';
import { Field } from '../../translation/types';

interface DirectionConf {
    direction?: 'HORIZONTAL' | 'VERTICAL';
}
export const getDirection = (fieldDefinition: Field) => {
    if (isFieldFromEntity(fieldDefinition)) {
        return getConfigProperty<DirectionConf>('direction')(fieldDefinition).getOrElse(undefined);
    }
    if (isFieldFromFlowable(fieldDefinition)) {
        return getFlowableFieldConfigProperty<DirectionConf>('direction')(fieldDefinition).getOrElse(undefined);
    }
    // no direction specified for fromFlowable
    return undefined;
};

interface DeselectableConf {
    deselectable?: boolean;
}
export const getDeselectable = (fieldDefinition: Field) => {
    if (isFieldFromEntity(fieldDefinition)) {
        return getConfigProperty<DeselectableConf>('deselectable')(fieldDefinition).getOrElse(undefined);
    }
    if (isFieldFromFlowable(fieldDefinition)) {
        return getFlowableFieldConfigProperty<DeselectableConf>('deselectable')(fieldDefinition).getOrElse(undefined);
    }
    // no direction specified for fromFlowable
    return undefined;
};
