import React, { useState } from 'react';
import FileDownload from '@material-ui/icons/SaveAlt';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem, Tooltip } from '@material-ui/core';
import Check from '@material-ui/icons/Check';

const ExportViewConfigButton = ({ mobile, label, onClick }) => {
    const [downloaded, setDownloaded] = useState<boolean>(false);

    const handleOnClick = () => {
        if (downloaded) {
            setDownloaded(false);
        } else {
            onClick();
            setDownloaded(true);
        }
    };
    const Icon = downloaded ? <Check /> : <FileDownload />;
    const Content = (
        <Tooltip title={downloaded ? 'Check your downloads folder.' : 'Initiate download.'}>
            <IconButton color="inherit" onClick={handleOnClick} aria-label="Export Viewconfig">
                {Icon}
            </IconButton>
        </Tooltip>
    );

    return mobile ? (
        <MenuItem onClick={handleOnClick}>
            {label} {Icon}
        </MenuItem>
    ) : (
        Content
    );
};

ExportViewConfigButton.defaultProps = {
    mobile: false,
    label: '', // not in use
};
export default ExportViewConfigButton;
