import ViewConfig from 'reducers/ViewConfigType';
import { fromEither, Option } from 'fp-ts/lib/Option';
import { tryCatch } from 'fp-ts/lib/Either';
import { mapOption } from 'fp-ts/lib/Array';
import { isValidEntityFieldExpression } from 'components/generics/utils/viewConfigUtils/getFieldProperties';
import getImpl from 'expressions/Provider/implementations/getImpl';
import { parseConfig } from 'expressions/entityViewConfig/parse';
import { parsingOrValidationErrMsg } from 'expressions/formValidation';
import setupValuesetFieldsRequired from 'viewConfigCalculations/util/setupValuesetFieldsRequired';
import { getViewConfiguration } from 'components/generics/utils/viewConfigUtils';
import { fromNullable, some } from 'fp-ts/lib/Option';
import { isActionOf } from 'typesafe-actions';
import { RootState } from 'reducers/rootReducer';
import { Epic } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { Services } from 'sideEffect/services';
import { filter, map, tap } from 'rxjs/operators';
import { loadSuccess } from 'viewConfig/actions';
import { getStorage } from 'storage/storage';
import { set } from './calcValueExpressionsSlice';
import { expressionTesterProvidedViewConfigurationContext } from 'expression-tester/entity-form';
import { useContext, useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import removeMethodHashes from '@mkanai/casetivity-shared-js/lib/spel/getFieldsInAst/removeMethodHashes';
import { ViewCalcValueExpressionMetaData } from './ViewCalcValueExpressions';

export const getMetadataFromCalcExpressions = (
    v: {
        [variable: string]: string;
    },
    resource: string,
    viewConfig: ViewConfig,
) => {
    // Either empty object or array object
    return mapOption(
        Object.entries(v ?? {}).filter(([f, e]) => {
            const fieldPath = f.endsWith('Id') ? f.slice(0, -2) : f.endsWith('Ids') ? f.slice(0, -3) : f;
            // try to do the lookup of the fieldName in the viewConfig here,
            // so that expressions on fields the user doesn't have access to
            // fail and are scrapped.
            // *references to expressionFields (non-data) starting with $ are allowed
            const isValid =
                fieldPath.startsWith('$') ||
                fieldPath.startsWith('_') ||
                isValidEntityFieldExpression(viewConfig, resource, fieldPath);
            if (!isValid) {
                console.log(f + ':' + resource + ' not found as target of default value expression');
            }

            return isValid;
        }),
        ([fieldName, exp]) =>
            fromEither(
                tryCatch(
                    () => {
                        const [expansionsRequired, dataPaths, valuesetLiterals] = (() => {
                            const impl = getImpl();
                            let compiled = impl.compileExpression(removeMethodHashes(exp));
                            if (compiled.type === 'parse_failure') {
                                throw new Error(compiled.msg);
                            }
                            return [
                                compiled.getExpansionsWithAll(),
                                compiled.getPathsWithAll(),
                                compiled.getValueSetLiterals(),
                            ] as [string[], string[], string[]];
                        })();
                        return {
                            expression: exp,
                            fieldName,
                            expansionsRequired,
                            dataPaths,
                            valuesetLiterals,
                        };
                    },
                    (e: Error) => {
                        // prints errors for the failed SPEL compilation
                        console.error('Error parsing SPEL entityConfig validation expression', e);
                        return e;
                    },
                ).chain(setupValuesetFieldsRequired(viewConfig, resource, exp)),
            ),
    );
};

export const getCalcValueConfigMetaData = (
    resource: string,
    viewConfiguration: Option<string>,
    viewConfig: ViewConfig,
): Option<ViewCalcValueExpressionMetaData[string]> => {
    const x = viewConfiguration.map(parseConfig).map((e) => e.map((c) => c.calcValueExpressions));
    const currentViewExpressions = x
        // prints errors for the failed configs
        .map((e) =>
            e.mapLeft((error) => {
                console.error(parsingOrValidationErrMsg(error));
                return error;
            }),
        )
        .chain(fromEither)

        .map((v) => {
            return getMetadataFromCalcExpressions(v, resource, viewConfig);
        });
    return currentViewExpressions.map((viewExpressions) =>
        Object.fromEntries(
            viewExpressions.map((e) => {
                return [e.fieldName, e];
            }),
        ),
    );
};

export const getAllCalcValueConfigMetaData = (viewConfig: ViewConfig) => {
    const validConfigs = Object.entries(viewConfig.views).reduce((prev, [viewName, view]) => {
        const viewConfiguration = fromEither(
            // this should take viewName
            tryCatch(() => getViewConfiguration(viewConfig, viewName)).mapLeft((e) => console.log(e)),
        ).chain(fromNullable);
        return getCalcValueConfigMetaData(view.entity, viewConfiguration, viewConfig).fold(prev, (res) => {
            prev[viewName] = res;
            return prev;
        });
    }, {} as ViewCalcValueExpressionMetaData);
    return validConfigs;
};
export const VIEW_CALC_VALUE_EXPS_KEY = 'viewCalcValues';

export const viewCalcValueMetaDataEpic: Epic<RootAction, RootAction, RootState, Services> = (
    action$,
    state$,
    services,
) =>
    action$.pipe(
        filter(isActionOf(loadSuccess)),
        map(({ payload: { viewConfig } }) => {
            return getAllCalcValueConfigMetaData(viewConfig);
        }),
        tap((exps) => {
            getStorage().setItem(VIEW_CALC_VALUE_EXPS_KEY, JSON.stringify(exps));
        }),
        map((e) => {
            return set(e);
        }),
    );

export const useOverriddenViewCalctValues = (viewName: string) => {
    const viewConfig = useViewConfig();
    const resource = viewConfig.views[viewName].entity;

    const overrideConfiguredContext = useContext(expressionTesterProvidedViewConfigurationContext);
    const overriddenCalcValueExpressions = overrideConfiguredContext?.config?.[viewName]?.calcValueExpressions;

    return useMemo(() => {
        if (!overriddenCalcValueExpressions) {
            return undefined;
        }
        return getCalcValueConfigMetaData(
            resource,
            some(
                JSON.stringify({
                    calcValueExpressions: overriddenCalcValueExpressions,
                }),
            ),
            viewConfig,
        ).toUndefined(); // not really handling the error here.
    }, [overriddenCalcValueExpressions, viewConfig, resource]);
};
