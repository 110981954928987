import ReCAPTCHA from 'react-google-recaptcha';
import React, { useCallback, useRef, useEffect } from 'react';
import { WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { getRecaptchaSiteKeySelector } from 'util/applicationConfig';
import { useTheme } from '@material-ui/core';
import { useLocale } from 'i18n/useMessages';
import { useAppSelector } from 'reducers/rootReducer';

// const recaptchaRef = React.createRef();

// onSubmit = () => {
//   const recaptchaValue = recaptchaRef.current.getValue();
//   this.props.onSubmit(recaptchaValue);
// }
// render() {
//   return (
//     <form onSubmit={this.onSubmit} >
//       <ReCAPTCHA
//         ref={recaptchaRef}
//         sitekey="Your client site key"
//         onChange={onChange}
//       />
//     </form>
//   )
// }

interface RecaptchaInputProps {
    input: WrappedFieldInputProps;
    meta: WrappedFieldMetaProps;
    siteKey?: string;
}
const RecaptchaInput: React.FC<RecaptchaInputProps> = (props) => {
    const {
        input: { onChange, value },
        meta: { submitFailed },
        siteKey: fieldLevelRecaptchaSiteKey,
    } = props;
    const appLevelRecaptchaSiteKey = useAppSelector(getRecaptchaSiteKeySelector);
    const siteKey = fieldLevelRecaptchaSiteKey || appLevelRecaptchaSiteKey;
    const theme = useTheme();

    const recaptchaRef = useRef<{
        reset(): void;
    }>();

    const unset = useRef(() => onChange(''));
    useEffect(() => {
        unset.current();
    }, []);
    useEffect(() => {
        if (!value) {
            recaptchaRef.current?.reset();
        }
    }, [value]);
    const handleChange = useCallback(
        (key: string) => {
            onChange(key);
        },
        [onChange],
    );

    const locale = useLocale();
    if (!siteKey) {
        return (
            <div>
                <p style={{ color: theme.palette.error.dark }}>
                    A ReCAPTCHA sitekey has not been configured for this site.
                </p>
            </div>
        );
    }
    return <ReCAPTCHA hl={locale} ref={recaptchaRef} sitekey={siteKey} onChange={handleChange} />;
};
export default RecaptchaInput;
