import { formContext } from 'bpm/components/TaskDetail/TaskForm/FormContext';
import { stagedFormDefinitionContext } from 'expression-tester/bpm-form';
import { useSingleKeyCachingEvaluator } from 'expressions/Provider/hooks/useKeyCachingEval';
import { flowablePreprocessValuesForEval } from 'expressions/formValidation';
import { useContext, useMemo } from 'react';
import useEntities from 'util/hooks/useEntities';
import useValueSets from 'util/hooks/useValueSets';
import { useEvaluatorInEntityContext } from '../useEvaluateInEntityContext';
import merge from 'lodash/merge';
import { casetivityViewContext } from 'util/casetivityViewContext';
import useViewConfig from 'util/hooks/useViewConfig';

type Options<DefaultType> =
    | {
          throwOnException: false;
          defaultOnException: DefaultType;
      }
    | {
          throwOnException: true;
      };

export const useEvaluatorInFormContext = <Type>(
    expression: string,
    options: Options<Type>,
): ((patchValues?: {}) => Type) => {
    const { currentViewContext } = useContext(casetivityViewContext);
    const taskFormContext = useContext(formContext);
    const flowableFormDefinitionContext = useContext(stagedFormDefinitionContext);
    const maybeBpmStyleFormDefinitionFields = flowableFormDefinitionContext?.formDefinition?.fields ?? null;
    const isTaskFormContext = currentViewContext === 'bpm';

    const getEntityFormContextResult = useEvaluatorInEntityContext(isTaskFormContext ? '' : expression, {
        useLiveValues: true,
        ...options,
    });

    /**
     * TODO: not handled: report form context
     * The issue is null initializing requires knowing all the fields in our form, and we don't use stagedFormDefinitionContext in Report Forms (although we could - we just don't have an editor there.)
     *
     * When we use our dynamic forms for that, then we will be all set.
     */
    const entities = useEntities();
    const valueSets = useValueSets();
    const viewConfig = useViewConfig();

    const bpmEvaluator = useSingleKeyCachingEvaluator(expression);
    const getBpmFormContextResult = useMemo(() => {
        if (!isTaskFormContext) {
            return null;
        }

        const processedValues = flowablePreprocessValuesForEval(
            taskFormContext.fieldValues,
            maybeBpmStyleFormDefinitionFields ?? [],
            entities,
            valueSets,
            viewConfig,
        );
        return (patchValues: {} = {}) => bpmEvaluator(merge({}, processedValues, patchValues));
    }, [
        bpmEvaluator,
        taskFormContext,
        entities,
        valueSets,
        maybeBpmStyleFormDefinitionFields,
        isTaskFormContext,
        viewConfig,
    ]);

    return getBpmFormContextResult ? getBpmFormContextResult : getEntityFormContextResult;
};

export const useEvaluateExpressionInFormContext = <Type>(expression: string, options: Options<Type>): Type => {
    const evaluator = useEvaluatorInFormContext(expression, options);
    return useMemo(evaluator, [evaluator]);
};
