import React, { FunctionComponent, useCallback } from 'react';
import { useForm, Controller, FormProvider, useFormContext, Resolver } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { makeStyles, Theme, createStyles, Button, TextField } from '@material-ui/core';
import useViewConfig from 'util/hooks/useViewConfig';
import AutocompleteSpelEditor from 'ace-editor/LazyFullFeaturedSpelEditor';
import FieldPath from 'layout-editor/add-field/components/FieldPath';
import { useValidationResolver } from '../ViewDefinitionConfig/EditExpression';
import { UpdateMeta } from 'expression-tester/entity-form/ViewDefinitionConfig/EditExpression';
import { useFormFieldWarning } from './useFormFieldWarning';
import getTypeAtCursor from 'ace-editor/util/getTypeAtCursor';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        error: {
            color: theme.palette.error.dark,
        },
        warning: {
            color: theme.palette.warning.dark,
        },
    }),
);

export interface ExpressionData {
    expression: string;
    compileSuccess: boolean;
    methodsAndFunctions: string[];
    fieldPath: string;
}

interface EditExpressionProps {
    isForCreate: boolean;
    viewName?: string;
    initialValues?: Pick<ExpressionData, 'expression' | 'fieldPath'> &
        Partial<Pick<ExpressionData, 'compileSuccess' | 'methodsAndFunctions'>>;
    onSubmit: (data: ExpressionData) => void;
    widgetMapsToSourceInsteadOfId?: boolean;
}

// for Entity forms only
const DefaultValueExpressionForm: FunctionComponent<EditExpressionProps> = (props) => {
    const classes = useStyles(props);
    const { viewName, onSubmit } = props;
    const viewConfig = useViewConfig();
    const rootEntity = viewConfig.views[viewName].entity;
    const methods = useFormContext<ExpressionData>();
    const { errors } = methods;
    const { warning, triggerWarning } = useFormFieldWarning<FormData, string, string | undefined>({
        name: 'expression',
        validate(value) {
            if (errors && Object.keys(errors).length > 0) {
                return;
            }

            const resolvedType = getTypeAtCursor(viewConfig, rootEntity)(value);
            if (resolvedType._type === 'map' && '__className' in resolvedType) {
                return `This expression does not evaluate to a primitive value.`;
            }
        },
    });
    return (
        <>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <label>
                    <b>Expression *</b>
                    <Controller
                        rules={{ required: 'Provide an expression' }}
                        as={AutocompleteSpelEditor as any}
                        defaultValue={props.initialValues?.['expression']}
                        name="expression"
                        control={methods.control as any}
                        hideDocs
                        onChange={triggerWarning}
                    />
                </label>
                <ErrorMessage errors={errors} name="expression" />
                <pre className={classes.error}>{errors['methodsAndFunctions']}</pre>
                <pre className={classes.error}>{errors['fieldsRequired']}</pre>
                {warning && <pre className={classes.warning}>{warning}</pre>}
                {props.isForCreate ? (
                    <FieldPath
                        required
                        allowPropertiesOnManys={false}
                        defaultValue={props.initialValues?.fieldPath}
                        resource={rootEntity}
                        depth={3}
                        appendId
                        appendIds
                    />
                ) : (
                    // For non-create views, where data already exists, we can only provide default values for non-data fields
                    // (unpersisted fields.)
                    // Then we can map these onto the data if we want, using calc expressions.
                    <Controller
                        name="fieldPath"
                        control={methods.control}
                        defaultValue={props.initialValues?.fieldPath ?? ''}
                        as={TextField}
                        fullWidth
                        margin="normal"
                        label="Unpersisted Calc Name"
                        error={Boolean(methods.errors?.['fieldPath'])}
                        helperText={methods.errors?.['fieldPath'] ?? null}
                    />
                )}
                <Button color="primary" variant="contained" disabled={Object.keys(errors).length > 0} type="submit">
                    Save
                </Button>
            </form>
            <UpdateMeta />
        </>
    );
};

const WrappedDefaultValueForm: FunctionComponent<{
    viewName: string;
    initialValues?: Pick<ExpressionData, 'expression' | 'fieldPath'> &
        Partial<Pick<ExpressionData, 'compileSuccess' | 'methodsAndFunctions'>>;
    onSubmit: (data: ExpressionData) => void;
    isForCreate: boolean;
}> = ({ viewName, initialValues, onSubmit, isForCreate }) => {
    const viewConfig = useViewConfig();
    const expressionResolver = useValidationResolver(viewName, viewConfig);
    const resolver: Resolver<ExpressionData> = useCallback(
        async (data: ExpressionData) => {
            if (!isForCreate) {
                // Don't allow data fields on creates
                if (!data.fieldPath) {
                    return {
                        errors: {
                            fieldPath: 'An unpersisted field is required.',
                        },
                        values: data,
                    } as any;
                }
                if (!data.fieldPath.startsWith('_')) {
                    return {
                        errors: {
                            fieldPath:
                                'On views with existing data (e.g. EDIT instead of CREATE), default values can only be set on unpersisted fields (these begin with "_")',
                        },
                        values: data,
                    } as any;
                }
            }

            return await expressionResolver(data);
        },
        [expressionResolver, isForCreate],
    );
    const methods = useForm<ExpressionData>({
        resolver,
        defaultValues: initialValues,
        mode: 'onChange',
    });

    return (
        <FormProvider {...methods}>
            <DefaultValueExpressionForm
                isForCreate={isForCreate}
                viewName={viewName}
                initialValues={initialValues}
                onSubmit={onSubmit}
            />
        </FormProvider>
    );
};
export default WrappedDefaultValueForm;
