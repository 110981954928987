import { allowsEdit } from 'components/generics/utils/viewConfigUtils';
import { useMemo } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';

export const useUserCanEditViewDefs = () => {
    const viewConfig = useViewConfig();
    const roles = viewConfig?.user?.roles;
    const isSuper = useMemo(() => roles?.includes('ROLE_SUPER'), [roles]);
    const allowedToEditViewDefs = useMemo(() => allowsEdit(viewConfig?.entities?.ViewDef?.accessLevel), [viewConfig]);
    const userHasViewDefEditAccess = isSuper || allowedToEditViewDefs;
    return userHasViewDefEditAccess;
};
