import React, { useState } from 'react';
import Draggable, { DraggableProps } from 'react-draggable';
import { LayoutAction } from './layoutReducer';

const elementIsInChain = (elementToTraverse, elementToFind) => {
    if (elementToTraverse === elementToFind) {
        return elementToFind;
    }
    if (elementToTraverse.parentElement) {
        return elementIsInChain(elementToTraverse.parentElement, elementToFind);
    }
    return false;
};

interface DraggableSourceProps {
    dispatch: React.Dispatch<LayoutAction>;
    targetRef: React.MutableRefObject<HTMLDivElement>;
    onDrag?: DraggableProps['onDrag'];
    onStop?: DraggableProps['onStop'];
}
const DraggableSource: React.SFC<DraggableSourceProps> = ({
    targetRef,
    dispatch,
    onDrag,
    onStop,
    children,
    ...rest
}) => {
    // item is not visibile if it is inserted.. as the acutual grid item should be displayed then
    const [inserted, setInserted] = useState(false);
    const onDragOverwrite = (e, data) => {
        if (onDrag) onDrag(e, data);
        const target = elementIsInChain(e.target, targetRef.current);
        if (!target && inserted) {
            dispatch({ type: 'clearTemp' });
            setInserted(false);
            const placeHolder: HTMLDivElement | null = document.querySelector('.react-grid-placeholder');
            if (placeHolder) placeHolder.style.transform = 'translate(-8000px, 0px)';
            return;
        }
        if (target && !inserted) {
            dispatch({
                h: 2,
                w: 2,
                type: 'addTemp',
                mouseEvent: { clientX: e.clientX, clientY: e.clientY },
                content: React.Children.only((React.Children.only(children) as any).props.children),
            });
            setInserted(true);
            return;
        }
    };
    const onStopOverwrite = (e, data) => {
        if (onStop) onStop(e, data);
        if (inserted) {
            dispatch({ type: 'finaliseTemporaryItem' });
            setInserted(false);
        } else {
            dispatch({ type: 'clearTemp' });
        }
    };
    return (
        <div style={{ visibility: inserted ? 'hidden' : 'visible' }}>
            <Draggable onDrag={onDragOverwrite} onStop={onStopOverwrite} {...rest} position={{ x: 0, y: 0 }}>
                {children}
            </Draggable>
        </div>
    );
};

export default DraggableSource;
