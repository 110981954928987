import { MenuItem } from '@material-ui/core';
import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { GetItemPropsOptions } from 'downshift';
import Message from 'i18n/components/Message';
import filterEntityByQueryRepresentation from 'isomorphic-query-filters/filterEntityByQueryRepresentation';
import React, { useMemo } from 'react';
import { useAppSelector } from 'reducers/rootReducer';
import { createSelector } from 'reselect';
import useViewConfig from 'util/hooks/useViewConfig';

const OfflineDropdown: React.FC<{
    resource: string;
    setItemCount: (count: number) => void;
    inputValue: string;
    filter: {};
    refEntityDisplayNamePlural: string;
    getItemProps: (options: GetItemPropsOptions<any>) => any;
    highlightedIndex: number;
    selectedItem: any;
}> = ({ getItemProps, highlightedIndex, selectedItem, resource, filter, inputValue }) => {
    const viewConfig = useViewConfig();
    const getEntities = useMemo(() => createGetEntities(), []);
    const recordsSelector = useMemo(() => {
        return createSelector(getEntities, (entities) => {
            return Object.values(entities[resource] ?? {}).filter((record: any) => {
                return filterEntityByQueryRepresentation(viewConfig)(filter)(record, entities);
            });
        });
    }, [getEntities, viewConfig, filter, resource]);

    const allItems = useAppSelector(recordsSelector) as { id?: string; title: string }[];
    // 5 per page.
    let items = allItems.filter((i) => i.title?.toLowerCase()?.includes(inputValue?.toLowerCase())).slice(0, 5);
    const total = allItems.length;

    if (!items.length) {
        return (
            <MenuItem component="div" aria-live="polite" disabled key="status">
                <Message id="list.noResults" dm="No results found" />
            </MenuItem>
        );
    }
    return (
        <>
            {items
                .map((record, index) => (
                    <MenuItem
                        key={record.id}
                        {...getItemProps({
                            item: record,
                            index,
                            selected: highlightedIndex === index,
                            isSelected: selectedItem && selectedItem.id === record.id,
                        })}
                        component="div"
                    >
                        {record.title}
                    </MenuItem>
                ))
                .concat([
                    <MenuItem component="div" aria-live="polite" key="status" disabled>
                        Showing {items.length} out of {total} result
                        {total === 1 ? '' : 's'}.
                    </MenuItem>,
                ])}
        </>
    );
};

export default OfflineDropdown;
