import {
    Dialog,
    DialogTitle,
    DialogContent,
    FormControl,
    InputLabel,
    Select,
    ListSubheader,
    MenuItem,
    DialogActions,
    Button,
} from '@material-ui/core';
import { themeOverrideContext } from 'components/layouts/ThemeOverrideProvider';
import produce from 'immer';
import { LayoutStateItem } from 'layout-editor/demo/layoutReducer';
import React, { useContext, useMemo, useState } from 'react';
import { View, ViewField } from 'reducers/ViewConfigType';

interface Destination {
    type: 'views' | 'tabs';
    name: string;
}
interface FieldLocation {
    type: 'views' | 'tabs' | null;
    name: string | null;
}

interface FieldSearchResult {
    location: FieldLocation;
    notIn: {
        views: string[];
        tabs: string[];
    };
}

const findFieldInViewOrTab = (view: View, parsedDef, gridKey: string): FieldSearchResult => {
    const location = { type: null, name: null };
    const notIn = { views: [], tabs: [] };
    const searchFields = (fields: Record<string, ViewField>, parentKey, parentType: 'views' | 'tabs') => {
        if (parentKey === gridKey) {
            return { found: true, location: { type: parentType, name: parentKey } };
        }
        return { found: false };
    };
    if (view?.fields) {
        const result = searchFields(view.fields, view.name, 'views');
        if (result.found) {
            location.type = result.location.type;
            location.name = result.location.name;
        } else {
            notIn.views.push(view.name);
        }
    }
    const tabs = view.tabs || {};
    for (const tabKey in tabs) {
        const tab = tabs[tabKey];
        if (tab?.fields) {
            const result = searchFields(tab.fields, tabKey, 'tabs');
            if (result.found) {
                location.type = result.location.type;
                location.name = result.location.name;
            } else {
                notIn.tabs.push(tabKey);
            }
        }
    }
    return { location, notIn };
};

export const moveField = (view: View, fieldToMove, destination: Destination) => {
    const { field: fieldDefinition } = fieldToMove;
    const newView = produce(view, (draft) => {
        const originalDef = JSON.parse(fieldDefinition['data-originaldefinition']);
        const { field, row, column } = originalDef;
        const newDef = { ...originalDef, key: fieldDefinition.content.key };
        const removeField = (fields) => {
            Object.keys(fields).forEach((key) => {
                if (fields[key].field === field && fields[key].key === fieldDefinition?.['content'].key) {
                    delete fields[key];
                    return;
                }
                if (fields[key].field === field && fields[key].row === row && fields[key].column === column) {
                    delete fields[key];
                    return;
                }
            });
        };
        const addField = (fields, newFieldDef) => {
            if (field in fields) {
                fields[`${field}_`] = newFieldDef;
            } else {
                fields[field] = newFieldDef;
            }
        };
        if (draft?.fields) {
            removeField(draft.fields);
        }
        if (draft?.tabs) {
            Object.values(draft.tabs).forEach((tab) => {
                if (tab.fields) {
                    removeField(tab.fields);
                }
            });
        }
        if (destination.type === 'views') {
            addField(draft.fields, newDef);
        } else if (destination.type === 'tabs' && draft.tabs[destination.name]) {
            addField(draft.tabs[destination.name].fields, newDef);
        }
    });
    return newView;
};

export const MoveFieldToOtherGridDialog = React.memo(
    (props: {
        fieldToMove: { gridKey: string; field: LayoutStateItem };
        view: View;
        onViewChange: (arg: { view: View }) => void;
        open: boolean;
        setOpen: (arg: boolean) => void;
        refreshTab: (arg: string) => void;
        incrementGridChangeKey: () => void;
    }) => {
        const { view, onViewChange, open, setOpen, fieldToMove, refreshTab, incrementGridChangeKey } = props;
        const [destination, setDestination] = useState<Destination>({
            name: '',
            type: 'views',
        });

        const handleClose = () => {
            setDestination({ name: '', type: 'views' });
            setOpen(false);
        };

        const handleSubmit = () => {
            const updatedView = moveField(view, fieldToMove, destination);
            onViewChange({ view: updatedView });
            if (location.type === 'tabs') {
                refreshTab(location.name);
            }
            if (destination.type === 'tabs') {
                refreshTab(destination.name);
            }
            incrementGridChangeKey();
            handleClose();
        };
        const parsedDef = useMemo(() => {
            const originalDef = JSON.parse(fieldToMove?.field?.['data-originaldefinition'] ?? null) ?? {};
            const contentKey = fieldToMove?.field?.i;
            return { ...originalDef, key: contentKey };
        }, [fieldToMove]);
        const { location, notIn } = findFieldInViewOrTab(view, parsedDef, fieldToMove?.gridKey);

        const { fieldVariant } = useContext(themeOverrideContext);

        return (
            <>
                <Dialog open={open} onClose={handleClose}>
                    {/* todo: configured labels like '$reports' do not display nicely */}
                    <DialogTitle>
                        Move "{parsedDef.label ?? parsedDef.field}" from "
                        {location.type === 'tabs' ? view.tabs[location.name].label : location.name}"
                        {location.type === 'tabs' ? ' Tab' : ' Main Section'} to a new grid:
                    </DialogTitle>
                    <DialogContent>
                        <FormControl fullWidth margin="normal">
                            <InputLabel shrink={true} id="select-view-label">
                                Destination Grid:
                            </InputLabel>
                            <Select
                                variant={fieldVariant}
                                labelId="select-view-label"
                                value={destination?.name}
                                onChange={(e) => {
                                    const selection = e.target.value as string;
                                    const name: string = selection;
                                    const locationType = notIn.views.includes(selection) ? 'views' : 'tabs';
                                    setDestination({ type: locationType, name: name });
                                }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                placeholder="Select from available grids:"
                                label={'Destination Grid:'}
                            >
                                {notIn.views.length > 0 && <ListSubheader>Main Section</ListSubheader>}
                                {notIn.views.map((location, index) => (
                                    <MenuItem key={index} value={location}>
                                        {location}
                                    </MenuItem>
                                ))}
                                {notIn.tabs.length > 0 && <ListSubheader>Tabs</ListSubheader>}
                                {notIn.tabs.map((location, index) => (
                                    <MenuItem key={index} value={location}>
                                        {view.tabs[location]?.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary" variant="contained">
                            Cancel
                        </Button>
                        <Button
                            onClick={handleSubmit}
                            variant="contained"
                            color="primary"
                            disabled={destination.name === ''}
                        >
                            Move
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    },
);
