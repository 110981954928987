import { createGetEntities } from 'components/generics/form/EntityFormContext/util/getEntities';
import { getValuesetConceptIdsFromCodes } from 'expressions/contextUtils';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { RootState, useAppSelector } from 'reducers/rootReducer';
import { change, formValueSelector, WrappedFieldInputProps, WrappedFieldMetaProps } from 'redux-form';
import { createSelector } from 'reselect';
import { createGetValueSets } from 'components/generics/form/EntityFormContext/util/getEntities';
import useViewConfig from 'util/hooks/useViewConfig';
import { getValueSetForFieldExpr } from 'components/generics/utils/viewConfigUtils';
import { useFormState, useForm } from 'react-final-form';

/*
    The point of this component is to:
    (if 'source' for this field is 'fooId')
    1. Read value from 'fooCode' if 'fooId' is not a present value
    2. Unset 'fooCode' elsewhere in the form when we change values using the widget.
*/

const useCode = (form: string, codeSource: string) => {
    const codeSelector = useMemo(() => formValueSelector(form), [form]);
    return useAppSelector((state: RootState) => codeSelector(state, codeSource));
};
const useConceptIdFromCode = (valueset: string, code: string) => {
    const getEntities = useMemo(() => createGetEntities(), []);
    const getValuesets = useMemo(createGetValueSets, []);
    const selector = useMemo(
        () =>
            createSelector(
                (state: RootState) => getEntities(state),
                getValuesets,
                (entities, valuesets) => {
                    return getValuesetConceptIdsFromCodes(valuesets, entities)(valueset, code);
                },
            ),
        [getEntities, getValuesets, code, valueset],
    );
    const conceptids = useAppSelector(selector);
    if (conceptids.length > 0) {
        return conceptids[0];
    }
    return undefined;
};
const useValuesetCode = (resource: string, source: string) => {
    const viewConfig = useViewConfig();
    return useMemo(
        () =>
            getValueSetForFieldExpr(
                viewConfig,
                resource,
                source.endsWith('Id') ? source.slice(0, -2) : source,
                'TRAVERSE_PATH',
            ),
        [resource, source, viewConfig],
    );
};

const useClearCode = (form: string, codeSource: string) => {
    const dispatch = useDispatch();
    return useCallback(() => {
        dispatch(change(form, codeSource, null));
    }, [dispatch, form, codeSource]);
};

const getCodeSource = (source: string) => {
    const codeSource = ((source.endsWith('Id') ? source.slice(0, -1 * 'Id'.length) : source) + 'Code')
        .split('.')
        .join('_~_');

    return codeSource;
};

const useMappedInput = (args: { code: string; input: any; clearCode?: () => void; idFromCode?: string }) => {
    const { code, input, clearCode, idFromCode } = args;
    const mappedInput: WrappedFieldInputProps = useMemo(() => {
        /*
            If there's a 'code' in the form, and no value, use the id looked up by code
            (this should only happen in initial conditions, because this component
                also should intercept changed, and clear 'code' when that happens)
        */
        if (code) {
            return {
                ...input,
                onChange: (eOrV, name) => {
                    clearCode();
                    input.onChange(eOrV, name);
                },
                onBlur: (eOrV, name) => {
                    clearCode();
                    input.onBlur(eOrV, name);
                },
                value: idFromCode && !input.value ? idFromCode : input.value,
            };
        }
        return input;
    }, [input, idFromCode, code, clearCode]);
    return mappedInput;
};
const ValuesetSelectForSearchController = <
    T extends {
        source: string;
        meta: WrappedFieldMetaProps;
        resource: string;
        input: WrappedFieldInputProps;
    },
>({
    children,
    ...props
}: T & { children: (props: Omit<T, 'children'>) => JSX.Element }) => {
    const { source, meta, input, resource } = props;
    const codeSource = getCodeSource(source);

    const code = useCode(meta.form, codeSource);

    const valuesetCode = useValuesetCode(resource, source);
    const idFromCode = useConceptIdFromCode(valuesetCode, code);

    const clearCode = useClearCode(meta.form, codeSource);

    const mappedInput: WrappedFieldInputProps = useMappedInput({
        input,
        idFromCode,
        code,
        clearCode,
    });
    return children({ ...props, input: mappedInput });
};

export const FFValuesetSelectForSearchController = <
    T extends {
        source: string;
        meta: WrappedFieldMetaProps;
        resource: string;
        input: WrappedFieldInputProps;
    },
>({
    children,
    ...props
}: T & { children: (props: Omit<T, 'children'>) => JSX.Element }) => {
    const { source, meta, input, resource } = props;
    const codeSource = getCodeSource(source);
    const formState = useFormState();
    const form = useForm();

    // get the code from final form.
    /**
     * get the value of 'codeSource'
     */
    const code = formState.values[codeSource];
    // const code = useCode(meta.form, codeSource);

    const valuesetCode = useValuesetCode(resource, source);
    const idFromCode = useConceptIdFromCode(valuesetCode, code);

    // clear the code from final form

    const clearCode = () => {
        form.change(codeSource, null);
    };
    // const clearCode = useClearCode(meta.form, codeSource);

    const mappedInput: WrappedFieldInputProps = useMappedInput({
        input,
        idFromCode,
        code,
        clearCode,
    });
    return children({ ...props, input: mappedInput });
};

export default ValuesetSelectForSearchController;
