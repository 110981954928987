import { Either, left, right } from 'fp-ts/lib/Either';
import { EvaluationOptions } from './formValidation/definitions.d';
import { CasetivityConfigRootViewContextVariable } from 'util/casetivityViewContext';
import { createRootContext } from '@mkanai/casetivity-shared-js/lib/spel/evaluation-context';
import getImpl from './Provider/implementations/getImpl';
import { EvalOptions } from 'ts-spel/lib/lib/Evaluate';

type SpelNodeType =
    | 'function' // <- just added this
    | 'assign'
    | 'boolean'
    | 'compound'
    | 'elvis'
    | 'method'
    | 'indexer'
    | 'list'
    | 'map'
    | 'null'
    | 'number'
    | 'op-and'
    | 'op-dec'
    | 'op-divide'
    | 'op-eq'
    | 'op-ge'
    | 'op-gt'
    | 'op-inc'
    | 'op-le'
    | 'op-lt'
    | 'op-minus'
    | 'op-modulus'
    | 'op-multiply'
    | 'op-ne'
    | 'op-not'
    | 'op-or'
    | 'op-plus'
    | 'op-power'
    | 'projection'
    | 'selection'
    | 'string'
    | 'ternary'
    | 'variable'
    | 'property';
/* | 'Abstract' */
// not sure why they assigned abstract as a node type

interface SpelActiveContext {
    peek(): any;
}
interface SpelState {
    activeContext: SpelActiveContext;
}
type activeContext = any;

export interface SpelNode {
    _type: SpelNodeType;
    getName(): string;
    getType(): SpelNodeType;
    setType(nodeType: SpelNodeType): void;
    getChildren(): SpelNode[];
    addChild(childNode: SpelNode): void;
    getParent(): SpelNode | null;
    setParent(parentNode: SpelNode): void;
    // hopefully these two below returntypes are the same. I'm not sure yet though.
    getContext(state: SpelState): activeContext | ReturnType<SpelState['activeContext']['peek']>;
    setContext(nodeContext: activeContext): void;
    getStartPosition(): number /* return (position >> 16); */;
    getEndPosition(): number /* (position & 0xffff) */;

    // must be overridden
    getValue(): void;

    toString(): string;
}

export type SpelOptions = {
    viewContext?: CasetivityConfigRootViewContextVariable;
    dateFormat?: string;
    backref?: {
        id: string;
        entityType: string;
        path: string;
    };
};

export type javaEquivPrimative = string | null | boolean | number | any[];
export const evaluateExpression = (
    expression: string,
    context: object = {},
    localVariablesAndMethods: object = {},
    options?: EvalOptions,
): javaEquivPrimative => {
    const rootContext = createRootContext(context);
    const impl = getImpl();
    const compiled = impl.compileExpression(expression);
    if (compiled.type === 'parse_failure') {
        throw new Error(compiled.msg);
    }
    const res = compiled.evaluate(rootContext, createRootContext(localVariablesAndMethods), options);
    if (res.type === 'evaluation_failure') {
        throw new Error(res.msg);
    }
    return res.result;
};

export interface SpelCompiledExpression {
    _compiledExpression: SpelNode; // ast
    eval(context: {}, locals: {}): javaEquivPrimative;
}

export const processExpr = (options: EvaluationOptions) => (expression: string) => {
    if (options.stripHashes !== false) {
        return expression.split(/#(?!this)/).join('');
    }
    return expression;
};

export const curriedEvalExpression = (
    _expression: string,
    options: EvaluationOptions = {},
): Either<Error, (context: {}, localVariablesAndMethods: {}) => Either<Error, javaEquivPrimative>> => {
    const expression = processExpr(options)(_expression);
    const impl = getImpl();

    const compiled = impl.compileExpression(expression);
    if (compiled.type === 'parse_failure') {
        return left(new Error(compiled.msg));
    }
    const evaluate = (context: {}, localVariablesAndMethods: {}): Either<Error, javaEquivPrimative> => {
        const rootContext = createRootContext(context);
        const result = compiled.evaluate(rootContext, { ...rootContext, ...localVariablesAndMethods });
        if (result.type === 'evaluation_failure') {
            return left<Error, javaEquivPrimative>(new Error(result.msg));
        }
        return right<Error, javaEquivPrimative>(result.result);
    };
    return right(evaluate);
};
