import get from 'lodash/get';

const getHtmlTableFromList = <Entry extends Record<string, unknown>>(
    list: Entry[],
    headers: (keyof Entry)[],
    headerNames: string[],
    tableStyle?: string,
    thStyle?: string,
    tdStyle?: string,
    thClassLast?: string,
) => {
    const tableHtml = (() => {
        let res: string;
        if (!tableStyle) {
            res = '<table>';
        } else {
            res = '<table style="' + tableStyle + '">';
        }
        res += '<thead><tr>';
        headerNames.forEach((headerName, i, arr) => {
            const isLast = i === arr.length - 1;
            const opener = isLast && thClassLast ? `<th class="${thClassLast}"` : '<th ';
            if (!thStyle) {
                res += opener + '>' + headerName + '</th>';
            } else {
                res += opener + ' style="' + thStyle + '">' + headerName + '</th>';
            }
        });
        res += '</tr></thead>';
        if (Array.isArray(list) && list.length > 0) {
            list.forEach((row) => {
                res += '<tr>';
                headers.forEach((header) => {
                    if (!tdStyle) {
                        res += '<td>' + get(row, header) + '</td>';
                    } else {
                        res += '<td style="' + tdStyle + '">' + get(row, header) + '</td>';
                    }
                });
                res += '</tr>';
            });
        } else {
            res += '<tr><td>No results</td></tr>';
        }
        res += '</table>';
        return res;
    })();

    const cards: string =
        Array.isArray(list) && list.length > 0
            ? list
                  .map(
                      (row) =>
                          `<li style="padding:.5em; margin-bottom: .5em; border: 1px solid black; list-style: none;"><table>${headers
                              .map((header, i) => `<tr><th>${headerNames[i]}</th><td>${get(row, header)}</td></tr>`)
                              .join('')}</table></li>`,
                  )
                  .join('')
            : '<div>No results</div>';

    return (
        '<div class="casetivity-largeonly">' +
        tableHtml +
        `</div><div class="casetivity-smallonly"><ol style="padding-left: 0">${cards}</ol></div>`
    );
};
export default getHtmlTableFromList;
