import {
    ContainerReflection,
    DeclarationReflection,
    ReferenceType,
    ReflectionType,
} from 'typedoc/dist/lib/serialization/schema';

export const getDefById = (id: number) => {
    const innerGetDefById = (doc: DeclarationReflection) => {
        if (doc.id === id) {
            return doc;
        }
        return doc.children?.map((child) => innerGetDefById(child))?.filter(Boolean)?.[0];
    };
    return innerGetDefById;
};

const getFDoc = (doc: ContainerReflection, path: string[]): DeclarationReflection => {
    console.log({
        doc,
    });
    const [head, ...rest] = path;
    if (head !== '#f()') {
        // not interested.
        return null;
    }
    const f = doc.children.flatMap((modul) =>
        modul.children.filter((c) => c.kindString === 'Function' && c.name === 'f'),
    )[0];
    if (!rest?.length) {
        return f;
    }

    const getSignatureReturnType = (node: DeclarationReflection) => {
        if (!(node.kindString === 'Function' || node.kindString === 'Method')) {
            throw new Error('getSignatureReturnType() not allowed for type ' + node.kindString);
        }
        const callSignatures = node.signatures?.filter((sig) => sig.kindString === 'Call signature');
        const declarationReturnType = (
            callSignatures?.find((sig) => sig.type?.type === 'reflection')?.type as ReflectionType
        )?.declaration;
        const referenceReturnId = (callSignatures?.find((sig) => sig.type?.type === 'reference')?.type as ReferenceType)
            ?.id;
        return referenceReturnId ? getDefById(referenceReturnId)(doc) : declarationReturnType;
    };
    const getChildren = (current: DeclarationReflection): DeclarationReflection[] => {
        if (!current) {
            return null;
        }
        const currentlyIsFunctionCall = current.kindString === 'Function' || current.kindString === 'Method';
        if (currentlyIsFunctionCall) {
            const returnType = getSignatureReturnType(current);
            return returnType?.children;
        }
        if (current.kindString === 'Class') {
            return current.children;
        }
        return null;
    };
    let current: DeclarationReflection = f;
    for (let subpath of rest) {
        const currChildren =
            current.kindString === 'Property' && current.type.type === 'reference'
                ? getChildren(getDefById(current.type.id)(doc)) ?? []
                : (current && getChildren(current)) ?? [];
        const type = subpath.endsWith('()')
            ? ('call' as const)
            : subpath.endsWith('(')
            ? ('def' as const)
            : ('property' as const);
        const subpathChild = type === 'call' ? subpath.slice(0, -2) : type === 'def' ? subpath.slice(0, -1) : subpath;
        const child = currChildren.find((c) => c.name === subpathChild);

        if (!child) {
            // // debug
            // console.log("child not found in ", {
            //     currChildren,
            //     subpathChild,
            //     current,
            //     subpath,
            //     type
            // })
            return null;
        }
        if (child.kindString === 'Call signature') {
            const type = child.type;
            if (type.type === 'reference') {
                current = getDefById(type.id)(doc);
            }
        } else if (child.kindString === 'Property' && child.type.type === 'query') {
            current = getDefById(child.type.queryType.id)(doc);
        } else if (child.kindString === 'Method' && type === 'call') {
            if (child.type?.type === 'reference') {
                current = getDefById(child.type.id)(doc);
            } else {
                current = getSignatureReturnType(child);
            }
        } else {
            current = child;
        }
    }
    return current;
};

export default getFDoc;
