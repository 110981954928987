import React, { useReducer, useState } from 'react';
import { MultiRelativeDateField } from './MultiRelativeDateField';
import { Card, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle/AlertTitle';

const CannedDateDemo = () => {
    const [value, setValue] = useState('');
    const [mode, setMode] = useState<'diff' | 'date'>('diff');
    const [key, remount] = useReducer((state) => state + 1, 1);
    return (
        <div>
            <Alert severity="info">
                <AlertTitle>Mode</AlertTitle>
                <div>
                    <RadioGroup
                        value={mode}
                        onChange={(e) => {
                            const newMode = e.target.value as 'diff' | 'date';
                            setValue('');
                            remount();
                            setMode(newMode);
                        }}
                    >
                        <FormControlLabel
                            control={<Radio />}
                            label="Configuration mode (when configuring report forms)"
                            value={'diff'}
                        />
                        <FormControlLabel
                            control={<Radio />}
                            label="User mode (when a form is being run by a user)"
                            value={'date'}
                        />
                    </RadioGroup>
                </div>
            </Alert>
            <Card style={{ margin: '1em' }}>
                <div style={{ padding: '1em' }}>
                    <MultiRelativeDateField
                        key={key}
                        relativeDateAs={mode}
                        label="Date example"
                        value={value}
                        onChange={setValue}
                        meta={{}}
                    />
                </div>
            </Card>
            <Alert severity="info">
                <AlertTitle>Value</AlertTitle>
                <pre>{JSON.stringify(value)}</pre>
            </Alert>
        </div>
    );
};

export default CannedDateDemo;
