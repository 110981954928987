import React, { useMemo, FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import useViewConfig, { OverriddenViewConfigContext } from 'util/hooks/useViewConfig';
import produce from 'immer';
import { View } from 'reducers/ViewConfigType';
import { FormProvider, useForm } from 'react-hook-form';
import { SearchValidations } from 'reducers/searchValidationsReducer';
import NavWarning from 'layout-editor/build-layout/steps/components/NavWarning';
import PopoverRefInput from 'fieldFactory/popovers/PopoverRefInput';
import { GenericPossibleMatchView } from 'components/generics';
import { createMrgListView } from 'injectViews/injectMatchViewsFromMrgViews';
import NavigateNext from '@material-ui/icons/NavigateNext';

interface OverrideSearchValidationsContext {
    [viewName: string]: SearchValidations[0];
}
export const overrideSearchValidationsContext = React.createContext<OverrideSearchValidationsContext>({});

interface Step3MatchProps {
    initialValues: Partial<View>;
    onSubmit: (action: {
        type: 'replace' | 'write';
        payload: Pick<View, 'name' | 'entity' | 'viewType' | 'route'>;
    }) => void;
}
const Step3Match: FunctionComponent<Step3MatchProps> = (props) => {
    const { initialValues, onSubmit: _onSubmit } = props;
    const methods = useForm({
        defaultValues: initialValues,
        mode: 'onBlur',
    });
    const { handleSubmit, watch } = methods;
    const view = watch() as View;
    React.useEffect(() => {
        methods.register({ name: 'recordWithMatchesId' });
        return () => {
            methods.unregister('recordWithMatchesId');
        };
    }, []); // eslint-disable-line
    const onSubmit = ({ recordWithMatchesId, ...data }: any) => {
        _onSubmit({ type: 'write', payload: { ...initialValues, ...data } });
    };
    const viewConfig = useViewConfig();
    const overrideViewConfig = useMemo(() => {
        /*
            Now that we have our match view, we need to generate the MrgList view from it.
        */
        return produce(viewConfig, (draft) => {
            const newMatchView = {
                ...initialValues,
                ...view,
            } as View;
            draft.views[initialValues.name] = newMatchView;
            const newMrgListView = createMrgListView(newMatchView);
            draft.views[newMrgListView.name] = newMrgListView;
            return draft;
        });
    }, [viewConfig, view, initialValues]);
    const value = watch('splitRecordId');
    if (
        !initialValues.viewType ||
        !initialValues.entity ||
        !initialValues.name ||
        !overrideViewConfig.views[initialValues.name]
    ) {
        return <div>Please fill out step 1.</div>;
    }

    return (
        <div>
            <FormProvider {...methods}>
                <div style={{ padding: '1em', margin: '1em' }}>
                    <h2>Step 3: Review</h2>
                    <NavWarning />
                    <PopoverRefInput
                        label="Select record (with matches)"
                        resource={initialValues.entity}
                        reference={initialValues.entity}
                        filterString={`hasPossibleMatches=true`}
                        source="recordWithMatchesId"
                        options={{}}
                        input={{
                            value,
                            onBlur: (v) => {
                                if (typeof v !== 'undefined') {
                                    methods.setValue('splitRecordId', v || null, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                    });
                                }
                            },
                        }}
                        meta={{}}
                    />
                    <OverriddenViewConfigContext.Provider value={overrideViewConfig}>
                        {value && initialValues.name && (
                            <GenericPossibleMatchView
                                viewName={initialValues.name}
                                match={{
                                    params: {
                                        id: value as string,
                                    },
                                }}
                            />
                        )}
                    </OverriddenViewConfigContext.Provider>

                    <div style={{ marginTop: '1em', paddingTop: '1em', width: '100%', textAlign: 'right' }}>
                        <Button id="step3-submit" variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
                            Submit&nbsp;
                            <NavigateNext />
                        </Button>
                    </div>
                </div>
            </FormProvider>
        </div>
    );
};

export default Step3Match;
