import React, { useMemo, useState } from 'react';
import FunctionTypeDoc, { findFunctionOrMethodByName } from 'expression-tester/util/FunctionDoc/FunctionDoc';
import { DeclarationReflection } from 'typedoc';
import { Card, TextField, Typography } from '@material-ui/core';
import useWidth from 'util/hooks/useWidth';
import { pi } from 'expression-tester/util/FunctionDoc/docs';
import FDocumentationRoot from './FTreeView/FDocumentationRoot';

const definitions = require('./output/definitions.json');

const itemContainsText = (item: DeclarationReflection, text: string): boolean => {
    return Boolean(item.name.includes(text) || item.children?.some((el) => itemContainsText(el, text)));
};
const getAllFunctionAndMethodNames = (def: DeclarationReflection) => {
    if (def.kindString === 'Method' || def.kindString === 'Function') {
        return [def.name];
    }
    return def.children?.flatMap((item) => getAllFunctionAndMethodNames(item)) ?? [];
};
const frontendFunctionAndMethodNames = getAllFunctionAndMethodNames(definitions);
const sharedFunctionAndMethodNames = getAllFunctionAndMethodNames(pi as DeclarationReflection);

type SearchResult = DeclarationReflection & {
    __source: 'frontend' | 'shared';
};
const DocumentationPage = () => {
    const [searchText, setSearchText] = useState('');
    const searchResults: SearchResult[] = useMemo(() => {
        const frontend: SearchResult[] = frontendFunctionAndMethodNames.map((name) => {
            return {
                ...findFunctionOrMethodByName(definitions, name),
                __source: 'frontend',
            };
        });
        const shared: SearchResult[] = sharedFunctionAndMethodNames.map((name) => {
            return {
                ...findFunctionOrMethodByName(pi as DeclarationReflection, name),
                __source: 'shared',
            };
        });
        return [...frontend, ...shared].filter((item) => {
            return !searchText || itemContainsText(item, searchText);
        });
    }, [searchText]);
    const width = useWidth();
    return (
        <div>
            <Card style={{ padding: '1em' }}>
                <Typography variant="h5">SPEL Functions</Typography>

                <TextField
                    margin="normal"
                    fullWidth={width === 'xs' || width === 'sm'}
                    label="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                />
            </Card>
            {(!searchText || searchText.toLowerCase() === 'f') && (
                <div style={{ margin: '.5em 0' }}>
                    <FDocumentationRoot />
                </div>
            )}
            <div>
                {searchResults.map(({ name, __source }) => {
                    return (
                        <div style={{ margin: '.5em 0' }}>
                            <FunctionTypeDoc
                                key={name}
                                docJson={__source === 'frontend' ? definitions : pi}
                                functionName={name}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default DocumentationPage;
