import React from 'react';
import ReactList from 'react-list'; // eslint-disable-line import/no-extraneous-dependencies
import DownloadFromLink from '../../../display/components/DownloadFromLink';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import Clear from '@material-ui/icons/Clear';
import { IconButton, FormControl, FormLabel } from '@material-ui/core';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { asyncEventsInProgressContext } from '../../../../bpm/components/TaskDetail/asyncEventCountContext';
import { getOnDrop } from './getOnDrop';
import { RootState } from 'reducers/rootReducer';
import { EvaluateFormattedMessage } from 'i18n/hooks/useEvaluatedFormattedMessage';
import formatError from 'fieldFactory/util/formatError';
import uniqueId from 'lodash/uniqueId';
import acceptFile from './util/acceptFile';
import defaultAcceptError from './util/defaultAcceptError';
import Message from 'i18n/components/Message';
import { EvaluateLabel } from '../aor/FieldTitle';
import parseFileSizeString from './util/parseFileSizeString';

const styles = (theme) => ({
    button: {
        width: 24,
        height: 24,
        padding: 0,
    },
    error: {
        color: theme.palette.error.main,
        fontSize: 'small',
    },
});
const dispatches = {
    enqueueSnackbar: enqueueSnackbarAction,
};
type Dispatches = typeof dispatches;

interface MultipleFileInputProps extends Dispatches {
    disabled: boolean;
    showNotification: any;
    accept?: string;
    acceptError?: string;
    input: {
        onBlur: (value: any[]) => void;
        value: any;
    };
    classes: { button: string; error: string };
    label: string;
    taskId: string;
    source: string;
    meta: {
        error: string;
        touched: boolean;
    };
    maxFileSize?: string;
}

class MultipleFileInput extends React.Component<MultipleFileInputProps, { inputKey: number }> {
    private errorMessageId = uniqueId('file-multierrormessageid-');
    private labelId = uniqueId('file-multiple-label');
    state = {
        inputKey: 1,
    };
    getInputValue = (inputValue = this.props.input.value) => {
        if (inputValue) {
            if (Array.isArray(inputValue)) {
                return inputValue;
            }
            // not sure why this case was added before refactoring... but keeping it in case it was a good reason.
            return [inputValue];
        }
        return [];
    };
    getOnDrop = (
        eventCbs: {
            beforeRequest?: () => void;
            afterRequest?: () => void;
        },
        taskId,
    ) => {
        const { enqueueSnackbar } = this.props;
        return getOnDrop(
            {
                ...eventCbs,
                onResponse: (statusCode) => {
                    const didFail = statusCode < 200 || statusCode >= 300;
                    enqueueSnackbar({
                        message: didFail ? 'Upload failed' : 'Upload succeeded',
                        options: {
                            variant: didFail ? 'error' : 'success',
                        },
                    });
                },
                onNetworkError: () => {
                    enqueueSnackbar({
                        message: 'Network error',
                        options: { variant: 'error' },
                    });
                },
                onSuccessWithData: (dataParsed) => {
                    this.props.input.onBlur([...this.getInputValue(), dataParsed]);
                },
            },
            taskId,
        );
    };

    handleUrlClear = (id) => {
        this.setState(({ inputKey }) => ({ inputKey: inputKey + 1 }));
        const remaining = this.getInputValue().filter((file) => file.id !== id);
        this.props.input.onBlur(remaining);
    };
    render() {
        const { meta, classes, maxFileSize } = this.props;
        const data = this.getInputValue();
        return (
            <EvaluateFormattedMessage>
                {({ translate, evaluateFormattedMessage }) => (
                    <FormControl margin="none" style={{ width: '100%' }}>
                        <EvaluateLabel label={this.props.label}>
                            {({ templatedLabel }) => <FormLabel id={this.labelId}>{templatedLabel}</FormLabel>}
                        </EvaluateLabel>
                        <div style={{ marginTop: '.75em' }}>
                            {!this.props.disabled ? (
                                <input
                                    accept={this.props.accept}
                                    key={this.state.inputKey}
                                    type="button"
                                    aria-labelledby={this.labelId}
                                    value={translate({ id: 'file.choose' })}
                                    onClick={() =>
                                        document.getElementById(this.props.source) &&
                                        document.getElementById(this.props.source)!.click()
                                    }
                                />
                            ) : null}

                            <asyncEventsInProgressContext.Consumer>
                                {({ increment, decrement }) => {
                                    return (
                                        <input
                                            accept={this.props.accept}
                                            aria-describedby={
                                                meta.touched && meta.error ? this.errorMessageId : undefined
                                            }
                                            aria-invalid={Boolean(meta.touched && meta.error)}
                                            key={this.state.inputKey}
                                            type="file"
                                            style={{ display: 'none' }}
                                            id={this.props.source}
                                            onChange={(e) => {
                                                const files = e.target.files;
                                                if (files) {
                                                    let someFailed = false;
                                                    const maxBytes = maxFileSize
                                                        ? parseFileSizeString(maxFileSize)
                                                        : Infinity;

                                                    for (let i = 0; i < files.length; i++) {
                                                        const file = files.item(i);

                                                        if (file.size === 0) {
                                                            const message = translate({
                                                                id: 'file.failure.empty',
                                                                defaultMessage:
                                                                    'File could not be uploaded because the file is empty.',
                                                            });
                                                            alert(message);
                                                            someFailed = true;
                                                        }
                                                        if (maxFileSize && file.size > maxBytes) {
                                                            const message = translate(
                                                                {
                                                                    id: 'file.failure.size',
                                                                    defaultMessage:
                                                                        'File is too large. Upload limit is {0}',
                                                                },
                                                                { '0': maxFileSize },
                                                            );
                                                            alert(message);
                                                            someFailed = true;
                                                        }
                                                        if (this.props.accept && !acceptFile(file, this.props.accept)) {
                                                            if (this.props.acceptError) {
                                                                alert(evaluateFormattedMessage(this.props.acceptError));
                                                            } else {
                                                                alert(defaultAcceptError(file.type, this.props.accept));
                                                            }
                                                            someFailed = true;
                                                        }
                                                    }
                                                    if (!someFailed) {
                                                        this.getOnDrop(
                                                            {
                                                                beforeRequest: increment,
                                                                afterRequest: decrement,
                                                            },
                                                            this.props.taskId,
                                                        )(files as any);
                                                    }
                                                }
                                            }}
                                        />
                                    );
                                }}
                            </asyncEventsInProgressContext.Consumer>
                            {data.length === 0 ? (
                                <span style={{ fontSize: '13px' }}>
                                    &nbsp;
                                    <Message dm="No files chosen" id="file.noFiles" />
                                </span>
                            ) : (
                                <div>
                                    <ReactList
                                        itemRenderer={(index, key) => {
                                            const attachment = data[index];
                                            const fileName = attachment.fileFileName || '<no file name>';
                                            return (
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {/* Don't allow downloads in start-form uploads */}
                                                    {!this.props.taskId ? (
                                                        <b style={{ marginTop: '2px' }}>{fileName}</b>
                                                    ) : (
                                                        <DownloadFromLink
                                                            url={`api/bpm/process-docs/${attachment.id}/raw`}
                                                            fileName={fileName}
                                                            contentType={attachment.mimeType}
                                                        />
                                                    )}
                                                    {!this.props.disabled ? (
                                                        <IconButton
                                                            aria-label={
                                                                'remove file' +
                                                                (attachment.fileFileName
                                                                    ? ` ${attachment.fileFileName}`
                                                                    : '')
                                                            }
                                                            className={this.props.classes.button}
                                                            onClick={() => this.handleUrlClear(attachment.id)}
                                                        >
                                                            <Clear />
                                                        </IconButton>
                                                    ) : null}
                                                </div>
                                            );
                                        }}
                                        length={data.length}
                                        type="uniform"
                                    />
                                </div>
                            )}
                        </div>
                        {meta.touched && meta.error && (
                            <EvaluateFormattedMessage>
                                {({ evaluateFormattedMessage, translate }) => (
                                    <span id={this.errorMessageId} className={classes.error}>
                                        {translate({ id: 'validate.error' })}:{' '}
                                        {evaluateFormattedMessage(formatError(meta.error))}
                                    </span>
                                )}
                            </EvaluateFormattedMessage>
                        )}
                    </FormControl>
                )}
            </EvaluateFormattedMessage>
        );
    }
}

const enhance = compose(
    connect((state: RootState) => {
        return {
            maxFileSize: state.basicInfo?.maxFileSize,
        };
    }, dispatches),
    withStyles(styles),
);

export default enhance(MultipleFileInput);
