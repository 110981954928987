import React, { useContext, useMemo, useState } from 'react';
import useViewConfig from 'util/hooks/useViewConfig';
import { EntityViewConfig } from 'expressions/entityViewConfig/type';

/*
    This exists as a 'state' hook for EntityView config, unless it exists as child to an existing viewConfiguration context.
    Then it is a means through which the component we are in is controlled by the outer context.
*/
export interface ViewConfigurationCtxt {
    setViewConfiguration: (config: EntityViewConfig) => void;
    viewConfiguration: EntityViewConfig;
}
export const viewConfigurationContext = React.createContext<ViewConfigurationCtxt | null>(null);

const useViewConfiguration = (
    viewName: string,
): [EntityViewConfig, (viewConfig: EntityViewConfig) => void, boolean] => {
    const configurationContext = useContext(viewConfigurationContext);
    const viewConfig = useViewConfig();
    const defaultViewConfiguration = viewConfig.views[viewName].config;

    const initialState = useMemo(
        () => (defaultViewConfiguration ? JSON.parse(defaultViewConfiguration) : {}),
        [defaultViewConfiguration],
    );
    const [state, setState] = useState(initialState);
    if (configurationContext) {
        return [configurationContext.viewConfiguration, configurationContext.setViewConfiguration, false];
    }
    return [state, setState, initialState !== state];
};
export default useViewConfiguration;
