import { crudCreateSuccess, crudCreateFailure } from 'sideEffect/crud/create/actions';
import { crudUpdateSuccess, crudUpdateFailure } from 'sideEffect/crud/update/actions';
import { crudDeleteSuccess, crudDeleteFailure } from 'sideEffect/crud/delete/actions';
import { crudGetListFailure } from 'sideEffect/crud/getList/actions';
import { crudGetOneFailure } from 'sideEffect/crud/getOne/actions';
import { isActionOf } from 'typesafe-actions';
import { Epic, StateObservable } from 'redux-observable';
import { RootAction } from 'actions/rootAction';
import { RootState } from 'reducers/rootReducer';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { Services } from 'sideEffect/services';
import { combineEpics } from 'redux-observable';
import { enqueueSnackbar as enqueueSnackbarAction } from 'notistack/actions';
import { AjaxError } from 'rxjs/ajax';
import { pipe } from 'rxjs';
import getImpl from 'expressions/Provider/implementations/getImpl';
import { SubmissionError } from 'redux-form';
import { unflatten } from 'flat';
import get from 'lodash/get';

/**
 * Examples
 *
 * fieldPath: 'User'
 * fieldPath: 'User.roles'
 *
 */
export type ServerValidationError = {
    fieldPath: string;
    message: string;
    expression?: string;
    level?: string;
};

export type SubmissionValidationError =
    | {
          hasExpression: true;
          pathFromRoot: string;
          message: string;
          expression: string;
          fieldsInExpressionAtPath: string[];
      }
    | {
          hasExpression: false;
          pathFromRoot: string;
          message: string;
      };

export type ErrorMessage = {
    message: string;
    fieldsInExpression?: string[];
};
export type SubmissionValidationsTree = {
    errors: ErrorMessage[];
    fieldErrors: {
        [field: string]: SubmissionValidationsTree;
    };
};

export const constructSubmissionValidationsTree = (
    validationErrs: SubmissionValidationError[],
): SubmissionValidationsTree => {
    if (!validationErrs) {
        return null;
    }
    const root: SubmissionValidationsTree = {
        errors: [],
        fieldErrors: {},
    };

    const addErrorToTree = (tree: SubmissionValidationsTree, error: SubmissionValidationError, path: string[]) => {
        if (path.length === 0) {
            const entry: ErrorMessage = {
                message: error.message,
            };
            if (error.hasExpression) {
                entry.fieldsInExpression = error.fieldsInExpressionAtPath;
            }
            tree.errors.push(entry);
            return;
        }

        const [currentField, ...remainingPath] = path;
        if (!tree.fieldErrors[currentField]) {
            tree.fieldErrors[currentField] = { errors: [], fieldErrors: {} };
        }

        addErrorToTree(tree.fieldErrors[currentField], error, remainingPath);
    };

    for (const error of validationErrs) {
        const path = error.pathFromRoot.split('.').filter(Boolean);
        addErrorToTree(root, error, path);
    }

    return root;
};
export const getServerValidationErrors = (error: AjaxError, keyInResponse?: string): SubmissionValidationError[] => {
    const responseErrors = keyInResponse ? get(error.response, keyInResponse) : error.response;
    if (Array.isArray(responseErrors)) {
        return (responseErrors as ServerValidationError[]).flatMap<SubmissionValidationError>(
            ({ fieldPath, message, expression, level }) => {
                const pathFromRoot = fieldPath.split('.').slice(1).join('.');
                if (expression) {
                    const compiled = getImpl().compileExpression(expression);
                    if (compiled.type === 'parse_failure') {
                        console.error("The expression in the error below couldn't be parsed:");
                        console.error(expression);
                        return [];
                    }
                    const fieldPaths = compiled.getExpansionsWithoutArrayDescendants();
                    return [
                        {
                            hasExpression: true,
                            pathFromRoot,
                            message,
                            expression,
                            fieldsInExpressionAtPath: fieldPaths, // TODO: filter out anything back to use? Possible only for 1-1 relationships.
                        },
                    ];
                }
                return [
                    {
                        hasExpression: false,
                        pathFromRoot,
                        message,
                    },
                ];
            },
        );
    }
    return null;
};

/**
 * Accumulates all field errors into a single object, with flat, .-separated keys representing all paths the error involves.
 * This may be too much, so we might have a filtering step where we remove anything that isn't a field in our current form, for example.
 */
export const accFieldErrors = (
    errs: SubmissionValidationsTree['fieldErrors'],
): {
    [fieldPath: string]: string[];
} => {
    const res: {
        [fieldPath: string]: string[];
    } = {};
    Object.entries(errs ?? {}).forEach(([k, _errs]) => {
        _errs.errors.forEach((err) => {
            if (!res[k]) res[k] = [];
            res[k].push(err.message);
            err.fieldsInExpression?.forEach((field) => {
                const key = `${k}.${field}`;
                if (!res[key]) res[key] = [];
                res[key].push(err.message);
            });
        });
        Object.entries(accFieldErrors(_errs.fieldErrors)).forEach(([subpath, errors]) => {
            const key = `${k}.${subpath}`;
            if (!res[key]) res[key] = [];
            res[key] = res[key].concat(errors);
        });
    });
    return res;
};

export const getSubmissionError = (
    error: AjaxError,
    allFieldSources: {
        [source: string]: true;
    },
    keyInResponse?: string,
): SubmissionError<
    {
        _error: string[];
        [k: string]: string[];
    },
    string[]
> => {
    if (!error) return null;
    const sves = getServerValidationErrors(error, keyInResponse);
    const submissionError = constructSubmissionValidationsTree(sves);
    if (!submissionError) {
        return null;
    }
    const fieldErrors = accFieldErrors(submissionError.fieldErrors);
    submissionError.errors.forEach((err) =>
        err.fieldsInExpression?.forEach((field) => {
            if (!fieldErrors[field]) fieldErrors[field] = [];
            fieldErrors[field].push(err.message);
        }),
    );

    // remove any errors that don't point to registered fields - actual fields in the form. This is an important step to prevent bugs where we overwrite
    // the fields we want highlighted, with deeper fields, due to unflattening, which don't actually exist in the form.
    const errorsForFieldsInTheForm = Object.fromEntries(
        Object.entries(fieldErrors).filter(([k, v]) => {
            // not all fields are registered - e.g. x-manys. So we look at all the field element sources instead.
            return allFieldSources[k];
        }),
    );
    const se = Object.assign(
        { _error: submissionError.errors.map((e) => e.message) },
        unflatten(errorsForFieldsInTheForm, { overwrite: true }),
    );
    return new SubmissionError(se);
};

export const translateAjaxError = (error: AjaxError) => {
    return error.message === 'ajax error'
        ? (() => {
              if (navigator.onLine) {
                  return 'Server communication error.';
              }
              return 'You are not connected to the internet.';
          })()
        : (error.response && error.response.description) ||
              (() => {
                  const { message } = error;
                  if (message) {
                      if (message.includes('ajax error 4')) {
                          return 'Client Error. Please try again.';
                      }
                      if (message.includes('ajax error 5')) {
                          return 'Server Error. Please try again.';
                      }
                      return 'System Error. Please try again.';
                  }
              })();
};

const filterBySecure = (state$: StateObservable<RootState>) =>
    pipe(
        withLatestFrom(state$.pipe(map((state) => (state.basicInfo ? !state.basicInfo.debugFeaturesEnabled : true)))),
        filter(([action, secure]) => !secure),
        map(([action]) => action),
    );

const crudUpdateSuccessNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudUpdateSuccess)),
        filterBySecure(state$),
        map((action) => enqueueSnackbarAction({ message: 'Updated', options: { variant: 'success' } })),
    );

const crudCreateSuccessNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudCreateSuccess)),
        filterBySecure(state$),
        map((action) => enqueueSnackbarAction({ message: 'Created', options: { variant: 'success' } })),
    );

const crudDeleteSuccessNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudDeleteSuccess)),
        filterBySecure(state$),
        map((action) => enqueueSnackbarAction({ message: 'Deleted', options: { variant: 'success' } })),
    );

const crudGetOneFailureNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf(crudGetOneFailure)),
        filterBySecure(state$),
        map((action) => enqueueSnackbarAction({ message: 'Does not exist', options: { variant: 'warning' } })),
    );

const crudFailureNotification: Epic<RootAction, RootAction, RootState, Services> = (action$, state$, services) =>
    action$.pipe(
        filter(isActionOf([crudCreateFailure, crudGetListFailure, crudUpdateFailure, crudDeleteFailure])),
        // Just a preference not to show a notification that flashes as the page redirects.
        filterBySecure(state$),
        filter(
            ({ payload: error }) =>
                !(
                    error &&
                    (error as AjaxError).status &&
                    ((error as AjaxError).status === 401 || (error as AjaxError).status === 409)
                ),
        ),
        map((action) => {
            const { payload: error } = action;
            const errorMessage = translateAjaxError(error as AjaxError);
            const detail = (error as AjaxError).response ? (error as AjaxError).response.detail : undefined;
            return enqueueSnackbarAction({
                message: errorMessage,
                options: { variant: 'error', style: { whiteSpace: 'pre-wrap' } },
                detail,
            });
        }),
    );
export default combineEpics(
    crudUpdateSuccessNotification,
    crudCreateSuccessNotification,
    crudDeleteSuccessNotification,
    crudGetOneFailureNotification,
    crudFailureNotification,
);
